import React, { Component } from 'react';
import SearchStock from './search-stock';
import { Redirect } from 'react-router-dom';
import Reset from './reset';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
import qs from 'query-string';

import { InventoryService } from './../../../service';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
let stock_category = 1;

class ResetStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockData: [],
            stockFilter: {
                car_status: [1],
                'classified': '1',
            },
            page: 0,
            statusCount: {},
            stockType: 'Car',
            firstLoad: true,
            resetInventoryFlag: false,
            resetRedirect: true
        };

        // this.onScroll = this.onScroll.bind(this);
        this.getStocklist = this.getStockList.bind(this);
        this.flag = true;
        const currentRoute = this.props.location.pathname
        if (currentRoute == '/manage-truck') stock_category = 3;
        else if (currentRoute == '/manage-stock') stock_category = 1;
    }

    componentDidMount = async () => {
        await this.updateReduxData();
        this.getStockcategoryList();
        this.getStockList();
        this.getResetFlagInfo();
        let queryParams = this.props.location.search;
        if (queryParams) {
            let queryObject = qs.parse(queryParams);
            if (queryObject.non_classified) {
                this.setState({
                    stockFilter: {
                        car_status: [1],
                        non_classified: '0'
                    }
                }, () => {
                    this.getList();
                });
            }
            else if (queryObject.car_age_before) {
                this.setState({
                    stockFilter: {
                        car_status: [1],
                        car_age_filter: 'over_45'
                    }
                }, () => {
                    this.getList();
                });
            }
            else if (queryObject.without_photos) {
                this.setState({
                    stockFilter: {
                        car_status: [1],
                        without_photos: 1
                    }
                }, () => {
                    this.getList();
                });
            }
            else {
                this.getList();
            }
        }
        else {
            this.getList();
        }
    }

    getResetFlagInfo = async () => {
        var postObj = {
            "dealer_id": this.props.dealer_id
        };
        MasterService.post('dealer/dealer/get_dealer_package_detail', postObj)
            .then((response) => {
                if (response.data.status == 200) {
                    if (response.data.data) {
                        this.setState({ resetRedirect: response.data.data.reset_inventory_flag }, () => { });
                    }
                }
            })
            .catch(function (response) {
            });
    }
    getStockcategoryList() {
        InventoryService.getMasters(["stock_category"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200 && response.data.data) {
                if (Array.isArray(response.data.data.stock_category)) {
                    let s_cat = response.data.data.stock_category.filter(v => v.key == stock_category)[0];
                    if (s_cat) this.setState({ stockType: s_cat.value });
                }
            }
        }).catch((error) => {
            this.setState({ loading: false })
        });
    }

    // componentWillReceiveProps = (nextProps) => {
    //     if(nextProps.history.location.state == 'freshLoadStock'){
    //         this.setState({stockFilter: {
    //             car_status: [1]
    //         }}, () => {
    //             this.submitFilterForm();
    //         });            
    //     }
    // }

    getList = () => {
        this.getStockCount();
        // this.onScroll('Mounter');
        // window.addEventListener('scroll', this.onScroll);
    }

    handleStockFilter = (fieldName, fieldValue) => {
        let stockFilter = { ...this.state.stockFilter };
        if (fieldName === 'car_make' || fieldName === 'car_model' || fieldName === 'fuel_type_id' || fieldName === 'uc_transmission_id') {
            if (!stockFilter[fieldName]) stockFilter[fieldName] = [];
            stockFilter[fieldName][0] = fieldValue;
        } else {
            stockFilter[fieldName] = fieldValue;
        }
        this.setState({ stockFilter })
    }

    handleStatusChange = (statusValArr) => {
        this.setState({
            stockFilter: {
                ...this.state.stockFilter,
                car_status: statusValArr
            }
        }, () => {
            this.submitFilterForm();
        });
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        } else {
            this.setState({ redirect: true })
        }
    }

    // onScroll(event) {
    //     if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
    //         if (this.flag) {
    //             let current_page = this.state.page;
    //             this.setState({ page: current_page + 1 }, () => {
    //                 this.getStockList();
    //             });
    //         }
    //         this.flag = false;
    //     }
    // }
    submitFilterForm = () => {
        this.setState({
            page: 1
        }, () => {
            this.getStockList();
            this.getStockCount();
        });

    }

    getStockCount = () => {
        InventoryService.stockStatusCount({ ...this.state.stockFilter, category_id: stock_category })
            .then((response) => {
                if (response.data.status == 200) {
                    this.setState({
                        statusCount: response.data.data
                    });
                }
            })
            .catch((response) => {
            });
    }

    getStockList() {
        var thisObj = this;
        this.setState({ loading: 'loading' });
        MasterService.post('inventory/inventory/stocklist', { 'category_id': stock_category, 'reset_inventory': 1, ...this.state.stockFilter })
            .then(function (response) {
                thisObj.setState({ loading: '' });
                thisObj.flag = false;
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ stockData: response.data.data, firstLoad: false }, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                stockData: thisObj.state.stockData.concat(response.data.data),
                                firstLoad: false
                            }, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ stockData: response.data.data, firstLoad: false });
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({ loading: '' });
            });
    }

    updateListData = (updatedObj, index) => {
        let stockArr = [...this.state.stockData];
        if (stockArr[index]) {
            stockArr[index] = {
                ...stockArr[index],
                ...updatedObj
            }
            this.setState({
                stockData: stockArr,
            });
        }
    }

    handleRemovedCar = (index) => {
        this.getStockCount();
        if (index >= 0) {
            let stockArr = [...this.state.stockData];
            if (index <= stockArr.length) {
                stockArr.splice(index, 1);
                this.setState({
                    stockData: stockArr
                });
            }
        }
    }
    handlechecked = (fieldName, fieldValue) => {
        let stockFilter = { ...this.state.stockFilter };
        stockFilter[fieldName] = fieldValue;
        this.setState({ stockFilter });
    }

    handleLeadAdd = (index, count) => {
        this.updateListData({ leadCount: count }, index);
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        else if (!this.state.resetRedirect) {
            return (<Redirect to={'/manage-stock'} />);
        }
        return (
            <div className="container-fluid manageStock">
                    <div className={`addStock ${this.state.loading}`}>
                        <SearchStock location={this.props.location} handlechecked={this.handlechecked} handleStockFilter={this.handleStockFilter} submitFilterForm={this.submitFilterForm} stockCategory={stock_category} />
                        <div className="result-wrap">
                            <Reset
                                updateListData={this.updateListData} handleRemovedCar={this.handleRemovedCar}
                                getStockCount={this.getStockCount} handleStatusChange={this.handleStatusChange}
                                statusCount={this.state.statusCount} stockData={this.state.stockData}
                                firstLoad={this.state.firstLoad}
                                handleLeadAdd={this.handleLeadAdd}
                                stockCategory={stock_category}
                                stockType={this.state.stockType}
                                dealerId={this.props.dealer_id}
                            />
                        </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('inventory')(connect(mapStateToProps, mapDispatchToProps)(ResetStock));

