
import React, { Component } from 'react';
class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:1,
            runPaginationFunc: false,
            totalLengthWithoutPagination: 0,
            cachedPostData:{}
        };
    }
    componentDidMount = async () => {
        this._isMounted = true;
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {

        let updatedStateData = {};
                        
            if(nextProps.paginateData.runPaginationFunc) { 
                updatedStateData['runPaginationFunc'] = false;
            }

            if(nextProps.paginateData.page){
                updatedStateData['page'] = nextProps.paginateData.page;
            }

            if(nextProps.paginateData.filterData){
                updatedStateData['cachedPostData'] = nextProps.paginateData.filterData;
            }


            updatedStateData['totalLengthWithoutPagination'] = nextProps.paginateData.totalLengthWithoutPagination
            
        return updatedStateData;
    }

    	/**
     * SUBMIT FILTER HANDLER
     */
         filterSubmit = async (params={}) => { 
            let postData = {page: params['page'] || 1};
            
            if(this._isMounted){
                await this.setState({        
                    submitted: false,
                    page: postData['page'] || 1
                });
            }
             
            if(params.callingFrom && params.callingFrom === 'pagination'){
                let pageNum = postData['page'];
                postData = Object.keys(this.state.cachedPostData).length ? Object.assign({}, this.state.cachedPostData) : postData 
                postData['page'] = pageNum
            }else{                    
                let cachedPostData = Object.assign({}, postData);
                if(this._isMounted) await this.setState({cachedPostData:cachedPostData});
            }
            this.props.sendRequestDataToParent(postData);
            
        }
    
        /**
         * 
         * ON PAGE NUMBER CLICK PAGINATE
         */
        onPageClick = (page, activeClass='') => e => {
            e.preventDefault()
            
            if(!activeClass && page>0 && page<= this.props.paginateData.paginationData.pages){
    
                let loading = false;
    
                if (page !== 0) {
                    loading = true;
                }
                
                //CALL FOR API & SENT runPaginationFunc TRUE WHICH SHOWS IF TRUE THEN UPDATE DATA IN TABLE
                if(this._isMounted) {
                    this.setState({ page, loading: loading, runPaginationFunc: true }, ()=>{
    
                    });
                }
                this.filterSubmit({page, callingFrom:'pagination'});
            }
        
        } 

    render() {
        const { paginationData } = this.props.paginateData;
        let numberPaginate = []
        let activeClass = ''
        //CREATE PAGINATE NUMBERS
        if(paginationData && Object.keys(paginationData).length && paginationData.total && paginationData.total>10){
            //MOVE BACKWARD
            numberPaginate.push(
                <li key={'left'} className="pre">
                    <a onClick={this.onPageClick(paginationData.cur_page-1)} href="#/"  disabled={paginationData.cur_page===1?true:false}><i className="ic-keyboard_arrow_left"></i></a>
                </li>
            )

            //SHOW FIRST PAGE LOGIC
            if(paginationData.cur_page >6){
                numberPaginate.push(<li className={activeClass} key={paginationData.pages}><a  onClick={this.onPageClick(1, activeClass)} href="#/" >1</a></li>)
                numberPaginate.push(<li key={'seprator'}><a  onClick={this.onPageClick(-1, activeClass)} href="#/" >...</a></li>)
            }

            let pageUpto = (paginationData.cur_page <= 5) ? (paginationData.pages>10 ? 5 : paginationData.pages) : (paginationData.cur_page === paginationData.pages ? paginationData.pages-1 : paginationData.cur_page );
            let pageFrom = (paginationData.cur_page <= 5) ? 1 : paginationData.cur_page-4;

            //PAGINATE NUMBERS UPDATE ON CHANGE LEFT/RIGHT BUTTONS
            for(let number=pageFrom; number <= pageUpto; number++ ){
                activeClass = (paginationData.cur_page === number) ? 'active' : ''
                //IF MORE THAN 10 PAGES, THEN SHOW ONLY 5 PAGE
                if( paginationData.pages>10){
                    numberPaginate.push(<li className={activeClass} key={number}><a onClick={this.onPageClick(number, activeClass)} href="#/" >{number}</a></li>)
  
                }else{ //ELSE SHOW ALL PAGES
                    numberPaginate.push(<li className={activeClass} key={number}><a onClick={this.onPageClick(number, activeClass)} href="#/" >{number}</a></li>)
                }
            }

            //SHOW LAST PAGE AND SEPRATOR
            if(paginationData.pages>10){
                activeClass = (paginationData.cur_page === paginationData.pages) ? 'active' : ''

                if(![paginationData.pages-1, paginationData.pages].includes(paginationData.cur_page) ){
                    numberPaginate.push(<li key={'sepratorlast'}><a onClick={this.onPageClick(-1)} href="#/" >...</a></li>)
                }

                numberPaginate.push(<li className={activeClass} key={paginationData.pages+'last'}><a  onClick={this.onPageClick(paginationData.pages, activeClass)} href="#/" >{paginationData.pages}</a></li>)
            }
            //MOVE FORWARD
            // if(![ paginationData.pages].includes(paginationData.cur_page) ){
                numberPaginate.push(<li key={'right'} className="next"><a onClick={this.onPageClick(paginationData.cur_page+1, activeClass)} href="#/" ><i className="ic-keyboard_arrow_right"></i></a></li>)
            // }
        }
        return (
            <div className="pagination-bx">
                <ul>
                {
                        numberPaginate.map(page=>{
                            return page
                        })
                    }
                </ul>
            </div>

        )
    }
}
export default Pagination;