import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";
import { NumericFormat as NumberFormat } from "react-number-format";

class ViewIFDetailsHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ifCategoryDetailsHistory: [],
            dealerIdHash: props.dealerIdHash,
            categoryName: props.categoryName,

            loading: false,
        };
    }

    getIFCategoryDetailsHistory = () => {
        this.setState({ loading: true })
        MasterService.post(`/dealer/inventory-funding/get_if_category_details_history`, { dealer_id: this.state.dealerIdHash }).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                this.setState({ loading: false })
                console.log(responseData)
                this.setState({ ifCategoryDetailsHistory: responseData });
            } else {
                this.setState({ loading: false })
                toast.error(response.data.message);
            }
        }).catch((error) => {
            this.setState({ loading: false })
            toast.error(error)
        });
    };

    componentDidMount() {
        this.getIFCategoryDetailsHistory();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            dealerIdHash: nextProps.dealerIdHash,
            categoryName: nextProps.categoryName
        });
    }

    render() {
        return (
            <div className={this.state.loading ? "container-fluid loading" : "container-fluid"}>
                <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <thead>
                        <tr>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Date")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Changed_By")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Category_Name")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Sanction_Limit")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Drawing_Power")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Per_Car_Limit")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Facility_Tenure")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Cashback")}</th>
                            <th>{this.props.t("dealerDetails.Inventory_Funding_Details.Details_History.Priority_Dealer")}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.ifCategoryDetailsHistory && this.state.ifCategoryDetailsHistory.length > 0 ? (
                            this.state.ifCategoryDetailsHistory.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{item.created_date}</td>
                                        <td>{item.name}</td>
                                        <td>{item.category_id}</td>
                                        <td>Rp <NumberFormat thousandSeparator="." decimalSeparator="," displayType="text" value={item.sanction_limit}/></td>
                                        <td>Rp <NumberFormat thousandSeparator="." decimalSeparator="," displayType="text" value={item.approved_limit}/></td>
                                        <td>Rp <NumberFormat thousandSeparator="." decimalSeparator="," displayType="text" value={item.per_car_limit}/></td>
                                        <td>{item.facility_tenure} Months</td>
                                        <td>{item.cashback} %</td>
                                        <td>{item.priority_dealer ? "Yes" : "No"}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="9">
                                    <h6>No Records Found</h6>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("dealers")(connect(mapStateToProps, mapDispatchToProps)(ViewIFDetailsHistory));
