

import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';
import AuctionService from '../../../service/AuctionService';
import { toast } from 'react-toastify';
import DateFormat from 'dateformat';
import NO_CAR_IMAGE from '../../../webroot/images/NoCar.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as HELPER from "./AuctionCommonData";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

class PurchaseHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryCars: [],
            updateFirst: false,
            paginationData: {},
            buyer_id: 0,
            isOpenLightBox: false,
            sliderIndex: 0,
            lightboxImages: []
        }

    }

    componentDidMount = async () => {
        await this.updateReduxData();
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer = loginResponse.data.dealer_data[0];
            this.setState({ buyer_id: dealer.dealer_id })
        } else {
            this.setState({ redirect: true })
        }
    }

    static getDerivedStateFromProps(props, state) {
        let updatedState = state;

        // if(props.auctionData && props.auctionData.inventoryCars.length && !state.inventoryCars.length && !state.updateFirst){
        updatedState['inventoryCars'] = props.auctionData.inventoryCars
        updatedState['paginationData'] = props.auctionData.paginationData
        updatedState['tabName'] = props.auctionData.tabName

        updatedState['updateFirst'] = true

        // }
        return updatedState;
    }


    /**
     * ADD/REMOVE CAR WISHLIST
     * @param {*} auction_id 
     * @param {*} car_id 
     * @param {*} type 
     */
    addRemoveWatchList = async (auction_id, car_id, type) => {

        let { buyer_id, tabName } = this.state;

        let watchlistParams = { buyer_id, car_id, auction_id, type }

        await AuctionService.addRemoveWatchList(watchlistParams).then(async response => {

            toast.success(response.data.message);
            if (response.data.status === 200) {
                this.props.updateTabList(tabName)

                // let updateInventory = inventoryCars.map(car=>{
                //     return ((car.vid == car_id || car.car_id == car_id) && car.auction_id == auction_id) ? {...car, watchlist_status: (type === 'add' ? 1 : 0)} : car
                // });
                // await this.setState({ inventoryCars: updateInventory })
            }
        })
    }


    getInventoryDetail = (inventoryId) => {
        this.props.history.push("/auction-inventory-detail/" + inventoryId);

    }
    loadMoreItems = () => {
        this.props.updateTabList(this.state.tabName, null, null, true);
    }

    //SET LIGHTBOX IMAGE & INDEX
    set_image_index = (isOpen, Index, lightboxImages = this.state.lightboxImages) => {
        this.setState({ sliderIndex: Index, isOpenLightBox: isOpen, lightboxImages: [...lightboxImages.slice(Index), ...lightboxImages.slice(0,Index)] });
    }

    render() {

        const settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const { inventoryCars, lightboxImages, isOpenLightBox } = this.state;

        //IMAGES ARRAY
        const images = lightboxImages

        return (
            <div className={inventoryCars.length ? "row" : ""}>
                <div id="scrollableDiv" className="w-100 auctionTabCard" >
                    <InfiniteScroll
                        dataLength={inventoryCars.length}
                        next={this.loadMoreItems}
                        hasMore={(+inventoryCars.length !== +this.props.totalCount) ? true : false}
                    //loader={loading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null} 
                    >
                        {
                            (inventoryCars.length) ?
                                inventoryCars.map((cars, keys) =>
                                    <div className="col-sm-6" key={keys}>
                                        <div className="filter-cards">
                                            <div className="filter-img">
                                                <div className="filter-slider-outer">
                                                    <Slider {...settings}>
                                                        {
                                                            cars.car_details && cars.car_details.car_image && cars.car_details.car_image.length
                                                                ?
                                                                cars.car_details.car_image.map((img, key) =>
                                                                    <div key={key} onClick={() => this.set_image_index(true, key, cars.car_details.car_image)}>
                                                                        <img src={`${img}?resize=215x140`} className="" style={{ width: '215px', height: '140px' }} alt="pic" />
                                                                    </div>
                                                                )
                                                                :
                                                                <div>
                                                                    <img src={NO_CAR_IMAGE} className="" style={{ width: '280px', height: '170px' }} alt="pic" />
                                                                </div>
                                                        }

                                                    </Slider>

                                                    <div className="bookmark-icn" onClick={() => this.addRemoveWatchList(cars.auction_id, (cars.vid || cars.car_id), ((cars.watchlist_status) ? "remove" : "add"))}>
                                                        <i className={(cars.watchlist_status) ? "ic-favorite" : "ic-favorite_outline"}></i>
                                                    </div>

                                                    {
                                                        (cars && cars.isInspected && +cars.isInspected === 1) ?
                                                            <div className="inspected-tag">
                                                                Inspected
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="filter-card-data">
                                                <div className="card-heading" onClick={() => this.getInventoryDetail(cars.hash_id)}>
                                                    <span>{cars.car_details ? cars.car_details.make + ' ' + cars.car_details.model : ''} </span>
                                                    {/* <i className="ic-info-icon"></i> */}
                                                </div>
                                                <ul className="car-spec">
                                                    <li>{cars.car_details ? cars.car_details.reg_no : ''}</li>
                                                    <li>{cars.car_details ? cars.car_details.make_year : ''}</li>
                                                    <li>{cars.car_details ? cars.car_details.fuel_type : ''}</li>
                                                    <li>{cars.car_details ? cars.car_details.km_driven + ' kms' : ''}</li>
                                                </ul>

                                                <div className="auction-price">
                                                    {HELPER.addCurrencyFormatting(cars.purchased_amount) || 0}
                                                </div>
                                                <div className="purchase-date">
                                                    Won on {cars.purchased_date ? DateFormat(new Date(cars.purchased_date), "dd/mm/yyyy") : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (typeof inventoryCars == 'object') ? <div style={{ textAlign: 'center' }}>No Record Found</div> : ''
                        }
                    </InfiniteScroll>
                </div>

                {
                    isOpenLightBox && (
                        <Lightbox
                            open={isOpenLightBox}
                            close={() => this.setState({...this.state, isOpenLightBox: false})}
                            slides={
                                images.map(val=> ({src: val}))
                            }
                        />
                    )
                }
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withRouter((connect(mapStateToProps, mapDispatchToProps)(PurchaseHistory)));