import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';
import AuctionService from '../../../service/AuctionService';
import { toast } from 'react-toastify';

import Socket from '../Common/socket';
import * as HELPER from "./AuctionCommonData";
import FullPageLoader from '../Common/FullPageLoader';
import WishlistIcon from '../Common/WishlistIcon';
import NO_CAR_IMAGE from '../../../webroot/images/NoCar.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import CurrencyInputField from './../../elements/CurrencyInputField';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

class AuctionFilterCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AuctionBid: false,
            inventoryCars: [],
            paginationData: {},
            auctionId: this.props.match.params.auctionId,
            buyer_id: 0,
            updateFirst: false,
            tabName: '',
            bid_incrementedAMt: 0,
            wintab: 0,
            buyerName: '',
            auctionDetails: {},
            bidSaveLoader: false,
            loading: false,
            isOpenLightBox: false,
            sliderIndex: 0,
            lightboxImages: []
        }

        this.socketChild = React.createRef();

    }
    showModalAuctionBid = (cars) => {

        //check auction status
        // let carbidStatus = this.checkCarAuctionStatus(cars);
        // if (carbidStatus) {
        //     return false;
        // } else {
            this.setState({ AuctionBid: true });
            document.body.classList.add("overflow-hidden");

        // }

    }

    hideModalAuctionBid = () => {
        this.setState({ AuctionBid: false });
        document.body.classList.remove("overflow-hidden");
    }

    getInventoryDetail = (inventoryId) => {
        this.props.history.push("/auction-inventory-detail/" + inventoryId);

    }

    componentDidMount = async () => {
        await this.updateReduxData();
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer = loginResponse.data.dealer_data[0];
            this.setState({ buyer_id: dealer.dealer_id, buyerName: dealer.organization })
        } else {
            this.setState({ redirect: true })
        }
    }

    static getDerivedStateFromProps(props, state) {
        let updatedState = state;

        if (props.auctionData && props.auctionData.inventoryCars && props.auctionData.inventoryCars.length && props.auctionData.updateInventoryList) {
            updatedState['inventoryCars'] = props.auctionData.inventoryCars
            updatedState['paginationData'] = props.auctionData.paginationData
            updatedState['auctionDetails'] = props.auctionData.auctionDetails
            updatedState['tabName'] = props.auctionData.tabName
            
            //UPDATE PARENT STATE TO NOT UPDATE INVENTORY HERE
            props.updateParentComponentState({ updateInventoryList: false })
            
        } else if (props.auctionData && props.auctionData.inventoryCars && !props.auctionData.inventoryCars.length) {
            updatedState['inventoryCars'] = []
            updatedState['paginationData'] = {}
        }

        updatedState['loading'] = props.auctionData.loading
        return updatedState;
    }

    incrementVal = async (e, car_id, auction_id) => {
         if(e){
            let { inventoryCars } = this.state;
            inventoryCars = inventoryCars.map(car => {
                if ((+car.vid === +car_id || +car.car_id === +car_id) && +car.auction_id === +auction_id) {
                return { ...car, update_bid_amount: (e) || 0 }
                } else {
                return car
                }
            });
            await this.setState({ inventoryCars })
         }
        
    }

    /**
     * UPDATE BID AMOUNT COMMING FROM SOCKET
     * @param {*} updatedBidAmntData 
     */
    updatedRealTimeBidAmount = async (updatedBidAmntData) => {
        let { inventoryCars, buyer_id } = this.state;
        for(let i=0;i<inventoryCars.length;i++ ){
            let car = inventoryCars[i]; 
        //inventoryCars = inventoryCars.map(car => {
            car = (car.hash_id === updatedBidAmntData['auction_inventory_id_hash']) ? { ...car, bid_amount: updatedBidAmntData['bid_amount'], winning_buyer: updatedBidAmntData['buyer_id'] } : car;
            //UPDATE COUNT & LAST BID AMOUNT FOR BID USER
            if (+buyer_id === +updatedBidAmntData['buyer_id']) {
                if(car.hash_id === updatedBidAmntData['auction_inventory_id_hash']){
                    car['total_used_bid_count'] = updatedBidAmntData.total_used_bid_count;
                    car['last_bid_amount'] = updatedBidAmntData['bid_amount'];

                    //DELETE LOCAL INCREMENTED AMOUNT
                    delete car.update_bid_amount;

                }
            }
            inventoryCars[i] = car;
            //return car;
        } //}); 
        this.setState({ inventoryCars })
    }

    /**
     * SAVE UPDATED BID 
     */
    addBid = async (buyer_id, auction_id, car_id, auctionInventoryHash, cars) => {

        //check auction status
        let carbidStatus = this.checkCarAuctionStatus(cars);
        if (carbidStatus) {
            toast.error('Bidding not allowed in this car')
            return false;
        } 
        // calculate bid amount
        let bid_amount = HELPER.calculateUpdatedBidAmount(cars);
        if(typeof bid_amount==='string'){
            bid_amount=HELPER.removeCurrencyFormatting(bid_amount);
        }

        let origBidAmount = cars['bid_amount'] || cars['base_price'];

        //BID AMOUNT VALIDATION CHECK
        if (origBidAmount && +bid_amount > +origBidAmount) {
            //CHECK LEFT BID COUNT 
            if (cars.bid_count - cars.total_used_bid_count > 0) {
                let bidParams = {
                    buyer_id: buyer_id,
                    auction_id_hash: this.state.auctionId || auction_id,
                    car_id,
                    bid_amount,
                    auction_inventory_id_hash: auctionInventoryHash,
                    bid_left : (cars.total_used_bid_count)?cars.total_used_bid_count+1:0
                };

                this.setState({ bidSaveLoader: true })

                await AuctionService.saveBid(bidParams).then(async response => {

                    if (response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ bid_incrementedAMt: bid_amount, bidSaveLoader: false })

                        // this.socketChild.current.dispatchBidAmountToSocket({auctionInventoryHash, bid_amount, buyer_id});

                    } else {
                        toast.error(response.data.message);
                        this.setState({ bidSaveLoader: false })

                    }
                });
            } else {
                toast.error('You don\'t have any bids left');
            }

        } else {
            toast.error('Bid amount should be greater than last bid amount');
        }

    }

    /**
     * Check Car status for eligibility for bidding
     */
    checkCarAuctionStatus = (car) => {
        let disablebuttons = true;
        if (car && car.start_date_time && new Date(car.start_date_time).getTime() <= new Date().getTime() && new Date(car.end_date_time).getTime() >= new Date().getTime()) {
            disablebuttons = false
        }

        return disablebuttons;
    }


    /**
     * HANDLE DATA FROM WISHLIST COMPONENT
     * @param {*} params 
     */
    wishlistUpdate = (params) =>{
        this.setState({ bidSaveLoader: params.loading })

        if(params.updateCount){ 
            this.props.updateTabList(this.state.tabName,null,params,true);
        }

    }

    /**
     * DISABLE MANUAL AMOUNT TYPING
     * @param {*} e 
     */
    onKeyDownevent = (e) => {
        e.preventDefault();
    }


    /**
     * UPDATE BID AMOUNT COMMING FROM SOCKET AFTER BID CANCEL
     * @param {*} activeBid 
     */
    updatedRealTimeActiveBidAmount = async (updatedBidAmntData) => {
        let { inventoryCars, buyer_id } = this.state;

        //LOOP ALL INVENTORY
        for(let [index, inventory] of inventoryCars.entries()){
            //LOOP ALL UPDATED AMOUNTS
            for(let bid of updatedBidAmntData){
                if(inventory.hash_id === bid.auction_inventory_id_hash){

                    inventoryCars[index]['bid_amount'] = bid['bid_amount'];
                    inventoryCars[index]['winning_buyer'] = bid['buyer_id'];
                      
                    if (+buyer_id === +bid['cancel_buyer_id']) { 
                        inventoryCars[index]['total_used_bid_count'] = bid.total_used_bid_count;
                        inventoryCars[index]['last_bid_amount'] = bid['last_amount'];
                    }
                    if (+buyer_id === +bid['buyer_id']) {
                        //inventoryCars[index]['last_bid_amount'] = bid['bid_amount'];
    
                        //DELETE LOCAL INCREMENTED AMOUNT
                        delete inventory.update_bid_amount;
                    }else{
                        //inventoryCars[index]['last_bid_amount'] = 0;
                    }
                }
                
            }
        }

        this.setState({ inventoryCars })

    }

    loadMoreItems=()=>{
        this.props.updateTabList(this.state.tabName,null,null,true);
    }

    //SET LIGHTBOX IMAGE & INDEX
    set_image_index =(isOpen,Index, lightboxImages=this.state.lightboxImages)=>
    {   
        this.setState({sliderIndex: Index , isOpenLightBox: isOpen, lightboxImages: [...lightboxImages.slice(Index), ...lightboxImages.slice(0,Index)]});
    }
    render() {

        const settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const { inventoryCars, buyer_id, tabName, bid_incrementedAMt, auctionId, buyerName, auctionDetails, bidSaveLoader, loading, isOpenLightBox } = this.state;

        let auctionStartStatus = ['mybid', 'watchlist'].includes(tabName) ? 1 : 0;

        if (!['mybid', 'watchlist'].includes(tabName) && auctionDetails && auctionDetails.start_date_time && new Date(auctionDetails.start_date_time).getTime() <= new Date().getTime() && new Date(auctionDetails.end_date_time).getTime() >= new Date().getTime()) {
            auctionStartStatus = 1
        }

        return (
            <div className={inventoryCars.length ?  "row " : ''}>

                <FullPageLoader show={bidSaveLoader} />

                {
                    inventoryCars.length && auctionStartStatus
                        ?
                        <Socket componentData={{ bid_incrementedAMt, auctionId, buyerName, inventoryCars, buyer_id }} ref={this.socketChild} updatedRealTimeBidAmount={this.updatedRealTimeBidAmount} updatedRealTimeActiveBidAmount={this.updatedRealTimeActiveBidAmount} />
                        :
                        ''
                }
                <div id="scrollableDiv" className="w-100 auctionTabCard" >
                    <InfiniteScroll
                        dataLength={inventoryCars.length}
                        next={this.loadMoreItems}
                        hasMore={(+inventoryCars.length !== +this.props.totalCount) ? true : false}
                        //loader={loading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null} 
                    >
                {
                    (inventoryCars.length) ?
                        inventoryCars.map((cars, keys) =>
                            <div className="col-sm-6" key={keys}>
                                <div className="filter-cards">
                                    <div className="filter-img">
                                        <div className="filter-slider-outer">
                                            <Slider {...settings}>
                                                {
                                                    cars.car_details && cars.car_details.car_image && cars.car_details.car_image.length
                                                        ?
                                                        cars.car_details.car_image.map((img, key) =>
                                                            <div key={key} onClick={() => this.set_image_index(true,key, cars.car_details.car_image)}>
                                                                <img src={`${img}?resize=215x140`} className="" style={{ width: '215px', height: '140px' }} alt="pic"/>
                                                            </div>
                                                        )
                                                        :
                                                            <div>
                                                                <img src={NO_CAR_IMAGE} className="" style={{ width: '280px', height: '170px' }} alt="pic"/>
                                                            </div>
                                                }

                                            </Slider>

                                            {/* <div className="bookmark-icn" onClick={() => this.addRemoveWatchList(cars.auction_id, (cars.vid || cars.car_id), ((cars.watchlist_status) ? "remove" : "add"))}>
                                                <i className={(cars.watchlist_status) ? "ic-favorite" : "ic-favorite_outline"}></i>
                                            </div> */}
                                            <WishlistIcon auctionDetails={{carDetails: {auctionData: cars}, buyer_id, tabName}} sendDataToParent={this.wishlistUpdate}/>


                                            {
                                                (cars && cars.isInspected && +cars.isInspected === 1) ?
                                                <div className="inspected-tag">
                                                    Inspected
                                                </div>
                                                 :
                                                null
                                            }
                                           

                                        </div>
                                    </div>
                                    <div className="filter-card-data">
                                        <div className="card-heading" onClick={() => this.getInventoryDetail(cars.hash_id)}>
                                            <span>{cars.car_details ? cars.car_details.make + ' ' + cars.car_details.model : ''} </span>
                                            
                                        </div>
                                        <ul className="car-spec">
                                            {
                                                cars.car_details
                                                    ?
                                                    <>
                                                        {
                                                            cars.car_details.reg_no
                                                                ?
                                                                <li>{cars.car_details.reg_no}</li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            cars.car_details.make_year
                                                                ?
                                                                <li>{cars.car_details.make_year }</li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            cars.car_details.fuel_type
                                                                ?
                                                                <li>{cars.car_details.fuel_type}</li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            cars.car_details.km_driven
                                                                ?
                                                                <li>{cars.car_details.km_driven + ' kms'}</li>
                                                                :
                                                                ''
                                                        }
                                                    </>
                                                    :
                                                    ''
                                            }
                                        </ul>
                                        {
                                                <>
                                                    <div className="bid-sec">
                                                        <div className="bid-status-amt">
                                                            <span>Last Bid: { (cars.last_bid_amount)?HELPER.addCurrencyFormatting(cars.last_bid_amount ):0 }</span>
                                                            
                                                            {
                                                                cars && cars.winning_buyer
                                                                ?
                                                                    (buyer_id && +buyer_id === +cars.winning_buyer)
                                                                        ?
                                                                            <div className="bid-status win-bg">
                                                                                {'Winning'}
                                                                            </div>
                                                                        :
                                                                            (cars.total_used_bid_count) 
                                                                            ?
                                                                                <div className="bid-status lose-bg">
                                                                                    {'Losing'}
                                                                                </div>
                                                                            :
                                                                                ''
                                                                :
                                                                    ''
                                                            }
                                                        </div>
                                                        
                                                        <div className="bid-left">
                                                            {(cars.bid_count - cars.total_used_bid_count) > 0 ? (cars.bid_count - cars.total_used_bid_count) : 0} Bid Left
                                                        </div>
                                                        
                                                    </div>

                                                    {
                                                        (
                                                            ((cars.auction_type && cars.auction_type.toLowerCase() !== 'upcoming') || ['mybid', 'watchlist'].includes(tabName)) && cars.bid_amount)
                                                        ?
                                                            <div className="bid-sec">
                                                                <div className="bid-status-amt">
                                                                    <span>Winning Bid: {HELPER.addCurrencyFormatting(cars.bid_amount) || HELPER.addCurrencyFormatting(cars.base_price)}</span>
                                                                </div>
                                                                
                                                            </div>
                                                        :
                                                            ""
                                                    }
                                                    


                                                    <div className="numric-btn">
                                                        <CurrencyInputField 
                                                        inputProps={{
                                                            id: "bid_amount",
                                                            type: "text",
                                                            name: "bid_amount",
                                                            placeholder: " ",
                                                            value:HELPER.calculateUpdatedBidAmount(cars),
                                                            disabled:this.checkCarAuctionStatus(cars),
                                                            min:(HELPER.calculateMinBidAmount(cars))?HELPER.calculateMinBidAmount(cars):0,
                                                            step:+cars.increment_amount
                                                          }}
                                                          mobile onChange={(value) => this.incrementVal(value, cars.car_id, cars.auction_id)} onKeyDown={this.onKeyDownevent}
                                                          onFocus={() => { }}
                                                          onBlur={() => { }}/>

                                                        <button type="submit" disabled={this.checkCarAuctionStatus(cars)} className="bid-btn" onClick={() => this.addBid(buyer_id, (cars.auction_id_hash || cars.auction_id), (cars.vid || cars.car_id), cars.hash_id, cars)}>
                                                            Bid
                                                        </button>
                                                        <i className={(this.checkCarAuctionStatus(cars)) ? 'bid-icn ic-bid-icn' : 'bid-icn ic-bid-icn'} onClick={() => this.showModalAuctionBid(cars)} >

                                                        </i>
                                                    </div>
                                                </>

                                        }
                                    </div>

                                </div>
                            </div>
                        ) : (!loading) ? <p style={{ "textAlign": 'center' }}>No Record Found</p> : ''

                }
                </InfiniteScroll>
                </div>
                <div className="auction-bid-popup pop-main-outer">
                    <Modal show={this.state.AuctionBid} handleClose={this.hideModalAuctionBid} >
                        <div className="modal-header">
                            
                        </div>
                        <div className="modal-body" >
                            <h2 className="text-center">Coming Soon</h2>

                        </div>
                    </Modal>
                </div>

                {
                    isOpenLightBox && (
                        <Lightbox
                            open={isOpenLightBox}
                            close={() => this.setState({...this.state, isOpenLightBox: false})}
                            slides={
                                this.state.lightboxImages.map(val=> ({src: val}))
                            }
                        />
                    )
                }
                
            </div >

        )
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};


const container = document.createElement('div');
document.body.appendChild(container);


const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default (withRouter((connect(mapStateToProps, mapDispatchToProps)(AuctionFilterCard))));
