import React, { Component } from 'react';
import Select from 'react-select';
import AuctionTabCount from './AuctionListTab'
import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';

const options = [
    { value: 'asc', label: 'Sooner to Later' },
    { value: 'desc', label: 'Later to Sooner' }
];
class Auction extends Component {
    constructor(props) {
        super(props);

        this.auctionTabCountChild = React.createRef();

        this.state = {
            selectedOption: options[0]['value'],
            buyer_id:'',
            currentTab: 'liveauction'

        }
    }
    
    componentDidMount = async () => {
        await this.updateReduxData();
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0]) {
                let dealer = loginResponse.data.dealer_data[0];
                this.setState({ buyer_id: dealer.dealer_id })
            }
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        } else {
            this.setState({ redirect: true })
        }
    }   
    
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        
        this.auctionTabCountChild.updateListByFilters({startTimeOrder: selectedOption.value})
    };

    handleChildValue = async (params) => {
        await this.setState({currentTab: params.tabName});
    }

    render() {
        const { selectedOption, buyer_id, currentTab } = this.state;
        const order = (typeof selectedOption =='string') ? selectedOption : selectedOption.value; 
        return (
            <div className="container-fluid auction-main">
                <div className="auction-outer">
                    
                        <div className="heading">
                        <h1>Auctions</h1>
                        
                        {
                            currentTab && ['liveauction', 'upcomingauction'].includes(currentTab)
                            ?
                                <div className="time-select">
                                    <label className="start-time-label"> { currentTab === 'upcomingauction' ? "Start Time" : 'End Time' } </label>
                                    <Select
                                        value={selectedOption}
                                        onChange={this.handleChange}
                                        options={options}
                                        placeholder=" Sooner to Later"
                                        classNamePrefix="react_select"
                                    />
                                </div>
                            :
                            ""
                        }
                        </div>
                        
                    
                    <div className="tabs-main">
                        {
                            buyer_id
                            ?
                                <AuctionTabCount buyer_id={buyer_id}  childRef={(ref) => this.auctionTabCountChild = ref} auctionFilter={{startTimeOrder: selectedOption.value}} order={order} onChangeValue={this.handleChildValue}/>
                            :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(Auction));

