import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/action/captchAction';
import InputField from '../elements/CaptchaInputFields';

const Captcha = (props) => {
  const [captcha, setCaptcha] = useState('');
  const [captchaInvalid, setCaptchaInvalid] = useState('');
  const [captchaImg, setCaptchaImg] = useState({});
  const [captchaImgVal, setCaptchaImgVal] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    loadCaptchaImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadCaptcha]);

  useEffect(() => {
    setCaptchaInvalid(props.errField.captchaErr);
  }, [props.errField]);

  /**
   *  Load Captcha Image
   */

  const loadCaptchaImage = (err = []) => {
    setCaptcha('');
    dispatch(UserActions.loginCaptcha()).then((res) => {
      if (res && res.data && res.data.data) {
        setCaptchaImg(res.data.data);
        setCaptchaImgVal(res.data);
      }
    });
  };

  /**
   * Input Handler
   * @param e (synthatic element)
   */
  const handleInputChange = async (e) => {
    let captchaVal = e.target.value;
    setCaptcha(captchaVal);
    props.onKeyPressCaptcha({ captchaVal: captchaVal, captchaData: captchaImgVal });
  };

  /**
   * Reset Captcha Error
   */
  // eslint-disable-next-line no-unused-vars
  const resetCaptchaError = async () => {
    if (this._isMount) {
      await this.setState({
        captchaInvalid: '',
      });
    }
  };
  const { captchaError } = props;

  return (
    <>
      <div className={captchaInvalid ? 'material' : 'material'} style={{ position: 'relative' }}>
        <InputField
          inputProps={{
            id: 'captcha',
            type: 'text',
            name: 'captcha',
            placeholder: 'Captcha',
            value: captcha,
            className: 'form-input-captcha',
            maxLength: 6,
            dataerror: props.errField.captchaErrorMessage,
          }}
          onChange={handleInputChange}
        />

        <i className="ic-survey icn-login"></i>
        <label data-label="" className="form-label"></label>
        {/* <span className="erroe-txt" style={{ left: "200px","fontSize": '10px'}}>{captchaInvalid||''}</span> */}

        <span className="password-show" style={{ top: '20px', left: '271px', fontSize: '20px', position: 'absolute', right: '-60px' }}>
          {captchaError ? (
            ''
          ) : (
            <>
              <img src={captchaImg.image} alt="" style={{ width: '52%' }} />
              <span style={{ position: 'relative', top: '3px', cursor: 'pointer', color: '#000' }} onClick={loadCaptchaImage} title="reload captcha">
                &#x21bb;
              </span>
            </>
          )}
        </span>
      </div>
    </>
  );
};

export default Captcha;
