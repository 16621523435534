import React from 'react';
import DateFormate from 'dateformat';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';

class CustomerTimeline extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            historyList: [],
            historyData: {},
            redirect: false
        }
    }
    componentDidMount = async () => {
        let historyData = this.state.historyData;
        historyData['lead_id'] = this.props.lead_id;
        historyData['dealer_id'] = this.props.dealer_id;
        await this.loadBuyesHistoryList();
    }


    loadBuyesHistoryList = async () => {
        MasterService.post('lead/lead/leadHistory', this.state.historyData, {
            'headers': { 'Authorization': this.props.auth_token }
        })
        .then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                this.setState({
                    historyList: response.data.data
                })
            } else if (response.status == 401 && response.data.status == 401) {
                this.setState({ redirect: true })
            }

        }).catch((error) => {
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        const { historyList } = this.state;
        return (
            <div className="comment-wrap">
                {historyList.length ?
                    <ul className="timeline">
                        {historyList && historyList.map((timelinedata, index) =>
                            <li key={index}>
                                <div className="timeline-badge"></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading border-BL clearfix">
                                        <div className=" edit-secL width20">
                                            <h4 className="timeline-title">{DateFormate(new Date(timelinedata.datetime), 'dd mmm, yyyy')}</h4>
                                            <p className="listingdate">{DateFormate(new Date(timelinedata.datetime), 'h:MM TT')}</p>
                                        </div>
                                        <div className="edit-secR">
                                        {timelinedata.activity !=='2' ? 
                                            <h4 className="timeline-title">{timelinedata.activity_text}
                                                {timelinedata.activity ==='5' ? 
                                                    <span className="pull-right">
                                                        {timelinedata.shared_by === 'whatsapp' || timelinedata.shared_by === 'WHATSAPP' ? <i className="ic-whatsapp icons-normal text-success"></i> : ''}
                                                        {timelinedata.shared_by === 'sms' || timelinedata.shared_by === 'SMS' ? <i className="ic-chat icons-normal text-light"></i> : ''}
                                                        {timelinedata.shared_by === 'EMAIL' || timelinedata.shared_by === 'email' || timelinedata.shared_by === 'Car_details'  ? <i className="ic-email icons-normal text-primary"></i> : ''}
                                                    </span>
                                                    : ''
                                                }
                                            </h4> 
                                            : ''
                                        }
                                        {timelinedata.comment ? <p className="listingdate">Comment: {timelinedata.comment}</p> : ''} 
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                    : <h5><center>{this.props.t('listLead.Customer_History_Not_Found')}</center></h5>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('lead') (connect(mapStateToProps, mapDispatchToProps)(CustomerTimeline));