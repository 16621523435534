import React, { Component } from "react";
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';
class StockCard extends Component {	
    constructor(props){
        super(props);
        this.state = {

        }
    }

    handleCheckboxChange = (event)=>{
        let car_id = event.target.getAttribute('carid');
        if (typeof this.props.updateCar === 'function') {
            this.props.updateCar(car_id);
        }
    }
	render() {
        let stockDataList = this.props.stockCarData;
		return (
                <ul className="row similarlist">
                    { stockDataList.length > 0 ?
                        stockDataList.map((stockData) =>
                        <li key={stockData.id} className="col-sm-4 col-md-3 col-lg-2 checkgreen">
                            <div className="thumbnail posrelative">
                                <div className="img_thumb"> 
                                { (stockData.car_profile_image) ?
                                    <img src={stockData.car_profile_image+'?tr=h-135'} alt="" className="img-responsive" />
                                    :
                                    <img src={require('./../assets/images/no-image.png')} alt="" className="img-responsive" />
                                } 
                                </div>
                                <div className="selectCheckbox">
                                    <InputField 
                                        inputProps={{
                                            id: "car_"+stockData.id,
                                            type: "checkbox",
                                            name: "car_"+stockData.id,
                                            label:"",
                                            carid: stockData.id,
                                        }}
                                        onChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <div className="caption clearfix">
                                    <p className="text-medium">{stockData.make+' '+stockData.modelVersion}</p>
                                    <div className="text-primary car-price"> {stockData.display_price}</div>
                                    <ul className="list-inline carpoints">
                                        {/* <li>{this.props.t('stockCard.manual')}</li> */}
                                        <li>{stockData.transmission}</li>
                                        <li className="text-muted">{stockData.km_driven} {this.props.t('stockCard.kms')}</li>
                                        <li className="text-muted">{stockData.display_reg_date}</li>                                    
                                        <li className="text-muted">{stockData.fuel_type}</li> 
                                    </ul>                          
                                </div>
                            </div>
                        </li>
                    ) :
                    <li><div className="posrelative text-center"><h6 className="text-danger">{this.props.t('stockCard.no_stock')}</h6></div></li>
                    }
                </ul>
        )
    }
}
export default withTranslation('lead')(StockCard);
            