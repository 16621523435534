import React, { Component } from 'react';
import DateFormate from 'dateformat';
import DatePicker from "react-datepicker";
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { PAYMENTMODE, BUSINESS_TYPE } from './../../../config/constant';
import Select from 'react-select';
import InputField from './../../elements/Input';
import Button from './../../elements/Button';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from './../../../service';
import { withTranslation } from 'react-i18next';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
class AuctionDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            city_id: "",
            loading: true,
            bank_state_id: "",
            location: "",
            dealership: "",
            business: "",
            kycMasterlist: props.kycMasterlist || [],
            dealer_id_hash: "",
            startDate: new Date(),
            kycDealerData: {},
            auctionDepositerData: {},
            kycDealerCallVerificationData: [],
            basic_details: props.basic_details || {},
            kyc_category_id: '',
            errors: {},
            kyc_errors: [],
            cv_link_errors: {},
            bankList:[],
            auctionDepositeList:[],
            fileUpload: [],
            allDepositeReceipt:[],
            counter:0,
            isDownloadRow: [],
            page_number:1,
            paginateData:{},
            runPaginate: false,
            selectedFile: null,
            formSubType:props.formSubType
        };
    }
    componentDidMount = async () => {
       // await this.getDealerAuctionDepositeDetail();
        await this.getBankList();
        await this.getAllAuctionDeposites();
        await this.getAllDepositeReceiptList();

        window.addEventListener('scroll', this.onScroll);

    }

    getBankList = async()=>{
        DealerService.getMasters(["bank_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ bankList: response.data.data.bank_list});
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }


    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handlebankListOptionChange = (ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }    
    // handleChange = date => {//deleteme duplicate function
    //     this.setState({
    //         startDate: date
    //     });
    // };
    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }
    handletKycDealerDataTextChange = (sname, ovalue) => {        
        let auctionMasterlist_new = { ...this.state.auctionDepositerData }        
        if(sname=='payment_date'){
            auctionMasterlist_new[sname] = ovalue;
        }else if(sname=='payment_mode'){
            auctionMasterlist_new[sname] = ovalue.value;
        }else{
            auctionMasterlist_new[sname] = ovalue.target.value;
        }
        this.setState({ auctionDepositerData: auctionMasterlist_new })
    }

    handletkycDealerCallVerificationTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerCallVerificationData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerCallVerificationData: kycMasterlist_new }, () => {
        })
    }
    
    handleCityOptionChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_city_id = ovalue.id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            basic_details: nextProps.basic_details,
            cityAllList: nextProps.cityAllList,
            filteredcity: nextProps.cityAllList,
            kycMasterlist: nextProps.kycMasterlist,
        });
    }
    stringifyFormData(fd) {
        var object = {};
        fd.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.parse(JSON.stringify(object));
    }
    handleKycDocChange = (idx) => (ovalue) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].kyc_child_id = ovalue.kyc_child_id;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }

    // getDealerAuctionDepositeDetail = async () => {
    //     DealerService.getAuctionDepositeDetails(this.get_dealer_id_hash).then(result => {
    //         if (result && result.data && result.data.status == 200) {
    //             let tempkycDealerData = {};
    //             if (result.data && result.data.data && result.data.data.id != null) {
    //                 tempkycDealerData = result.data.data;                    
    //             }                
    //             this.setState({ kycDealerData: tempkycDealerData, auctionDepositerData:tempkycDealerData, loading: false })
    //         }
    //         else {
    //             this.setState({ loading: false })
    //             toast.error(result.data.message || "Some Error Occurred");
    //         }
    //     }).catch(error => {
    //         this.setState({ loading: false })
    //         toast.error(error.message || "Some Error Occurred");
    //     });
    // }
    getAllAuctionDeposites = async (params={}) => { 
        let postData = {page_number: params['page'] || this.state.page_number}
        DealerService.getAllAuctionDeposites(postData).then(result =>{
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = []; 
                if (result.data && result.data.data) {
                    tempkycDealerData = result.data.data;                    
                } 
                let resultData = []; 
                if(postData.page_number ==1){
                    resultData = tempkycDealerData
                }else{
                    resultData = [...this.state.auctionDepositeList, ...tempkycDealerData]
                }              
                this.setState({ auctionDepositeList:resultData, loading: false, paginateData: result.data.pagination, runPaginate: false })
            }else {
                this.setState({ loading: false, runPaginate: false })
                toast.error(result.data.message || "Some Error Occurred");
            }

        }).catch(error => {
            this.setState({ loading: false, runPaginate: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }
    getAllDepositeReceiptList = async(params={})=>{

        DealerService.getAllDepositeReceiptList().then(result =>{
            if (result && result.data && result.data.status == 200) {
                let allreceipt = [];
                
                if (result.data && result.data.data) {
                    allreceipt = result.data.data;                    
                }                
                this.setState({ allDepositeReceipt:allreceipt, loading: false })
            }else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }

        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }
   
    s3UploadFiles = (allfiles, formPostdata) => {
        // let dealerKycDocSaveArr = [];//deleteme    
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];            
            allfiles.forEach(async (fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'deposite_receipts');
                formData.append("dealer_id", this.state.basic_details.id);
                if (filesData.length) {                                  
                    for(let uploadedFiles of filesData) {
                        formData.delete('images');
                        formData.append('images', uploadedFiles);

                        let s3Response = await InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }); 
                        if(s3Response.status == 200){
                            let dealerKycDocSaveJson = {};
                            let s3res_data = s3Response.data;                         
                            dealerKycDocSaveJson.file_name = s3res_data.data[0].file_url;
                            prom.push(dealerKycDocSaveJson);
                        }                                               
                    }
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        auction_deposite_id: formPostdata.deposite_id,
                        docs: prom
                    };
                    DealerService.saveDepositeReceipt(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ loading: false }); 
                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                    document.getElementById("fileName").value = ""
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            }); 

        }
    }
    saveAuctionDeposites = (event) => {
        event.preventDefault();        
            // var parentThis = this;//deleteme
            const form = event.target;
            const data = new FormData(form);
            const formdata = this.stringifyFormData(data);
            if (this.validFormData(formdata)) {
                let save_confirm = window.confirm(this.props.t('dealerDetails.auctionDeposites.validationForm.are_you_sure_save'));
                if (save_confirm == true) {
                this.setState({ loading: true })
                let allfiles = document.querySelectorAll('input[type="file"]');
                let dealerAuctionDepositeSaveJson = {
                    "dealer_id_hash": this.get_dealer_id_hash,
                    "deposite_amount": formdata.depositeamount || 0,
                    "deposite_amount_remark": formdata.depositeamountremarks,
                    "payment_date": formdata.payment_date,
                    "payment_mode": formdata.payment_mode,
                    "bank_id": formdata.bankname,
                    "branch_name": formdata.branch_name,
                    "receipt_ch_dd": formdata.chqdd,
                    "resp_name": formdata.resp_name,
                    "business_type": formdata.business_type
                };

                DealerService.saveAuctionDepositeDetails(dealerAuctionDepositeSaveJson).then(async result => {   
                    if (result && result.data && result.data.status == 200) {
                        this.setState({ loading: false,page_number:1 })
                        toast.success(result.data.message);
                        this.setState({auctionDepositerData:'', kycDealerData: {}});
                        await this.getAllAuctionDeposites();

                        this.s3UploadFiles(allfiles, {deposite_id: result.data.data.id});
                      
                        //await this.getAllDepositeReceiptList();
                    }
                    else {
                        this.setState({ loading: false })
                        toast.error(result.data.message || "Some Error Occurred");
                    }
                }).catch(error => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
            }
        }
    }
    onScroll = (event) => {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            try {
                //Handling multiple API call on single scroll & if total results are showing
                let tableDataLen = this.state.auctionDepositeList.length;
                
                if (this.state.paginateData.total !== tableDataLen && !this.state.runPaginate) {
                    
                    const { page_number } = this.state;
                    let loading = false;

                    if (page_number !== 0) {
                        loading = true;
                    }
                    
                    //CALL FOR API & SENT runPaginationFunc TRUE WHICH SHOWS IF TRUE THEN UPDATE DATA IN TABLE
                    // if(this._isMounted) {
                        this.setState({ page_number: page_number+1, loading: loading, runPaginate: true }, ()=>{

                        });
                    // }
                    this.getAllAuctionDeposites({page_number:page_number+1});
                }
            } catch (err) {
                //
            }

        }
    }
    validFormData = (formData) => {
        let errors = {};
        let formIsValid = true;
        let reg = new RegExp('^[0-9]+$');
        if(!formData['depositeamount']) {
            //
        }else if(!reg.test(formData['depositeamount'])){
            errors["depositeamount"] = this.props.t('dealerDetails.auctionDeposites.validationForm.only_number');
        }
        
        if (!formData['bankname']) {
            formIsValid = false;
            errors["bankname"] = this.props.t('dealerDetails.auctionDeposites.validationForm.bankname');
        } 
        if(!formData['payment_date']) {
            formIsValid = false;
            errors["payment_date"] = this.props.t('dealerDetails.auctionDeposites.validationForm.payment_date');
        }   
        if(!formData['chqdd']) {
            formIsValid = false;
            errors["chqdd"] = this.props.t('dealerDetails.auctionDeposites.validationForm.chqdd');
        }   
        if(!formData['payment_mode']) {
            formIsValid = false;
            errors["payment_mode"] = this.props.t('dealerDetails.auctionDeposites.validationForm.bankname');
        }    
        if(!this.state.selectedFile) {
            formIsValid = false;
            errors["selectedFile"] = this.props.t('dealerDetails.auctionDeposites.validationForm.bankname');
        } 
        this.setState({ errors: errors })
        return formIsValid;
    }
    onChangeHandler = event => {
        let files = event.target.files;    
        this.setState({ selectedFile: files })      
        let err = [] // create empty array
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf','application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (let x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.auctionDeposites.receipt_file_support_msg') + ' \n';
                // assign message to array
            }
        };
        for (var z = 0; z < err.length; z++) { // loop create toast massage
            event.target.value = null
            toast.error(err[z])
        }
        // if(err.length == 0){
        //     this.setState({fileUpload:files});
        // }
        return true;
    }

    downLoadReceipts =(auctionId)=>{
        this.setState({isDownloadRow: [...this.state.isDownloadRow, auctionId]}) //UPDATE AUCTION ID TO SHOW LOADER
        let updateIsDownloadRow = this.state.isDownloadRow.filter(id=> id!=auctionId ); //REMOVE ID FROM LOADER ARRAY

        DealerService.downloadDepositeReceipt({deposite_auction_id: auctionId}).then(async result => {   
            if (result && result.data && result.data.status == 200) {

                this.setState({ loading: false,isDownloadRow: updateIsDownloadRow })
                //AUTO DOWNLOAD FILES
                if(result.data.data && result.data.data.length){
                    const link = document.createElement('a');

                    //Split image name
                    const nameSplit=result.data.data[0].split("/");
                    const  duplicateName=nameSplit.pop();

                    link.href = `${result.data.data[0]}`;
                    link.download = `${duplicateName}`;
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                
                // toast.success(result.data.message);
            }
            else {
                this.setState({ loading: false,isDownloadRow: updateIsDownloadRow})

                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {

            this.setState({ loading: false, isDownloadRow: updateIsDownloadRow  })

            toast.error(error.message || "Some Error Occurred");
        });
    }

    approveDealer = async () => {
        var r = window.confirm(this.props.t('dealerDetails.kyc_details.approve_prompt'));

        if (r === true) {
            const formSubType = this.props.formSubType;
            let redirect_url = '';

            if (formSubType.indexOf("basic_detail") === -1) {
                redirect_url = `/dealer/${this.get_dealer_id_hash}/basic-details`
            } else if (formSubType.indexOf("outlet_info") === -1) {
                redirect_url = `/dealer/${this.get_dealer_id_hash}/outlet-info`
            }
            if (redirect_url!=='') {
                this.props.history.push(redirect_url);
                if (typeof this.props.onClick === 'function') {
                    this.props.onClick();
                }
            }


            if (redirect_url === '') {
                this.setState({
                    loading: true,
                });
                let dealerUpdateData = {}
                dealerUpdateData['status'] = '1';
                dealerUpdateData['current_status'] = this.state.basic_details.status;
                dealerUpdateData['dealer_id_hash'] = this.state.basic_details.dealer_id_hash;
                let response = await DealerService.dealerStatusChange(dealerUpdateData);
                if (response.data.status === 200) {
                    toast.success(this.props.t('dealerDetails.kyc_details.Dealer_approved'));
                    let basicDetails = this.state.basic_details
                    basicDetails.status = '1';
                    this.setState({
                        basic_details: basicDetails,
                    });
                } else {
                    toast.error(this.props.t('dealerDetails.kyc_details.dealer_activation_fail'));
                }
                this.setState({
                    loading: false,
                });
            }
        }
    }

    render() {  
        const bankList = this.state.bankList;
        // const { StateList } = this.props;//deleteme
        // const { kycMasterlist, kycDealerData, auctionDepositerData, auctionDepositeList , allDepositeReceipt, errors, kyc_errors,counter, isDownloadRow } = this.state;//deleteme allDepositeReceipt,kyc_errors,counter unused variable
        const { kycMasterlist, kycDealerData, auctionDepositerData, auctionDepositeList , errors , isDownloadRow } = this.state;
        let basicinfo = this.state.basic_details;        
        let masterlist = {};
        
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                masterlist['cat_' + val.id] = val.child;
            })
        }
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
                console.log(role)
            }
        }
        
        return (
            <div><h1>
                {this.props.t('dealerDetails.auctionDeposites.auction_lable')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span>
                {['3', '4', '6'].includes(this.state.basic_details.status) ?
                        <button className="btn btn-approve floatRight mrg-30" onClick={this.approveDealer.bind(this, 'approve')}>
                            {this.props.t('dealerDetails.kyc_details.approve')}
                        </button> : ""}
                </h1>

                <div className={(this.state.loading ? "card loading" : "card")}>
                    <div className="card-heading">
                        <h2 className="card-title">{this.props.t('dealerDetails.auctionDeposites.deposite_details')}</h2>
                    </div>

                    <div className="card-body">
                        <form onSubmit={this.saveAuctionDeposites} method="post" autoComplete="off" encType="multipart/form-data">
                            <div className="row">
                            <div className="col-sm-5 form-group">                                    
                                    <InputField type="text" name="depositeamount" error={(errors.depositeamount) ? errors.depositeamount : ''}             id="deposite_amount" placeholder={this.props.t('dealerDetails.auctionDeposites.desposite_amount')} value={auctionDepositerData.deposite_amount} label={this.props.t('dealerDetails.auctionDeposites.desposite_amount')} onChange={this.handletKycDealerDataTextChange.bind(this, 'deposite_amount')}/>
                                </div>                                
                                <div className="col-sm-5">
                                    <label>{this.props.t('dealerDetails.auctionDeposites.deposite_amount_remark')}</label>
                                    <textarea onChange={this.handletKycDealerDataTextChange.bind(this, 'deposite_amount_remark')} rows='2' className="form-control" name="depositeamountremarks" value={auctionDepositerData.deposite_amount_remark} autoComplete="off"></textarea>

                                </div>                               
                                 
                            </div>
                            <div className="row">
                            <div className="col-sm-5 form-group">
                                <label>{this.props.t('dealerDetails.auctionDeposites.payment_date')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    
                                        <DatePicker
                                            showYearDropdown 
                                            showMonthDropdown                                                       
                                            onChange={this.handletKycDealerDataTextChange.bind(this, 'payment_date')}
                                            selected={(auctionDepositerData.payment_date) ? new Date(auctionDepositerData.payment_date) : ''}
                                            name="payment_date"
                                            id="payment_date"
                                            placeholderText={this.props.t('dealerDetails.auctionDeposites.payment_date')}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                   {errors.payment_date &&
                                        <div className="error show" >{errors.payment_date}</div>
                                   }
                                </div>
                                <div className="col-sm-5">
                                <label>{this.props.t('dealerDetails.auctionDeposites.payment_mode')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                <Select
                                        id="payment_mode"                                        
                                        options={PAYMENTMODE}
                                        name="payment_mode"
                                        value={PAYMENTMODE.filter(payment=>payment.value == auctionDepositerData.payment_mode)}
                                        onChange={this.handletKycDealerDataTextChange.bind(this, 'payment_mode')}
                                        placeholder={this.props.t('dealerDetails.auctionDeposites.payment_mode')}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ value }) => value}
                                    />
                                    {errors.payment_mode &&
                                        <div className="error show" >{errors.payment_mode}</div>
                                   }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5 form-group">                                    
                                    <label>{this.props.t('dealerDetails.auctionDeposites.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id="bankname"
                                        onChange={this.handlebankListOptionChange}
                                        options={bankList}
                                        name="bankname"
                                        placeholder={this.props.t('dealerDetails.auctionDeposites.bank_name')}
                                        value={bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ bank_id }) => bank_id}
                                    />
                                    {errors.bankname &&
                                        <div className="error show" >{errors.bankname}</div>
                                    }
                                </div>
                                <div className="col-sm-5">
                                    <InputField type="text" name="branch_name" id="branch_name" placeholder={this.props.t('dealerDetails.auctionDeposites.branch_name')} value={auctionDepositerData.branch_name} label={this.props.t('dealerDetails.auctionDeposites.branch_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'branch_name')} />
                                </div> 
                            </div>
                            <div className="row">
                                <div className="col-sm-5 form-group">
                                    <InputField type="text" error={(errors.chqdd) ? errors.chqdd : ''} name="chqdd" id="chqdd" placeholder={this.props.t('dealerDetails.auctionDeposites.chq_no')} value={auctionDepositerData.receipt_ch_dd} label={this.props.t('dealerDetails.auctionDeposites.chq_no')} onChange={this.handletKycDealerDataTextChange.bind(this, 'receipt_ch_dd')}  required={true}/>
                                </div>
                                {/* <div className="col-sm-5">
                                <label>{this.props.t('dealerDetails.auctionDeposites.resp_name')}</label>
                                    <Select
                                        id="resp_name"                                        
                                        options={RESP_NAME}
                                        name="resp_name"
                                        placeholder={this.props.t('dealerDetails.auctionDeposites.resp_name')}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>  */}
                                <div className="col-sm-5 form-group">                                    
                                <label>{this.props.t('dealerDetails.auctionDeposites.business_type')}</label>
                                <Select
                                        id="business_type"                                        
                                        options={BUSINESS_TYPE}
                                        name="business_type"
                                        placeholder={this.props.t('dealerDetails.auctionDeposites.business_type')}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-sm-5 form-group">
                                    <label>{this.props.t('dealerDetails.auctionDeposites.upload_receipt')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <input type="file" name="fileName" id="fileName" className="form-control" onChange={this.onChangeHandler} />  
                                    {errors.selectedFile &&
                                        <div className="error show" >{errors.selectedFile}</div>
                                   }             
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.auctionDeposites.save')} type="submit" /></div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={(this.state.loading ? "card loading" : "card")}>
                    <div className="card-heading">
                        <h2 className="card-title">{this.props.t('dealerDetails.DepositeHistory.history')}</h2>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead style={{fontSize:'13px'}}>
                                    <tr>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.deposite_amt')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.payemnt_details')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.added_date')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.added_by')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.requested_by')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.reason')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.deposite_type')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.business')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.resp_name')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.Payment_sts')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.rejected_reason')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.download')}</th>
                                       <th>{this.props.t('dealerDetails.DepositeHistory.status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!auctionDepositeList || auctionDepositeList.length == 0) && <tr><td align="center" colSpan="13"><h6 className="text-center text-danger text-bold">No Record Found</h6></td></tr>}
                                    {auctionDepositeList && auctionDepositeList.length>0 && auctionDepositeList.map((row, k2) =>
                                    
                                        <tr key={k2}>
                                           <td>{row.deposite_amount}</td>
                                           <td>{row.receipt_ch_dd}</td>
                                           <td>{DateFormate(new Date(row.created_date),"yyyy-mm-dd")}</td>
                                           <td>{row.name}</td>
                                           <td></td>
                                           <td></td>
                                           <td>{(row.payment_mode==1)? 'Cash': (row.payment_mode==2)? 'Cheque':(row.payment_mode==3)? 'DD': (row.payment_mode==4)? 'NEFT':(row.payment_mode==5)? 'RTGS':(row.payment_mode==6)?'Card':''}</td>
                                           <td>{row.business_type}</td>
                                           <td>{row.resp_name}</td>
                                           <td></td>
                                           <td></td>
                                           <td>
                                               { 
                                                   isDownloadRow.includes(row.id)
                                                   ?
                                                        'downloading...'
                                                   :
                                                    <span style={{cursor: 'pointer'}} onClick={this.downLoadReceipts.bind(this, row.id)}>
                                                        <i className="ic-save_alt"></i>
                                                    </span>
                                               }
                                               
                                            </td>
                                           <td>{row.status == 1 ? 'Active': 'Inactive'}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuctionDetails)));
//export default withTranslation('dealers')(BasicDetails)