import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import MasterService from './../../../service/MasterService';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors:{},
            fields:{},
            redirect: 0,
        }
    }
    
    submitResetForm = (event)=>{
        event.preventDefault();
        if(this.validForm()){
            MasterService.post('dealer/dealer/change-password', this.state.fields)
            .then(function (response) {
                if(response.status == 200){
                    //let responseData = response.data;
                }
            }).catch(function (error) {
                console.log(error.message);
            });
        }
    }

    handleChange = event => {
        let fields = this.state.fields;
        const errors = this.state.errors;
        fields[event.target.name] = event.target.value;
        if(event.target.value !== ''){
            delete errors[event.target.name];
        }else{
            this.setErrorMessage(event.target.id, 'Please enter '+event.target.name);
        }
        this.setState({
            fields,
            errors
        });
    }
    
    validForm = ()=>{
        let fields = this.state.fields;
        // let error = {};//deleteme var not in use
        let formIsValid = true;
        if (!fields["old_password"]) {
            this.setErrorMessage('old_password', 'old_password is required.');
            formIsValid = false;
        }
        if (!fields["new_password"]) {
            this.setErrorMessage('new_password', 'new_password is required.');
            formIsValid = false;
        }
        if (!fields["re_new_password"]) {
            this.setErrorMessage('re_new_password', 're_new_password is required.');
            formIsValid = false;
        }
        return formIsValid;
    }
    
    setErrorMessage = (id, message)=>{
        document.getElementById(id).parentNode.querySelector('.error').innerHTML = message;
        document.getElementById(id).parentNode.querySelector('.error').classList.remove('hide');
        document.getElementById(id).closest('.form-field').classList.remove('active');
    }

    render() {
        return (
            <div className="item active">
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                                <h1 className="login-h1">Change Password</h1>
                                <p className="login-p">Create your new password. Password must be 8 – 50 characters with at least one upper and lower case letter, and one number</p>
                                    <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform" onSubmit={this.submitResetForm}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "old_password",
                                                        type: "password",
                                                        name: "old_password",
                                                        label:"Enter Old Password",
                                                        dataerror: "Password is required"
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "new_password",
                                                        type: "password",
                                                        name: "new_password",
                                                        label:"Enter New Password",
                                                        dataerror: "Password is required"
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "re_new_password",
                                                        type: "password",
                                                        name: "re_new_password",
                                                        label:"Re-enter New Password",
                                                        dataerror: "Password is required"
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <span id="invalidLogin" className="invalid-login"></span>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="change_pwd" type="submit" onClick={this.submitResetForm}>Reset Password</button>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;