import React, { useState, useRef } from "react";

function Accordion(props) {
  // const [setActive, setActiveState] = useState("");
  // const [setHeight, setHeightState] = useState("0px");
  let { defActive, defHeight, defOverflow } = { defActive: props.active == "true" ? "active" : "", defHeight: props.active == "true" ? "inherit" : "0px", defOverflow: props.active == "true" ? "inherit" : "hidden" }
  const [setActive, setActiveState] = useState(defActive);
  const [setHeight, setHeightState] = useState(defHeight);
  const [setOverflow, setOverflowState] = useState(defOverflow);
  
  const children = props.children;
  const content = useRef(null);

  function toggleAccordion() { 
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `inherit` // ${content.current.scrollHeight}px
    );
    setOverflowState(
      setActive === "active" ? "hidden" : `inherit` // ${content.current.scrollHeight}px
    );
  }

  

  return (
    
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div className="accordion__title">{props.title} <span className="stepprogress"><i className="sprite ic-tick"></i></span></div>        
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}`, overflow: `${setOverflow}` }}
        className="accordion__content"
      >
        <div className="accordion__text"> {children}
          </div>
      </div>
    </div>
  );
}

export default Accordion;