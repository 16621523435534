import React, { Component } from 'react';
import AuctionInventoryFilter from './AuctionInventoryFilter'
import AuctionFilterCard from './AuctionFilterCards'
import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';
import  AuctionService  from '../../../service/AuctionService';
// import * as HELPER from "./AuctionCommonData";
import FullPageLoader from '../Common/FullPageLoader';
import AuctionInventoryHeaderTimer from "./AuctionInventoryHeaderTimer";

class AuctionInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            auctionId: this.props.match.params.auctionId,
            inventoryCars:[],
            paginationData: {},
            auctionDetails: {},
            loading: true,
            buyer_id:0,
            updateInventoryList: false,
            inventoryListloading: true,
            inventoryFilters: {},
            endDate:'',
            isExpired:false,
            page:1
        }
    }

    componentDidMount = async () => {
        await this.updateReduxData();
        await Promise.all([
            //GET AUCTION DETAILS
            this.getAuctionDetails(),
            
          ]);
        
          this.setState({loading: false})
        }

    /**
     * GET AUCTION DETAILS
     */
    getAuctionDetails = async () => {
        await AuctionService.getAuctionDetails({auctionId: this.state.auctionId}).then(auctionDetails=>{
            if(auctionDetails && auctionDetails.status === 200){
                this.setState({auctionDetails: auctionDetails.data.data })
                if(new Date().getTime()< new Date(auctionDetails.data.data.end_date_time).getTime()){
                     //RUN TIMER
                    this.initTimer();
                }else{
                    let isExpired=true;
                    this.setState({isExpired})
                }
            }
        })

        
       
    }

    /**
     * GET AUCTION INVENTORY
     */
    getAuctionInventories = async (params={},isSearch=false,watchDetail=null) => {
        this.setState({inventoryListloading: true});
        let { buyer_id , auctionId, inventoryFilters,page } = this.state; 
        let pageNum = (isSearch)?1:page; 
        if(watchDetail){  
            if(typeof this.state.inventoryCars =='object'){
                let inventoryCars = this.state.inventoryCars.map((ob) => {
                    return {
                        ...ob,
                        watchlist_status: (+ob.vid === +watchDetail.car_id)? (watchDetail.type === 'add')?1:0 : ob.watchlist_status
                    }
                });    
                this.setState({inventoryCars: inventoryCars,inventoryListloading:false,updateInventoryList: true });
            }    
        }else{
            await AuctionService.getAuctionCarsList({auction_id: auctionId, buyer_id: buyer_id, page:pageNum, ...params, ...inventoryFilters}).then(auctionDetails=>{
                this.setState({inventoryListloading: false})

                if(auctionDetails && auctionDetails.status === 200){
                    let finalResult = auctionDetails.data.data;   
                    if(typeof this.state.inventoryCars =='object' && pageNum>1){
                        finalResult = this.state.inventoryCars.concat(finalResult); 
                    }   
                    this.setState({updateInventoryList: true, inventoryCars: finalResult, paginationData: auctionDetails.data && auctionDetails.data.pagination ? auctionDetails.data.pagination : {},page: pageNum +1 })
                }
            })
        }
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer = loginResponse.data.dealer_data[0];
            this.setState({ buyer_id: dealer.dealer_id })
        } else {
            this.setState({ redirect: true })
        }
    }

    searchInventory = async(filterData) => {
        await this.setState({inventoryFilters: filterData})
        this.getAuctionInventories(filterData, true)
    }

    updateComponentState = (params) => {
        this.setState(params)
    }

    initTimer = () => {
        let {auctionDetails} = this.state;
        let auctionDate = (new Date().getTime() > new Date(auctionDetails.start_date_time).getTime()) ? auctionDetails.end_date_time : auctionDetails.start_date_time;

        this.setState({endDate: auctionDate})

        // this.intervalManger = setInterval(()=> {
        //     let endDate ='';

        //     if(auctionDetails){
        //         if( new Date(auctionDate).getTime() - new Date().getTime() > 0){
        //             endDate = auctionDate;                    
        //             this.setState({endDate})
        //         }else {
        //             clearInterval(this.intervalManger);
        //             window.location.reload();
        //         }
               
        //     }

        // },1000) 
    }
    // componentWillUnmount=()=>{
    //     // clearInterval(this.intervalManger);
    // }
    render() {

        const { inventoryCars, paginationData, auctionDetails, loading, updateInventoryList, inventoryListloading, buyer_id,endDate ,isExpired} = this.state;
        let loadPage = (loading || inventoryListloading)? true:false;        
        return (
            <div className={"container-fluid auction-main"}>

                <FullPageLoader show={loading || inventoryListloading} />

                <div className="auction-list-top">
                    <div className="heading">
                        <div className="heading-timer">
                            <h1>{auctionDetails && auctionDetails.name}</h1>
                            <span className="timer">
                                <i className="ic-access_timequery_builderschedule"></i>
                                {isExpired ? "Expired" : <AuctionInventoryHeaderTimer endDate={endDate}  />  }
                               
                            </span>
                            <ul>
                                <li>
                                    {(auctionDetails && auctionDetails['city_name']) || ''}
                                </li>
                                <li>
                                    {(auctionDetails && auctionDetails['business_type_name']) || ''}
                                </li>
                                <li>
                                    {paginationData ? paginationData.total : 0} Cars
                                </li>
                            </ul>
                        </div>
                        {/* <div className="search-bx">
                            <input type="text" placeholder="Search" />
                            <i className="ic-search searc-icn"></i>
                        </div> */}
                    </div>
                </div>
                <div className="auction-filter-sec">
                    {
                        buyer_id
                        ?
                            <AuctionInventoryFilter searchInventory={this.searchInventory} buyer_id={buyer_id}/>
                        :
                            ''
                    }
                </div>
                <div className="auction-filter-card-outer">
                    <AuctionFilterCard auctionData={ {inventoryCars, paginationData, updateInventoryList, auctionDetails,loading:loadPage} } updateTabList={this.getAuctionInventories} updateParentComponentState={this.updateComponentState}
                                totalCount={paginationData.total ? paginationData.total:0}
                            />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(AuctionInventory));