import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import secureStorage from "../../../config/encrypt";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import IFCategoryListing from "./IFCategoryListing";
import IFCategoryManagement from "./IFCategoryManagement";
import ModalPopup from "../../elements/Popup";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";

class InventoryFunding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ifCategoryListing: [],
            ifCategoryListingHistory: [],
            adminUserData: {},

            ifAddCategoryPopup: false,

            loading: false,

            redirect: false,
        };
    }
    updateReduxData = async () => {
        if (secureStorage.getItem("loginUserInfo")) {
            var loginResponse = secureStorage.getItem("loginUserInfo");
            this.setState({ adminUserData: loginResponse.data.user_data });
            this.props.updateStateIntoRedux("LOGIN_USER", loginResponse);
            this.afterLoginSetState(loginResponse);
        } else {
            this.setState({ redirect: true });
        }
    };

    componentDidMount = async () => {
        await this.updateReduxData();
        await this.getIFCategoryListAll();
    };

    afterLoginSetState = (loginResponse) => {
        let role = (loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role) || null;
        if (role != "admin") {
            this.setState({ redirect: "/" });
        }
    };

    getIFCategoryListAll = () => {
        this.setState({ loading: true })
        MasterService.post(`/dealer/inventory-funding/get_all_if_category_list`, {}).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                this.setState({ ifCategoryListing: responseData });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                toast.error(response.data.message);
            }
        });
    };

    closeIfAddCategoryModel = () => {
        this.getIFCategoryListAll();
        this.setState({ ifAddCategoryPopup: false });
    };

    handleIfAddCategory = (event, ifCategoryId, ifCategoryName) => {
        this.setState({ ifAddCategoryPopup: true });
    };

    render() {
        console.log("===========this.props=============", this.props);
        if (this.state.redirect) {
            return <Redirect to={"/login"} />;
        }
        // let role_access = []//deleteme
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            // role_access = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role_data && loginResponse.data.user_data.role_data.length > 0 ? loginResponse.data.user_data.role_data : []//deleteme
        }
        return (
            <div className={this.state.loading ? "container-fluid loading" : "container-fluid"}>
                <div className="addStock">
                    <div className="card-heading">
                        <div className="pull-left">
                            <h1 className="card-title">{this.props.t("Listing.Inventory_Funding_Listing")}</h1>
                        </div>
                        <div className="pull-right">

                            <button className="btn btn-success pull-right" onClick={(e) => this.handleIfAddCategory(e)}>
                                {this.props.t("Listing.Add_Category")}
                            </button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix">
                        <IFCategoryListing
                            ifCategoryListing={this.state.ifCategoryListing}
                            adminUserData={this.state.adminUserData}
                            getIFCategoryListAll={this.getIFCategoryListAll}
                        />
                    </div>
                </div>

                {this.state.ifAddCategoryPopup === false ? null : (
                    <ModalPopup
                        id="if_add_category"
                        className="modal ViewHistory data-table"
                        title={`Add Inventory Funding Category`}
                        modalClose={this.closeIfAddCategoryModel}
                    >
                        <IFCategoryManagement adminUserData={this.state.adminUserData} isEdit={false} closeIfAddCategoryModel={this.closeIfAddCategoryModel} />
                    </ModalPopup>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};
export default withTranslation("inventory_funding")(connect(mapStateToProps, mapDispatchToProps)(InventoryFunding));
