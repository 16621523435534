import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalPopup from '../../elements/Popup';
import MasterService from './../../../service/MasterService';
import BlackListPopup from './BlackListPopup';
class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AccountManagerListData: props.AccountManagerListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            dealerUpdateData: {
            },
            blackListPopup: false,
            blackListUpdateData: {},
            currentIndex: null,
            current_org_name: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            AccountManagerListData: nextProps.AccountManagerListData,
            loading: nextProps.loading,
            pageloading: nextProps.pageloading
        });
    }

    handleChange = (idx, id) => async ({ target }) => {
        let org = this.state.AccountManagerListData[idx] || {}
        let active = target.checked ? "Active" : "Inactive";
        var r = window.confirm(this.props.t('Listing.Search_Result.alert_active_inactive', { status: active, dealer_name: org.organization }));
        let dealerUpdateData = this.state.dealerUpdateData;
        if (r == true) {
            if (target.checked) {
                dealerUpdateData['status'] = '1';
                dealerUpdateData['id'] = id;

            } else {
                dealerUpdateData['status'] = '0';
                dealerUpdateData['id'] = id;
            }
            this.setState({ dealerUpdateData });
            var thisObj = this;
            let dealerListTempData = [...this.state.AccountManagerListData];
            let checkVal = target.checked;
            const response = await MasterService.post('dealer/account-manager/status_change', { ...this.state.dealerUpdateData });
            thisObj.flag = false;
            if (response.status == 200) {
                if (checkVal) {
                    target.parentNode.style.textDecoration = "";
                    dealerListTempData[idx].status = 1;
                } else {
                    target.parentNode.style.textDecoration = "line-through";
                    dealerListTempData[idx].status = 2;
                }
                thisObj.setState({ AccountManagerListData: dealerListTempData });
            }


        }
    }
    handleBlackList = (idx, dealer_id_hash, status) => async ({ target }) => {
        let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
        tempBlackListUpdateData.status = status;
        tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
        let org_name = this.state.AccountManagerListData[idx] && this.state.AccountManagerListData[idx].organization
        this.setState({ blackListPopup: true, blackListUpdateData: tempBlackListUpdateData, currentIndex: idx, current_org_name: org_name }, () => { });
    }

    closeModal = () => {
        this.setState({ blackListPopup: false });
    }
    updateDealerListData = (updatedObj, index) => {
        let dealerListTempData = [...this.state.AccountManagerListData];
        dealerListTempData[index].status = updatedObj.status;
        this.setState({ AccountManagerListData: dealerListTempData });
    }
    render() {
        return (
            <div className="result-wrap">
                <div className="card">
                    <div className="pad-15">
                        <div className="row">
                            <div className="col-sm-8">
                            </div>
                            <div className="col-sm-4 text-right pad-t10">
                                <NavLink to="/admin/add-manager" className="btn btn-success">{this.props.t('Listing.add_ac_manager')}</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('Listing.Search_Result.ID')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Name')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Email')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Mobile')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Actions')}</th>
                                        {/* <th>Active</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.AccountManagerListData.length == 0 ? <tr><td align="center" colSpan="7"><h6 className="text-center text-danger text-bold">{this.props.t('Listing.Search_Result.No_Record_Found')}</h6></td></tr> : null}
                                    {(this.state.AccountManagerListData.length != 0) && this.state.AccountManagerListData.map((managerdata, k) =>
                                        <tr key={k}>
                                            <td>{managerdata.id}</td>
                                            <td>{(managerdata.name) ? managerdata.name : null}</td>
                                            <td>{(managerdata.email) ? managerdata.email : null}</td>
                                            <td>{(managerdata.mobile) ? managerdata.mobile : null}</td>
                                            <td>
                                                <div className="btn-group btn-group-small mrg-r10">

                                                    <Link to={`/admin/add-manager/${managerdata.id}`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                                    {
                                                        (managerdata.is_active == '1') ? <>
                                                            <label className="switch-btn btn btn-link" htmlFor={"active" + managerdata.id} >
                                                                <input className="switch-btn" id={"active" + managerdata.id} value="open" name={"active" + managerdata.id} type="checkbox" onClick={this.handleChange(k, managerdata.id)} checked={(managerdata.status == '1') ? true : false} />
                                                                <div className="slider round"></div>
                                                                <span className="switch-label"></span>
                                                                {this.props.t('Listing.Search_Result.Active')}
                                                            </label></>
                                                            : ""
                                                    }
                                                </div>

                                            </td>
                                        </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {this.state.blackListPopup == false ? null :
                    <ModalPopup id="black_list" className="modal" title={(parseInt(this.state.blackListUpdateData.status) == 5) ? this.props.t('Listing.Search_Result.Black_List_Dealer') : this.props.t('Listing.Search_Result.White_List_Dealer')} modalClose={this.closeModal}>
                        <BlackListPopup modalClose={this.closeModal} blackListUpdateData={this.state.blackListUpdateData} updateDealerListData={this.updateDealerListData} currentIndex={this.state.currentIndex} current_org_name={this.state.current_org_name} />
                    </ModalPopup>
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(SearchResult));