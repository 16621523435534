import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class MakeReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetcarId: props.resetcarId,
            dealer_id: props.dealerId,
            redirect: false
        }
    }
    resetStock = () => {
        var thisObj = this;
        var postObj = { car_ids: this.state.resetcarId, dealer_id: this.state.dealer_id };
        MasterService.post('inventory/inventory/reset_inventory', postObj)
            .then(function (response) {
                if (response.data.status == 200) {
                    thisObj.setState({ redirect: true }, () => {} );
                   toast.success(response.data.message);
                   thisObj.props.modalClose();
                } else {
                   toast.error(response.data.message);
                    thisObj.props.modalClose();
                }
            })
            .catch(function (response) {
            });
    }

    cancelrequest = () => {
        var thisObj = this;
        thisObj.props.modalClose();
    }
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/manage-stock'} />);
        }
        let maxResetMsg = [];
        maxResetMsg.push(<p key='1'>{this.props.t('listInventory.reset_msg')}</p>);
        return (<div>
            <div className='wrap row'> <div className="col-sm-12">{maxResetMsg}</div></div>
             <div className='wrap row'>
                <div className="col-sm-6">
                </div>
                <div className="col-sm-2">
                    <Button type="button" onClick={this.resetStock} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Yes" />
                </div>
                <div className="col-sm-2">
                    <Button type="button" onClick={this.cancelrequest} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="No" />
                </div>
            </div>
        </div>
        );
    }
}

export default withTranslation('inventory')(MakeReset);