import  { Component } from 'react';
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
class Logout extends Component{
    componentDidMount() {
        //secureStorage.removeItem('loginUserInfo');
        secureStorage.clear();
        this.props.updateStateIntoRedux('LOGOUT_USER', '');
        window.location.href='/login';
    }
    render(){
        return('');
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
  }

export default connect(null, mapDispatchToProps)(Logout);