import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import OtpValidation from './otp-validate';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import {MOBILE_VALIDATION} from './../../../config/constant';
import {CONF_VAL} from './../../../config/constant';
import Captcha from "../../common/Captcha";

var countryCodeListOption;
class OtpLogin extends Component {
    constructor(props){
        super(props);
        this.state = {
            showotpFrm:false,
            errors:{},
            fields:{},
            redirect: 0,
            loading: false,
            countryCodeList:[],
            reloadCaptcha:1,
            inputFieldCaptcha:{captcha:""},
            passwordType:"password",
            //fields:{mobileCountryCode:""}
        }
    }
    componentDidMount =  ()=>{
        this.getMobileCountryCode();
    }

    getMobileCountryCode = ()=>{
        MasterService.get('core/commonservice/master?master[]=country_code').then((response)=>{
            if(response.status === 200 && response.data.status === 200){
                let mobileCountryCode = response.data.data.country_code;
                countryCodeListOption = mobileCountryCode.map(function (countryCode) {
                    return { label: countryCode.value, value: countryCode.code };
                  })
                let fields = this.state.fields;
                fields['mobileCountryCode'] = countryCodeListOption.length ? countryCodeListOption[0]['value'] : '';
                this.setState({countryCodeList: countryCodeListOption,fields:fields});
            }
        });
    }


    handleChange = event => {   
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        if(event && event.captchaData && event.captchaData.status==200){    
            fields["captchaVal"] = event.captchaVal;
            fields['captchaId'] = event.captchaData.data.value;
            if(event.captchaVal ==''){
                errors['captchaErr'] = 'Please enter valid captcha';
            }
        }else{
            fields[event.target.name] = event.target.value;
            if(event.target.value !== ''){
                delete errors[event.target.name];
            }else{
                errors[event.target.name] = 'Please enter '+event.target.name;
            }
        }                
        this.setState({ fields: fields,errors: errors });
    }
    
    submitOtpForm = (event)=>{
        var parentObj = this;
        event.preventDefault();
        if(this.validForm()){
            this.setState({loading: true})
            let payload = {
                ...this.state.fields,
                withPassword:true
            }
            MasterService.post('account/user/send_otp', payload)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    // let responseData = response.data;//deleteme var not in use
                    parentObj.setState({showotpFrm:true, loading: false});
                    toast.success(response.data.message)
                }else{
                    parentObj.setState({loading: false})

                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                parentObj.setState({loading: false})

                window.commonErrorToast(error.message);
            });
        }
    }

    validForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!fields["mobile"]) {
            errors['mobile'] = this.props.t('joinus.enter_mobile');
            formIsValid = false;
        }else if(!mobileRule.test(fields['mobile'])){
            errors['mobile'] = this.props.t('joinus.'+CONF_VAL.VALID_MOBILE_MSG);
            formIsValid = false;
        }
        
        if (!fields["password"]) {
            errors['password'] = this.props.t('login.enter_password');
            formIsValid = false;
        }

        if (errors["captchaErr"] || (!fields["captchaVal"])) {  
            if(!fields["captchaVal"]){
                errors['captchaErr'] = 'Captcha is required';  
                errors['captchaErrorMessage'] = 'Captcha is required';
            }else if(errors["captchaErr"]){
                errors['captchaErr'] = 'Please enter valid captcha';  
                errors['captchaErrorMessage'] = 'Please enter valid captcha';
                this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
            }                   
            formIsValid = false;
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    validateOtpAndLogin = (event)=>{
        var parentObj = this;
        let errors = {...this.state.errors};
        event.preventDefault();
        if(this.validOtpForm()){
            this.setState({loading: true})

            let postData = Object.assign(this.state.fields);
            delete postData.mobileCountryCode;
            delete postData.captchaId;
            delete postData.captchaVal;
            delete postData.password;
            MasterService.post('account/user/login-otp', postData)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                    secureStorage.setItem('loginUserInfo', responseData);
                    parentObj.props.updateStateIntoRedux('LOGIN_USER', responseData);
                    parentObj.setState({redirect: 1, loading: false })
                }else{
                    window.commonErrorToast(response.data.message);
                    errors['otp'] = response.data.message;
                    parentObj.setState({errors: errors, loading: false})
                }
            }).catch(function (error) {
                parentObj.setState({loading: false})

                console.log(error.message);
            });
        }
    }

    togglePassword = (e) => {
        e.preventDefault();
        if (this.state.passwordType === 'password') {
            this.setState({passwordType:'text'})
        }else{
            this.setState({passwordType:'password'})
        }
    };

    captchaFiledHandler = (childData) => {
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        let captcahId = childData.captchaData.data.value;
        //let bufferObj = Buffer.from(childData.captchaData.data.value, "base64");
        //let decodedCaptcha = bufferObj.toString("utf8");
        let tempErrors=this.state.errors;
        let tempInputFieldCaptcha=this.state.inputFieldCaptcha;
        if(captcahId){                  
           tempErrors['captchaErr']="";           
           tempInputFieldCaptcha['captcha']=childData.captchaVal;               
        } else {            
            tempErrors['captchaErr']="Please enter valid captcha.";             
        }
        
        if (childData) {
            if(childData && childData.captchaData && childData.captchaData.status==200){    
                fields["captchaVal"] = childData.captchaVal;
                fields['captchaId'] = childData.captchaData.data.value;
                if(childData.captchaVal ==''){
                    errors['captchaErr'] = 'Please enter valid captcha';
                }
            }else{
                fields['captcha'] = childData.captchaVal;
                if(childData.captchaVal !== ''){
                    delete errors['captcha'];
                }else{
                    errors['captcha'] = 'Please enter captcha';
                }
            }                
            this.setState({ fields: fields,errors: errors });
        }
        this.setState({tempErrors});        
    }

    validOtpForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!fields["otp"]) {
            errors['otp'] = this.props.t('joinus.enter_otp');
            formIsValid = false;
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    render() {
        if (this.state.redirect == 1) {
            return (<Redirect to={'/dashboard'} />);
        }
        const {errors,countryCodeList,loading} = this.state;
        return (
            <div id="otp-forms">
                {this.state.showotpFrm === false?
                <form method="post" id="otp-form1" className=" text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                   <div className="phonewithcountry">
                        <div className="countrycode">
                            <Select
                                options={countryCodeList}
                                onChange={this.handleChange}
                                value={countryCodeList.filter(({ value }) => value === this.state.fields.mobileCountryCode)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="countrymobile">
                            <InputField
                                inputProps={{
                                    id: "mobile",
                                    type: "tel",
                                    name: "mobile",
                                    label:this.props.t('login.mobile'),
                                    dataerror: errors.mobile,
                                    maxLength:"10"
                                }}
                                onChange={this.handleChange}
                            />
                        </div>
                   </div>
                   <div className="mobilePassword">
                    <InputField
                        inputProps={{
                            id: "password",
                            type:this.state.passwordType,
                            name: "password",
                            label: this.props.t('login.password'),
                            dataerror: errors.password
                        }}
                        onChange={this.handleChange}
                    />
                        <span style={{cursor:"pointer",position: "absolute",right: "8px",marginTop: "-50px"}} >{ this.state.passwordType == 'password' ? <i onClick={this.togglePassword} className='ic-remove_red_eyevisibility'></i> :<i onClick={this.togglePassword} className='ic-remove_red_eyevisibility'></i> }</span>
                </div>
                <div className="mobilecaptch">
                    <Captcha onKeyPressCaptcha={this.captchaFiledHandler} errField={this.state.errors} inputField={this.state.inputFieldCaptcha} reloadCaptcha={this.state.reloadCaptcha} />
                </div>

                         
                    <div className="form-group" id="showbutton">
                        {
                            loading
                            ?
                                <button type="submit" className="btn btn-primary btn-lg btn-block" id="send-otp" disabled>{this.props.t('login.please_wait')}</button>
                            :
                                <button type="submit" className="btn btn-primary btn-lg btn-block" id="send-otp" onClick={this.submitOtpForm}>{this.props.t('login.get_otp')}</button>
                        }
                        
                    </div>
                </form>
                :
                <OtpValidation errors={this.state.errors} loading={this.state.loading} onClick={this.validateOtpAndLogin} onChange={this.handleChange} showOtp={this.state.showOtp}  sendOtpEmail={this.submitOtpForm} />
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
 }

 const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
 }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)( OtpLogin));
