import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import DragSortableList from 'react-drag-sortable';
import ImageDraggableList from './ImageDraggableList'

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 15,
    marginRight: 15,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: "relative"
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: "relative"
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Previews(props) {
    const [files, setFiles] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            let validreq = true;
            acceptedFiles.forEach((file) => {
                let image_size = parseInt(file.size, 10);
                if (image_size < 25000 || image_size > 8000000) {
                    toast.error(props.t('addInventory.image_size_valid'));
                    validreq = false;
                }
            })
            let uploadFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            if (validreq) {
                setFiles(
                    files.concat(uploadFiles)
                );
            }
        }
    });

    const thumbs = files.map((file, index) => {
        return {
            id: `item-${index+1}`,
            content: <div onClick={e => e.stopPropagation()} style={thumb} key={index}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                        alt=''
                    />
                </div>
                <span className="deleteImg" onClick={(e) => { deleteImage(e, index) }}><i className="ic-clearclose"></i></span>
                {/* <span className="rotateImg"><i className="sprite ic-rotate"></i></span> */}
            </div>
        }
    });


    if (props.preUploadedImages && props.preUploadedImages.length && firstLoad) {
        const preUploadedImages = props.preUploadedImages.map((file, index) => {
            return {
                preview: file.url + '?resize=100x100',
                filename: file.image_name
            }
        });
        if (!files.length) {
            setFiles(
                preUploadedImages
            )
            setFirstLoad(false);
        }
    }


    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        // files.forEach(file => URL.revokeObjectURL(file.preview));        
        props.handelFilesUpload(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const deleteImage = (e, eleIndex) => {
        e.stopPropagation();
        files.forEach((file, idx) => {
            if (eleIndex == idx) {
                URL.revokeObjectURL(file.preview);
            }
        });
        let filesArr = [...files];
        filesArr.splice(eleIndex, 1);
        setFiles(
            filesArr
        );
    }

    const onSort = (sortedList) => {
        let filesArr = [...files];
        let _sortedList = []
        for (let i = 0; i < sortedList.length; i++) {
            filesArr.forEach((item, index) => {
                if (index == sortedList[i].content.key) {
                    _sortedList.push(item);
                }
            });
        }
        setFiles(_sortedList);
    }

    // var placeholder = (//deleteme var not in use
    //     <div className="placeholderContent"></div>
    // );

    return (
        <section className={files.length?'fileuploadbox updated':'fileuploadbox'}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>{props.t('addInventory.image_drag_msg')}</p>                
            </div>
            <aside style={thumbsContainer}>
                {/* <DragSortableList placeholder={placeholder} items={thumbs} moveTransitionDuration={0.3} onSort={onSort} type="grid" /> */}
                <ImageDraggableList images={thumbs} onSort={onSort}/>
            </aside>
            <p className="text-light">{props.t('addInventory.set_profile_pic_msg')}</p>
        </section>
    );
}

export default withTranslation('inventory')(Previews);
