import { Link } from "react-router-dom";
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import Select from 'react-select';
import { DealerService } from './../../service';
import { withTranslation } from 'react-i18next';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
    this.state = {
      redirect: false,
      dashboardcardinfo: [],
      dashboardleadcard: [],
      formData: { lead_report: "today" },
      currentMonth:monthNames[today.getMonth()],
      countReportLeadDpd: [
        {
          label: 'today',
          value: 'today',
        },
        // {
        //   label: 'past_7d',
        //   value: 'past_7d',
        // },
        {
          label: 'last_30d',
          value: 'last_30d',
        },
        {
          label: 'this_month',
          value: 'this_month',
        },
        {
          label: 'last_month',
          value: 'last_month',
        },
        {
          label: 'last_90d',
          value: 'last_90d',
        }
      ],
    };
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse)
    } else {
      this.setState({ redirect: '/login' })
    }
  }
  afterLoginSetState = (loginResponse) => {
    // let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;//deleteme
    let role = loginResponse?.data?.user_data?.role || null;
    if (role != "dealercentral") {
      this.setState({ redirect: '/' })
    }
  }
  componentDidMount = async () => {
    await this.updateReduxData();
    await this.getdashboardcard();
    await this.getdashboardleadcard(this.state.formData && this.state.formData.lead_report);
  }
  getdashboardcard = async () => {
    DealerService.getdashboardcard().then(result => {
      if (result && result.data && result.data.status == 200) {
        this.setState({ dashboardcardinfo: result.data.data }, () => { })
      }
      else {
        this.handelError(this, result.data);
      }
    }).catch(error => {

    });
  }
  handleChange = (sname, ovalue) => {
    let formData = this.state.formData;
    formData[sname] = ovalue.value;
    this.setState({
      formData
    }, () => {
      this.getdashboardleadcard(ovalue.value);
    });
  }
  getdashboardleadcard = async (oldReportDays) => {
    let dashboardCountJson = { "filter_by": oldReportDays };
    DealerService.getdashboardleadcard(dashboardCountJson).then(result => {
      if (result && result.data && result.data.status == 200) {
        this.setState({ dashboardleadcard: result.data.data }, () => { })
      }
      else {
        this.handelError(this, result.data);
      }
    }).catch(error => {

    });
  }
  render() {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />);
    }
    const { dashboardcardinfo, dashboardleadcard, countReportLeadDpd, formData } = this.state;
    const goto = (card, filter) => {
      if (card && card.clickable) {
        let pathname;
        if (card._key.indexOf("DASHBOARD_CARD_CAR") > -1) pathname = '/manage-stock'
        if (card._key.indexOf("DASHBOARD_CARD_LEAD") > -1) pathname = '/lead'
        if (card._key.indexOf("DASHBOARD_CARD_TOTAL_LEAD") > -1) pathname = '/lead'
        return pathname && pathname + "?" + (card.filter || filter).toString();
      }
    }
    return (
      <div className="dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8">

              {dashboardcardinfo && dashboardcardinfo.map((dashboardCard, index) =>
                <div key={index} className="clearfix">
                  <div className="card background-efOne mrg-T20">
                    <div className="card-heading">
                      <h2 className="card-title">
                        
                          {
                            dashboardCard._key == "DASHBOARD_CARD_LEAD" ? 
                            <div>
                           {this.props.t('Dashboard.'+this.state.currentMonth)} {this.props.t('Dashboard.'+dashboardCard._key)}
                            </div>:<div>
                            {this.props.t('Dashboard.'+dashboardCard._key)}
                            </div>
                          }
                        
                       
                      </h2>
                    </div>
                    {/* <div className="card-heading"><h2 className="card-title">
                  {
                      dashboardCard._key == "DASHBOARD_CARD_LEAD" ? 
                      <div className="clearfix">
                        {dashboardCard.title}
                        <div className="pull-right lead-bytime">
                        <Select
                          value={formData.lead_report}
                          onChange={this.handleChange.bind(this, 'lead_report')}
                          options={countReportLeadDpd}
                          name="lead_report"
                          placeholder="Select Lead Report"
                          value={countReportLeadDpd.filter(({ value }) => value === formData.lead_report)}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ value }) => value}
                      />
                        </div>
                        
                      </div>
                      : <div>{dashboardCard.title}</div>
                      }
                  
                  </h2>
                  </div> */}
                    <div className="card-body">
                      <ul className="lead-list clearfix">
                        <li key={index} className="col-sm-3">
                          <Link to={goto(dashboardCard.card_details.parent)}>
                            <p className="leadno">{dashboardCard.card_details.parent.value}</p>
                            <p className="leadlabel">
                              {/* {dashboardCard.card_details.parent.label} */}
                              {this.props.t('Dashboard.'+dashboardCard.card_details.parent._key)}
                              </p>
                          </Link>                       
                          {/* <a onClick={goto(dashboardCard.card_details.parent)}>
                              <p className="leadno">{dashboardCard.card_details.parent.value}</p><p className="leadlabel">{dashboardCard.card_details.parent.label}</p>
                            </a> */}
                        </li>
                        {
                          dashboardCard.card_details.sub_parent && dashboardCard.card_details.sub_parent.map((report, indexchild) =>
                            <li key={(indexchild + 1)} className="col-sm-3">
                              <Link to={goto(report)}>
                                <p className="leadno">{report.value}</p><p className="leadlabel">{this.props.t('Dashboard.'+report._key)}</p>
                              </Link>
                              {/* <a href="#">
                                <p className="leadno">{report.value}</p><p className="leadlabel">{report.label}</p>
                              </a> */}
                            </li>
                          )
                        }

                        {
                          dashboardCard.card_details.child && dashboardCard.card_details.child.map((report, indexchild) =>
                            <li key={(indexchild + 1)} className="col-sm-3">
                              <Link to={goto(report)}>
                                <p className="leadno">{report.value}</p><p className="leadlabel">{this.props.t('Dashboard.'+report._key)}</p>
                              </Link>
                              {/* <a href="#">
                                <p className="leadno">{report.value}</p><p className="leadlabel">{report.label}</p>
                              </a> */}
                            </li>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-4">
              {dashboardleadcard && dashboardleadcard.map((dashboardCard, index) =>
                <div key={index} className="clearfix">
                  <div className="card background-efOne mrg-T20">
                    <div className="card-heading"><h2 className="card-title">
                      {
                        dashboardCard._key == "DASHBOARD_CARD_TOTAL_LEAD" ?
                          <div className="clearfix">
                            {/* {dashboardCard.title} */}
                            {this.props.t('Dashboard.'+dashboardCard._key)}
                            <div className="pull-right lead-bytime">
                              <Select
                                // value={formData.lead_report}//deleteme duplicate props
                                onChange={this.handleChange.bind(this, 'lead_report')}
                                options={countReportLeadDpd}
                                name="lead_report"
                                placeholder="Select Lead Report"
                                value={countReportLeadDpd.filter(({ value }) => value === formData.lead_report)}
                                getOptionLabel={({ label }) => this.props.t('Dashboard.'+label)}
                                getOptionValue={({ value }) => value}
                                className={"dealer_dashboard_select"}
                                classNamePrefix={"dealer_dashboard_select"}
                              />
                            </div>

                          </div>
                          : <div>{this.props.t('Dashboard.'+dashboardCard._key)}</div>
                      }

                    </h2>
                    </div>
                    <div className="card-body">
                      <ul className="lead-list 44 clearfix">
                        <Fragment>
                          {
                            dashboardCard.card_details.child.length ?
                              dashboardCard.card_details.child.map((reportListLead, indexlead) =>

                                <li className="col-sm-6" key={indexlead}>
                                  <Link to={goto(reportListLead, `dashboard_tab=${formData.lead_report}`)}>
                                    <p className="leadno">{reportListLead.value}</p>
                                    <p className="leadlabel">{this.props.t('Dashboard.'+reportListLead._key)}</p>
                                  </Link>
                                </li>
                              ) : ''
                          }
                        </Fragment>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.name,
    user_loggedin: state.userLoggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
