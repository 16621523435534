import React, { Component } from 'react';
import Select from 'react-select';
import Carousel from './../../inventory/component/LeadCarousal';
import MmvDropDown from './MmvDropDown';
import MasterService from './../../../service/MasterService';
import { connect } from 'react-redux';
import CrousalListing from './crousalListing';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG, CONFIG_FILE_CHANGE } from './../../../config/constant'


class EditRequirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fuel_type_id: [],
            uc_transmission_id: [],
            budgetList: [],
            preference: {},
            tab_click: '',
            lead_id: '',
            recomandedCar: [],
            favrouteCar: [],
            sliderContent: [],
            loaderClass: ''
        };
    }

    componentDidMount = async () => {
        await this.getFilterList();
        this.loadBudgetList();
        let preference = {...this.state.preference}
        preference.car_make     = (this.props.initialData.car_make) ? this.props.initialData.car_make : '';
        preference.car_model    = (this.props.initialData.car_model) ? this.props.initialData.car_model : '';
        preference.budget       = (this.props.initialData.price_from) ? this.props.initialData.price_from : '';
        preference.dp_budget    = (this.props.initialData.dp_budget) ? this.props.initialData.dp_budget : '';
        preference.fuel_type    = (this.props.initialData.fuel_type) ? this.props.initialData.fuel_type : '';
        preference.transmission = (this.props.initialData.transmission) ? this.props.initialData.transmission : '';
        preference.body_type    = (this.props.initialData.body_type) ? this.props.initialData.body_type : '';
        this.setState({ tab_click: this.props.showUpdateOption, lead_id: this.props.lead_id, preference:preference, data_index: this.props.data_index });
        this.getRecomandedCar();
        this.getFavrouteCar();
    }

    updateFavrouteCar = (car_index, car_id)=>{
        let tabClick = this.state.tab_click;
        let lead_id  = this.state.lead_id;
        let favrouteData = [...this.state.favrouteCar];
        let recomandedData = [...this.state.recomandedCar];
        let favrouteObj = {};
        let parentObj = this;
        favrouteObj.lead_id = lead_id;
        favrouteObj.is_favourite = (tabClick === 'edit') ? 1 : 0;
        favrouteObj.car_id = [car_id];
        MasterService.post('lead/lead/updateFavourite',favrouteObj)
            .then((response)=>{
                if (response.status == 200 && response.data.status == 200) {
                    if(tabClick === 'details'){
                        let tempData = favrouteData[car_index];
                        favrouteData.splice(car_index,1);
                        recomandedData.push(tempData);
                    }else{
                        let tempData = recomandedData[car_index];
                        recomandedData.splice(car_index,1);
                        favrouteData.push(tempData);
                        
                    }
                    parentObj.setState({favrouteCar: favrouteData, recomandedCar: recomandedData},() => {
                        parentObj.generateSliderHtml();
                    })
                    toast.success(response.data.message);
                }
            })
    }

    generateSliderHtml = ()=>{
        let selectedTab = this.state.tab_click;
        let parentObj = this;
        let sliderData  = (selectedTab === 'details') ? [...this.state.favrouteCar] : [...this.state.recomandedCar];
        let htmlData = [];
        if(sliderData.length > 0){
            sliderData.forEach(function (result, index) {
                let crousalDetails = {
                    content: <CrousalListing updateFavrouteCar={parentObj.updateFavrouteCar} crousalData={result} selectedTab={selectedTab} rowIndex={index} />,
                }
                htmlData.push(crousalDetails);
            });
        }
        parentObj.setState({ sliderContent: htmlData });
    }

    getRecomandedCar = () => {
        var parentObj = this;
        MasterService.post('lead/lead/getRecommendedCar', { lead_id: this.state.lead_id, dealer_id: this.props.dealer_id })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({recomandedCar: response.data.data}, ()=>{
                        parentObj.generateSliderHtml();
                    }); 
                }
            }).catch((error) => {

            })
    }

    getFavrouteCar = () => {
        var parentObj = this;
        MasterService.post('lead/lead/getFavoutiteCar', { lead_id: this.state.lead_id, dealer_id: this.props.dealer_id })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({favrouteCar: response.data.data},()=>{
                        parentObj.generateSliderHtml();
                    });
                }
            }).catch((error) => {

            })
    }
    loadBudgetList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllBudget', null)
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        budgetList: response.data.data
                    })
                }
            }).catch(function (error) {
            });
    }
    getFilterList = async () => {
        var parentObj = this;
        await MasterService.get('inventory/inventory/getfilter', null).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let responseData = response.data.data;
                for (let index in responseData) {
                    let keyValue = responseData[index]['key'];
                    if (keyValue === 'fuel_type_id' || keyValue === 'uc_transmission_id' || keyValue === 'body_type') {
                        parentObj.setState({
                            [keyValue]: responseData[index]['list']
                        });
                    }
                }
            }
        });
    }

    handleChange = (sname, fieldData) => {
        if(sname === 'car_make'){
            sname = 'make';
        }
        if(sname === 'car_model'){
            sname = 'model';
        }
        sname = (sname === 'car_model') ? 'model' : sname;
        let preference = this.state.preference;
        let field_value;
        if (fieldData != null) {
            if (sname === 'make' || sname === 'model') {
                field_value = (Array.isArray(fieldData)) ? fieldData.map((v) => v.id).join(',') : fieldData.id;
            }
            else if (sname === 'body_type' || sname === 'transmission' || sname === 'fuel_type') {
                field_value = (Array.isArray(fieldData)) ? fieldData.map((v) => v.key).join(',') : fieldData.key;
            }
            else { field_value = fieldData.key }
        }
        if (field_value != null || field_value==undefined) {
            if (sname == 'make') {
                preference['car_make'] = field_value;
            } else if (sname == 'model') {
                preference['car_model'] = field_value;
            } else {
                preference[sname] = field_value;
            }
            this.setState({ preference });
        }
        if (typeof this.props.updatePrefData === 'function') {
            this.props.updatePrefData(sname, field_value);
        }
    }

    updateLeadPref = async (event) => {
        this.setState({ loaderClass: "loading" })
        if (typeof this.props.updateLeadPref === 'function') {
            await this.props.updateLeadPref(event);
            this.getRecomandedCar();
            this.setState({ loaderClass: "" });
        }
    }

    showTabSection = (event) => {
        let tabClicked = event.currentTarget.dataset.key;
        this.setState({ tab_click: tabClicked },()=>{
            this.generateSliderHtml();
        });
        
    }

    componentWillReceiveProps = (nextProps) => {
        let preference = {...this.state.preference}
        preference.car_make     = this.props.initialData.car_make;
        preference.car_model    = this.props.initialData.car_model;
        preference.budget       = this.props.initialData.price_from;
        preference.dp_budget    = this.props.initialData.dp_budget;
        preference.fuel_type    = this.props.initialData.fuel_type;
        preference.transmission = this.props.initialData.transmission;
        preference.body_type    = this.props.initialData.body_type;
        this.setState({ tab_click: nextProps.showUpdateOption, lead_id: nextProps.lead_id, preference: preference,data_index: this.props.data_index }, ()=>{
            this.getRecomandedCar();
            this.getFavrouteCar();
            this.generateSliderHtml();
        }); 
    }

    render() {
        const { fuel_type_id, uc_transmission_id, body_type,  budgetList, preference, sliderContent } = this.state;
        return (
            <div className="panel panel-gray mrg-b0">
                <div className="panel-heading pad-l30 mrg-b15">
                    <ul className="list-inline nav-tabs">
                        <li className={"nav-item " + (this.state.tab_click === 'details' ? "active" : " ")} data-key='details' onClick={this.showTabSection}><div className="text-bold">{this.props.t('preferences.favourites')}<span>({this.state.favrouteCar.length})</span></div></li>
                        <li className={"nav-item " + (this.state.tab_click === 'edit' ? "active" : " ")} data-key='edit' onClick={this.showTabSection}><div className="text-bold">{this.props.t('preferences.recommended')}<span>({this.state.recomandedCar.length})</span></div></li>
                    </ul>
                    <div className="closerow" onClick={() => { this.props.rowClosefull(this.props.initialData.data_index) }} ><span className="ic-clearclose"></span></div>
                </div>
                <div className="carrequirment">
                    <form>
                        {this.state.tab_click === 'edit' ?
                            <div className="clearfix mrg-b15">
                                <h5 className="col-sm-12">{this.props.t('preferences.requirements')}</h5>
                                <div className="col-sm-6 col-md-6 ">
                                    <MmvDropDown coloumnClass="col-sm-6 form-group" handleOptionChange={this.handleChange} defaultMake={this.state.preference.car_make} defaultModel={this.state.preference.car_model}  multiSelectEnable={BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG}/>
                                </div>
                                <div className={"col-sm-3 form-group " + (CONFIG_FILE_CHANGE.DP_BUDGET ? "col-md-1" : "col-md-2")}>
                                    <label>{this.props.t('listLead.Select_budget')}</label>
                                    <Select
                                        id="budget"
                                        // value={preference.budget}//deleteme duplicate props
                                        onChange={this.handleChange.bind(this, 'budget')}
                                        options={budgetList}
                                        name="budget"
                                        placeholder={this.props.t('listLead.Budget')}
                                        value={budgetList.filter(({ key }) => key === preference.budget)}
                                        getOptionLabel={({ value }) => value}
                                        getOptionValue={({ key }) => key}
                                    />
                                </div>
                                {CONFIG_FILE_CHANGE.DP_BUDGET ?
                                <div className="col-sm-3 col-md-2 form-group">
                                    <label>{this.props.t('listLead.select_dp_budget')}</label>
                                    <Select
                                        id="dp_budget"
                                        // value={preference.dp_budget}//deleteme duplicate props
                                        onChange={this.handleChange.bind(this, 'dp_budget')}
                                        options={budgetList}
                                        name="dp_budget"
                                        placeholder={this.props.t('listLead.select_dp_budget')}
                                        value={budgetList.filter(({ key }) => key === preference.dp_budget)}
                                        getOptionLabel={({ value }) => value}
                                        getOptionValue={({ key }) => key}
                                    />
                                </div>
                                : '' }
                                <div className={"col-sm-3 form-group " + (CONFIG_FILE_CHANGE.DP_BUDGET ? "col-md-1" : "col-md-2")}>
                                    <label>{this.props.t('preferences.select_fuel_type')}</label>
                                    <Select
                                        id="fuel_type"
                                        value={fuel_type_id.filter(({ key }) => {
                                            if (preference.fuel_type && Array.isArray(preference.fuel_type)) {
                                                return (preference.fuel_type.indexOf(key) > -1)
                                            }
                                            else {
                                                if (preference.fuel_type && typeof preference.fuel_type != 'number')
                                                    return preference.fuel_type.split(',').map(v => Number(v)).indexOf(key) > -1;
                                                else
                                                    return (key === preference.fuel_type)
                                            }
                                        })}
                                        onChange={this.handleChange.bind(this, 'fuel_type')}
                                        options={fuel_type_id}
                                        name="fuel_type"
                                        isMulti={BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG}
                                        placeholder={this.props.t('listLead.Fuel_Type')}
                                        //value={fuel_type_id.filter(({ key }) => key === preference.fuel_type)}
                                        getOptionLabel={({ value }) => value}
                                        getOptionValue={({ key }) => key}
                                    />
                                </div>
                                <div className="col-sm-4 col-md-3 form-group">
                                    <label>{this.props.t('preferences.select_transmission')}</label>
                                    <Select
                                        id="transmission_type"
                                       // value={preference.transmission}
                                        value={uc_transmission_id.filter(({ key }) => {
                                            if (preference.transmission && Array.isArray(preference.transmission)) {
                                                return (preference.transmission.indexOf(key) > -1)
                                            }
                                            else {
                                                if (preference.transmission && typeof preference.transmission != 'number')
                                                    return preference.transmission.split(',').map(v => Number(v)).indexOf(key) > -1;
                                                else
                                                    return (key === preference.transmission)
                                            }
                                        })}
                                        onChange={this.handleChange.bind(this, 'transmission')}
                                        options={uc_transmission_id}
                                        name="transmission_type"
                                        isMulti={BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG}
                                        placeholder={this.props.t('listLead.Transmission')}
                                       // value={uc_transmission_id.filter(({ key }) => key === preference.transmission)}
                                        getOptionLabel={({ value }) => value}
                                        getOptionValue={({ key }) => key}
                                    />
                                </div>
                                <div className="col-sm-4 col-md-3 form-group">
                                    <label>{this.props.t('preferences.select_body_type')}</label>
                                    <Select
                                        id="body_type"
                                       // value={preference.body_type}
                                        value={body_type.filter(({ key }) => {
                                            if (preference.body_type && Array.isArray(preference.body_type)) {
                                                return (preference.body_type.indexOf(key) > -1)
                                            }
                                            else {
                                                if (preference.body_type && typeof preference.body_type != 'number')
                                                    return preference.body_type.split(',').map(v => Number(v)).indexOf(key) > -1;
                                                else
                                                    return (key === preference.body_type)
                                            }
                                        })}
                                        onChange={this.handleChange.bind(this, 'body_type')}
                                        options={body_type}
                                        name="body_type"
                                        isMulti={BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG}
                                        placeholder={this.props.t('preferences.body_type')}
                                        //value={body_type.filter(({ key }) => key === preference.body_type)}
                                        getOptionLabel={({ value }) => value}
                                        getOptionValue={({ key }) => key}
                                    />
                                </div>
                                <div className="col-sm-3 mrg-t22 form-group">
                                    <button type="submit" className={"btn btn-primary " + this.state.loaderClass } onClick={this.updateLeadPref}>{this.props.t('preferences.update')}</button>
                                </div>
                            </div>
                            :
                            ''}
                        <div className="clearfix">
                            <div className="carmove">
                                {sliderContent.length > 0 ?
                                    <Carousel slides={sliderContent} > </Carousel>
                                    : <h3>{this.props.t('preferences.no_data_found')}</h3>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('lead')(connect(mapStateToProps, null)(EditRequirements));