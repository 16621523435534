import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateFormate from 'dateformat';
import { DealerService } from './../../../service';


class SubscriptionHistoryLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            validation_error_msg: "",
            subscriptionHistoryLog: [],
        };
    }
    componentDidMount = async () => {
        await this.getSubscriptionHistoryLog();
    }
    getSubscriptionHistoryLog = async () => {
        DealerService.getSubscriptionHistoryLog(this.props.dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ subscriptionHistoryLog: result.data.data }, () => { });
            }
            else {
                this.handelError(this, result.data);
            }
        }).catch(error => {

        });
    }
    handleChange = (sname, ovalue) => {
        this.setState({
            validation_error_msg: "",
            [sname]: ovalue.value
        });
    }
    render() {
        let historyLog = this.state.subscriptionHistoryLog;
        return (
            <div>
                <table className="table table-bordered table-hover table-striped buyerenguiry-table" style={{tableLayout: "fixed"}}>
                    <thead>
                        <tr>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.id')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.dealer_id')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.table_name')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.pk_field')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.pk_value')}</th> 
                            <th style={{width: "50%"}}>{this.props.t('dealerDetails.Subscription_History_Log.change_value')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.comment')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.created_date')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.created_by')}</th>
                            <th>{this.props.t('dealerDetails.Subscription_History_Log.created_by_type')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            historyLog.map((log,index) =>
                                <tr key={index}>
                                    <td>
                                        <span>{log.id ?log.id: '-'}</span>
                                    </td>
                                    <td>
                                        <span>{log.dealer_id ?log.dealer_id: '-'}</span>
                                    </td>
                                    <td>
                                        <span>{log.table_name ?log.table_name: '-'}</span>
                                    </td>
                                    <td>
                                        <span>{log.pk_field ?log.pk_field: '-'}</span>
                                    </td>
                                    <td>
                                        <span>{log.pk_value ?log.pk_value: '-'}</span>
                                    </td>
                                    <td>
                                        <span>
                                            {
                                                Object.keys(log.change_value).map((k) =>
                                                    k + " : " + log.change_value[k] + " , "
                                                )
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <span>{log.comment ?log.comment: '-'}</span>
                                    </td>
                                    <td>
                                        <span>{DateFormate(log.created_date, 'mmm-dd-yyyy h:MM:ss TT')}</span>
                                    </td>
                                    <td>
                                        <span>{log.created_by ?log.created_by: '-'}</span>
                                    </td> 
                                    <td>
                                        <span>{log.created_by_type ?log.created_by_type: '-'}</span>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionHistoryLog));
