import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import CheckBox from './../../elements/CheckBox';

class MakeClassified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carId: props.carId,
            is_bump_enable:props.is_bump_enable,
            classifiedVal:props.classifiedVal,
            featuredVal:props.featuredVal,
            maxlimit: false,
            currentIndex:props.currentIndex,
            siteid: props.siteid,
            siteName: props.siteName,
            errors: {}
        }
    }

    saveStockClassified = () => {  
            var thisObj = this;
            let site_id_data = this.state.siteid.join(',')
            var postObj = { featuted: this.state.featuredVal,classified: this.state.classifiedVal,bumpUp: this.state.is_bump_enable, stock_used_car_id: this.state.carId ,site_id: site_id_data };
            let updateJsonRow={};
            if(this.state.classifiedVal=='true'){
                updateJsonRow.isclassified=1;
                updateJsonRow.site_id=site_id_data;
                let errors = {};
                if (site_id_data=='') {
                    errors["site_id"] = this.props.t('addInventory.validation_error.site_id_is_required');
                    this.setState({ errors: errors })
                    return false;
                }
            }else{
                updateJsonRow.isclassified=0;
                updateJsonRow.ispremium=0;
                updateJsonRow.is_bump_enable = 0;
                updateJsonRow.bump_up_expiry_date = 0;
                updateJsonRow.site_id = site_id_data;
            }
    
            MasterService.post('inventory/inventory/makeCarPremium', postObj)
                .then(function (response) {
                   updateJsonRow.isclassified = (postObj.classified=="false"  && postObj.site_id=='') ?  '0' : '1';
                    thisObj.flag = false;
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        if (typeof thisObj.props.updateStockData === 'function') {
                            thisObj.props.updateStockData(updateJsonRow, thisObj.state.currentIndex);
                          }
                        thisObj.props.modalClose();
                        thisObj.props.getbumpUpStatus();
                    }else{
                        toast.error(response.data.message);
                        thisObj.props.modalClose();
                    }
                })
                .catch(function (response) {
                });
        
    }
    handleSiteIdchecked = (field) => event => {
        if (field == 'site_id') {
            let chkval = event.target.value;
            let site_id = this.state.siteid ;
            if (!site_id.includes(chkval)) {
                site_id.push(chkval);
            }
            else {
                let index_db = site_id.indexOf(chkval);
                site_id.splice(index_db, 1);
            }
            this.setState({
                siteid : site_id
            });
        }
    }
    render() {
        let maxclassifiedmsg = [];
        let selected_site_id = this.state.siteid;
        let errors = this.state.errors;
        if (this.state.classifiedVal == 'true') {
            maxclassifiedmsg.push(<p key='1'>{this.props.t('listInventory.mark_classified_msg')}</p>);
        } else {
            maxclassifiedmsg.push(<p key='2'>{this.props.t('listInventory.mark_unclassified_msg')}</p>);
            // if (this.state.classifiedVal == 'false' && this.state.is_bump_enable == 1 && this.state.featuredVal == 'true') {
            //     maxclassifiedmsg.push(<p key='3'>{this.props.t('listInventory.featured_and_bump_up_both_will_msg')}</p>);
            // }
            if (this.state.classifiedVal == 'false' && this.state.featuredVal == 'true') {
                maxclassifiedmsg.push(<p key='3'>{this.props.t('listInventory.featured_will_also_remove_msg')}</p>);
            }
            // else if (this.state.classifiedVal == 'false' && this.state.is_bump_enable == 1) {
            //     maxclassifiedmsg.push(<p key='5'>{this.props.t('listInventory.bump_up_will_also_remove_msg')}</p>);
            // } 
        }
      
        if(this.state.maxlimit==true){
            maxclassifiedmsg= <div className="alert alert-danger">
            <p>
                <strong className="text-primary">{this.props.t('listInventory.classified_exceed_msg_1')}</strong>
            </p>
            <p>{this.props.t('listInventory.classified_exceed_msg_2')}</p>
        </div>
        }
        let siteNameList = this.state.siteName;
        return (
            <div>
                {maxclassifiedmsg}
                {
                    <div className="row">
                        <div className="form-field col-sm-4">
                            {
                                (siteNameList.length > 0) ?
                                    <div className='rc-inline'>
                                        {siteNameList.map((site_id, index) =>
                                            <CheckBox type="checkbox" name="site_id[]"
                                                id={'site_id' + site_id.key} value={site_id.key} label={site_id.value}
                                                key={site_id.key} checked={(selected_site_id && selected_site_id.indexOf(site_id.key)) >= 0 ? 'checked' : ''} onChange={this.handleSiteIdchecked('site_id')}
                                            />
                                        )}
                                    </div>
                                    : ''
                            }
                        <span className="error show">{errors.site_id}</span>
                        </div>
                    </div>

                }
                <div className="col-sm-6 col-md-12 form-group text-right">
                <Button type="button" onClick={this.saveStockClassified} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
            </div> 
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeClassified);