import { CONF_VAL } from './../../../config/constant';

export const getRemainingTime = (endDateTime) => {
    let remainingTime = '';
    let countDownDate = new Date(endDateTime).getTime();
    let now = new Date().getTime();
    let timeleft = countDownDate - now;

    let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    if (days > 0) {
        remainingTime += days + 'd ';
    }
    if (hours > 0) {
        remainingTime += hours + 'h ';
    }
    if (minutes > 0) {
        remainingTime += minutes + 'm ';
    }
    // if(!days && !hours && !minutes && seconds>0){
    if (seconds >= 0) {
        remainingTime += seconds + 's'
    }
    return (remainingTime) ? remainingTime : '';
}

export const calculateUpdatedBidAmount = (cars) => {
    let bid_amount = 0;
    if (cars.update_bid_amount) {
        bid_amount = cars.update_bid_amount
    } else if (cars.bid_amount) {
        bid_amount = +cars.bid_amount + +cars.increment_amount;
    } else if (!+cars.bid_amount) {
        bid_amount = +cars.base_price + +cars.increment_amount;
    }

    return bid_amount;

}

export const calculateMinBidAmount = (cars) => {
    let calculateMinBidAmnt = 0

    if (cars.bid_amount) {
        calculateMinBidAmnt = +cars.bid_amount + +cars.increment_amount;
    } else {
        calculateMinBidAmnt = +cars.base_price + +cars.increment_amount;
    }
    return calculateMinBidAmnt;
}

export const calculateAuctionTime = (auctionDetails) => {
    let auctionCarDaysLeft = ''

    if (auctionDetails && auctionDetails.end_date_time && new Date(auctionDetails.end_date_time).getTime() > new Date().getTime()) {
        const today = new Date();
        const endDate = (new Date().getTime() > new Date(auctionDetails.start_date_time).getTime()) ? new Date(auctionDetails.end_date_time) : new Date(auctionDetails.start_date_time);
        const days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
        const hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
        const minutes = parseInt(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60) % 60);

        if (days >= 1) {
            auctionCarDaysLeft += days + 'd '
        }
        auctionCarDaysLeft += hours + 'h ' + minutes + 'm';

    } else {
        auctionCarDaysLeft = 'Expired'
    }

    return auctionCarDaysLeft;
}

export const addCurrencyFormatting = (number, countryCode = '') => {
    number = number.toString().replace(/,/g, '')
    return CONF_VAL.CURRENCY_SYMBOL + ' ' + (+number ? Number(number).toLocaleString("en") : 0);
}

export const removeCurrencyFormatting = (number) => {
    if (number) {
        var result = number.replace(/[Rp]+/g, "").replace(/[₱]+/g, "").replace(/[AED]+/g, "").replace(/,/g, "");
        return result.toString().trim()
    }

}