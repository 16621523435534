import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';
import Captcha from "../../common/Captcha";
class RemoveInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: 'email',
            passwordType: "password",
            reloadCaptcha: 1,
            fields: {},
            inputFieldCaptcha: { captcha: "" },
            errors: { captchaErr: 'Please enter valid captcha' },
            showOtp: false,
            otpTimer: 0,
            loading:props.loading
        }
    }
    componentDidMount = () => {
        let self = this
        this.myInterval = setInterval(() => {
            self.setState(({ otpTimer }) => ({
                otpTimer: otpTimer - 1
            }))
        }, 1000)
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ errors: nextProps.errorMessage });
        this.setState({ reloadCaptcha: nextProps.reloadCaptcha });
        this.setState({ showOtp: nextProps.showOtp });
        if (this.state.showOtp != nextProps.showOtp && nextProps.showOtp) {
            this.setState({ otpTimer: 60 })
        }
    }
    submitLoginForm = (event) => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }

    handleChange = event => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    captchaFiledHandler = (childData) => {
        let captcahId = childData.captchaData.data.value;
        let tempErrors = this.state.errors;
        let tempInputFieldCaptcha = this.state.inputFieldCaptcha;
        if (captcahId) {
            tempErrors['captchaErr'] = "";
            tempInputFieldCaptcha['captcha'] = childData.captchaVal;
        } else {
            tempErrors['captchaErr'] = "Please enter valid captcha.";
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(childData);
        }
        this.setState({ tempErrors });
    }

    handleResend = async (event) => {
        await this.props.sendOtpEmail(event)
        this.setState({ otpTimer: 60 })
    }

    render() {
        const {  errors } = this.state;
        return (
            <div className="item active">

                <div className="sm">

                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">

                            <div className="signUp-container text-left">
                               

                                <h2>Request data deletion</h2>

                                <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform" onSubmit={this.submitLoginForm}>
                                    <div className="row">
                                        {this.state.showOtp == false ?
                                            <div>
                                                <div className="col-sm-12">
                                                    <InputField
                                                        inputProps={{
                                                            id: "email",
                                                            type: "text",
                                                            name: "email",
                                                            label: this.props.t('joinus.email'),
                                                            autoFocus: true,
                                                            dataerror: errors.email
                                                        }}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="col-sm-12">
                                                    <Captcha onKeyPressCaptcha={this.captchaFiledHandler} errField={this.state.errors} inputField={this.state.inputFieldCaptcha} reloadCaptcha={this.state.reloadCaptcha} />
                                                </div>
                                            </div> :
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "otpValue",
                                                        type: "number",
                                                        name: "otpValue",
                                                        label: this.props.t('joinus.otp'),
                                                        autoFocus: true,
                                                        dataerror: errors.otpValue
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                                
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                        </div>
                                    </div>
                                    <span id="invalidLogin" className="invalid-login"></span>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.submitLoginForm}>{this.props.t('remove_info.submit')}</button>
                                    </div>
                                </form>
                                {this.state.loading ? <div className="loading"></div> : ""}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation('common')(RemoveInfoForm);