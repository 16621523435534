import React, { useEffect } from 'react'
import AuctionService from '../../../service/AuctionService';
import { toast } from 'react-toastify';

const WishlistIcon = ({auctionDetails, sendDataToParent}) => {

  const [carDetails, setCarDetails] = React.useState({});
  const [buyerId, setBuyerId] = React.useState(0)

  useEffect(()=>{
    setCarDetails(auctionDetails.carDetails)
  },[auctionDetails.carDetails])

  useEffect(()=>{
    setBuyerId(auctionDetails.buyer_id)
  },[auctionDetails.buyer_id])
  

  /**
   * ADD/REMOVE CAR WISHLIST
   * @param {*} auction_id 
   * @param {*} car_id 
   * @param {*} type 
   */
  const addRemoveWatchList = async (auction_id, car_id, type) => {

      let { auctionData } = carDetails;

      let watchlistParams = { buyer_id: buyerId, car_id, auction_id, type }

      sendDataToParent({loading: true});
      await AuctionService.addRemoveWatchList(watchlistParams).then(response => {
          sendDataToParent({loading: false, updateCount: true,type,car_id,auction_id});
          toast.success(response.data.message);
          if(response.data.status === 200 && auctionDetails.tabName !== 'watchlist'){
              // setCarDetails({ auctionCarDetails: {...auctionCarDetails, auctionData: {...auctionCarDetails.auctionData,  watchlist_status: (type === 'add' ? 1 : 0)} }})
              setCarDetails({  auctionData: {...auctionData,  watchlist_status: (type === 'add' ? 1 : 0)} })
          }
      })
  }

  let { auctionData } = carDetails || {};

  return (

      auctionData
      ?
          <div className="bookmark-icn" onClick={()=>addRemoveWatchList(auctionData.auction_id, auctionData.vid || auctionData.car_id, ((auctionData.watchlist_status) ? "remove" : "add") )}>
          <i className={(auctionData.watchlist_status) ? "ic-favorite" : "ic-favorite_outline"}></i>
        </div>
      :
        ''    
  );

};
export default WishlistIcon;