import React, { Component } from 'react';
import Select from 'react-select';
import MasterService from '../../../service/MasterService';
import { AUCTION_INSPECTION_STATUS } from '../../../config/constant';
import { CONF_VAL } from '../../../config/constant';
import _ from 'lodash'
import MultiSelect from '../Common/MultiSelect';

class AuctionInventoryFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            makeModelList: {},
            modelList: [],
            makeList: [],
            cityStateList: {},
            cityList: [],
            filterList: [],
            fuelTypeList: [],
            selectedMake: [],
            selectedModel: [],
            fuelList: [],
            kmRange: [],
            maxYearRange: [],
            filterData: {},

        }
    }

    componentDidMount = () => {

        this.getMake();
        this.getCitylist();
        this.getInventoryFilters();

        this.searchInventory();
    }


    getInventoryFilters = () => {
        MasterService.get('inventory/inventory/getfilter')
            .then((response) => {

                if (response && response.data && response.data.status && response.data.status === 200) {
                    if (response.data.data && response.data.data.length) {
                        let fuelList = response.data.data ? response.data.data.filter(filter => filter.key === 'fuel_type_id') : []
                        fuelList = fuelList[0] ? fuelList[0].list.map(fuel => { return { value: fuel.key, label: fuel.value } }) : []

                        let kmRange = response.data.data ? response.data.data.filter(filter => filter.key === 'max_km_driven') : []
                        kmRange = kmRange[0] ? kmRange[0].list.map(km => { return { value: km.key, label: km.value } }) : []

                        let maxYearRange = response.data.data ? response.data.data.filter(filter => filter.key === 'max_make_year') : []
                        maxYearRange = maxYearRange[0] ? maxYearRange[0].list.map(makeYear => { return { value: makeYear.key, label: makeYear.value } }) : []

                        this.setState({ filterList: response.data.data, fuelList, kmRange, maxYearRange })
                    }
                }
            })
    }


    getMake = () => {
        MasterService.get('core/commonservice/mmv_all')
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    if (response.data.data && response.data.data.make) {
                        let makeList = response.data.data.make ? response.data.data.make.map(make => { return { ...make, value: make.id, label: make.make } }) : []

                        this.setState({ makeModelList: response.data.data, makeList })
                    }
                }
            })
    }

    getCitylist = () => {

        MasterService.get('core/commonservice/state_city_all')
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    if (response.data.data && response.data.data.city) {
                        let cityList = response.data.data.city ? response.data.data.city.map(city => { return { ...city, value: city.id, label: city.name } }) : []

                        this.setState({ cityStateList: response.data.data, cityList })
                    }
                }
            })
    }

    handleChange = (key) => async e => {
        let { makeModelList, filterData } = this.state

        if (key === 'make') {
            let makeIds = e.map(make => make.id)
            let modelList = makeModelList.model.filter(model => makeIds.includes(model.mk_id))
            modelList = modelList.map(model => { return { ...model, value: model[CONF_VAL.MODEL_VALUE_KEY], label: model.m } })

            // this.setState({  })
            this.setState({ modelList, selectedMake: e, car_make: makeIds })
            filterData['car_make'] = makeIds;
        }

        if (key === 'model') {
            let modelIds = e.map(model => model[CONF_VAL.MODEL_ID_KEY]);
            modelIds = _.uniq(modelIds);

            this.setState({ selectedModel: e });
            filterData['car_model'] = modelIds;

        }

        if (key === 'certification_status_id') {
            filterData['certification_status_id'] = e.value;
        }

        if (key === 'city') {
            let selectedCity = e.map(city => city.value)
            this.setState({ selectedCity: e })

            filterData['city'] = selectedCity;

        }

        if (key === 'fuel_type') {
            let selectedFuel = e.map(fuel => fuel.value)
            this.setState({ selectedFuelType: e })
            filterData['fuel_type_id'] = selectedFuel;

        }

        if (key === 'km_driven') {
            let selectedKm = e.value
            this.setState({ selectedKm: e });
            filterData['max_km_driven'] = selectedKm;
        }

        if (key === 'make_year') {
            let selectedMake = e.value
            this.setState({ selectedMakeYear: e });
            filterData['max_make_year'] = selectedMake;
            filterData['min_make_year'] = selectedMake;

        }

        this.setState({ filterData })
    }

    searchInventory = () => {

        let { filterData } = this.state;

        let filterFields = {}
        for (let filter of Object.keys(filterData)) {
            if (['city', 'fuel_type_id', 'certification_status_id', 'max_km_driven', 'car_make', 'max_make_year', 'min_make_year', 'car_model'].includes(filter)) {
                filterFields[filter] = filterData[filter]
            }
        }

        this.props.searchInventory(filterFields);

        this.makeLabels()
    }

    resetFilter = () => {
        this.setState({ filterData: {}, selectedCity: undefined, max_km_driven: undefined, selectedMake: undefined, selectedModel: undefined, selectedMakeYear: undefined, selectedFuelType: undefined, selectedKm: undefined, modelList: [] }, () => {

            this.searchInventory();
        })
    }

    makeLabels = () => {
        const { selectedModel, selectedMake, selectedCity, selectedMakeYear, selectedFuelType, filterData } = this.state;
        let labels = []

        if (selectedMake && selectedMake.length) {
            for (let make of selectedMake) {
                labels.push(<li key={make.label}>
                    <span>{make.label}</span>
                    <i className="ic-clearclose" onClick={() => this.removeLabel('car_make', make.value)}></i>
                </li>)
            }
        }
        if (selectedModel && selectedModel.length) {
            for (let model of selectedModel) {
                labels.push(<li key={model.label}>
                    <span>{model.label}</span>
                    <i className="ic-clearclose" onClick={() => this.removeLabel('car_model', model.value)}></i>
                </li>)
            }

        }
        if (selectedCity && selectedCity.length) {
            for (let city of selectedCity) {
                labels.push(<li key={city.label}>
                    <span>{city.label}</span>
                    <i className="ic-clearclose" onClick={() => this.removeLabel('city', city.value)}></i>
                </li>)
            }

        }

        if (filterData.max_km_driven) {
            labels.push(<li key={'max_km_driven'}>
                <span>{filterData.max_km_driven}</span>
                <i className="ic-clearclose" onClick={() => this.removeLabel('max_km_driven')}></i>
            </li>)

        }

        if (selectedMakeYear) {
            labels.push(<li key="max_make_year">
                <span>{selectedMakeYear.label}</span>
                <i className="ic-clearclose" onClick={() => this.removeLabel('max_make_year')}></i>
            </li>)

        }
        if (selectedFuelType && selectedFuelType.length) {
            for (let fuel of selectedFuelType) {
                labels.push(<li key="fuel_type_id">
                    <span>{fuel.label}</span>
                    <i className="ic-clearclose" onClick={() => this.removeLabel('fuel_type_id', fuel.value)}></i>
                </li>)
            }

        }
        if (typeof filterData.certification_status_id !== 'undefined') {
            labels.push(<li key="certification_status_id">
                <span>{filterData.certification_status_id === '0' ? 'Not Inspected' : 'Inspected'}</span>
                <i className="ic-clearclose" onClick={() => this.removeLabel('certification_status_id')}></i>
            </li>)

        }

        this.setState({ labels })
    }

    removeLabel = async (key, value) => {

        let { selectedMake, selectedModel, selectedCity, selectedFuelType } = this.state;

        let updatedFilters = this.state;

        if (key === 'car_make') {
            selectedMake = selectedMake.filter(make => make.value !== value)
            if (selectedModel && selectedModel.length) {
                let removeModel = selectedModel.filter(model => model.mk_id !== value)
                updatedFilters['selectedModel'] = removeModel
                updatedFilters['filterData']['car_model'] = removeModel.map(model => model.value)
            } else {

                updatedFilters['modelList'] = [];
                updatedFilters['selectedModel'] = undefined;
                updatedFilters['filterData']['car_model'] = undefined;
            }


            if (selectedMake.length) {
                updatedFilters['selectedMake'] = selectedMake
                updatedFilters['filterData']['car_make'] = selectedMake.map(make => make.value)
            } else {
                updatedFilters['selectedMake'] = undefined;
                updatedFilters['filterData']['car_make'] = undefined;
            }
        }

        if (key === 'car_model') {
            selectedModel = selectedModel.filter(model => model.value !== value)
            if (selectedModel.length) {
                updatedFilters['selectedModel'] = selectedModel
                updatedFilters['filterData']['car_model'] = selectedModel.map(model => model.value)
            } else {
                updatedFilters['selectedModel'] = undefined;
                updatedFilters['filterData']['car_model'] = undefined;
            }
        }

        if (key === 'city') {
            selectedCity = selectedCity.filter(city => city.value !== value)
            if (selectedCity.length) {
                updatedFilters['selectedCity'] = selectedCity
                updatedFilters['filterData']['city'] = selectedCity.map(city => city.value)
            } else {
                updatedFilters['selectedCity'] = undefined;
                updatedFilters['filterData']['city'] = undefined;
            }
        }

        if (key === 'max_km_driven') {
            updatedFilters['selectedKm'] = undefined;
            updatedFilters['filterData']['max_km_driven'] = undefined;
        }

        if (key === 'max_make_year') {
            updatedFilters['selectedMakeYear'] = undefined;
            updatedFilters['filterData']['max_make_year'] = undefined;
            updatedFilters['filterData']['min_make_year'] = undefined;

        }


        if (key === 'fuel_type_id') {
            selectedFuelType = selectedFuelType.filter(fuel => fuel.value !== value)
            if (selectedFuelType.length) {
                updatedFilters['selectedFuelType'] = selectedFuelType
                updatedFilters['filterData']['fuel_type_id'] = selectedFuelType.map(fuel => fuel.value)
            } else {
                updatedFilters['selectedFuelType'] = undefined;
                updatedFilters['filterData']['fuel_type_id'] = undefined;
            }
        }
        if (key === 'certification_status_id') {
            delete updatedFilters['filterData']['certification_status_id'];
        }

        this.setState({ ...updatedFilters })
        this.searchInventory()

    }

    /**
     * MULTISELECT DYNAMIC PLACEHOLDER
     */

    getDropdownButtonLabel = (placeholderButtonLabel, value) => {
        if (value) {
            if (value.length === 0) {
                return placeholderButtonLabel;
            } else {
                return `${value.length} selected`;
            }
        }

    }
    render() {
        const { fuelList, kmRange, maxYearRange, labels, cityList, makeList, modelList, selectedModel, selectedMake, selectedCity, selectedMakeYear, selectedFuelType, filterData, selectedKm } = this.state;
        return (
            <div>
                <div className="auction-filter">
                    <div className="filter-outer">
                        <fieldset className="form-group single-select model-sec">
                            <div className={`material animation-effect ${selectedMake?.length ? 'active-label' : ''}`}>
                                <MultiSelect
                                    key={"dropdown"}
                                    options={makeList || []}
                                    value={selectedMake || []}
                                    onChange={this.handleChange('make')}
                                    isSelectAll={true}
                                    placeholder="Select Make"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                                <label data-label={"Select Make"} className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="form-group single-select model-sec">
                            <div className={`material animation-effect ${selectedModel?.length ? 'active-label' : ''}`}>

                                <MultiSelect
                                    key={"dropdown"}
                                    options={modelList || []}
                                    value={selectedModel || []}
                                    onChange={this.handleChange('model')}
                                    isSelectAll={true}
                                    placeholder="Select Model"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                                <label data-label={"Select Model"} className="form-label"></label>

                            </div>

                        </fieldset>
                        <fieldset className="form-group single-select city-sec">
                            <div className={`material animation-effect ${selectedCity?.length ? 'active-label' : ''}`}>

                                <MultiSelect
                                    key={"dropdown"}
                                    options={cityList || []}
                                    value={selectedCity || []}
                                    onChange={this.handleChange('city')}
                                    isSelectAll={true}
                                    placeholder="Select City"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                                <label data-label={"Select City"} className="form-label"></label>

                            </div>

                        </fieldset>
                        <fieldset className="form-group single-select kilometer-sec">
                            <div className={`material animation-effect ${selectedKm?.value ? 'active-label' : ''}`}>

                                <Select
                                    options={kmRange || []}
                                    value={selectedKm || null}
                                    placeholder="Mileage"
                                    onChange={this.handleChange('km_driven')}
                                    classNamePrefix="react_select"

                                />
                                <label data-label={"Mileage"} className="form-label"></label>

                            </div>
                        </fieldset>
                        <fieldset className="form-group single-select year-sec">
                            <div className={`material animation-effect ${selectedMakeYear?.value ? 'active-label' : ''}`}>

                                <Select
                                    options={maxYearRange}
                                    value={selectedMakeYear || []}
                                    placeholder="Make Year"
                                    onChange={this.handleChange('make_year')}
                                    classNamePrefix="react_select"

                                />
                                <label data-label={"Make Year"} className="form-label"></label>

                            </div>
                        </fieldset>
                        <fieldset className="form-group multiselect-dropDown fuel-sec">
                            <div className={`material animation-effect ${selectedFuelType?.length ? 'active-label' : ''}`}>

                                <MultiSelect
                                    key={"dropdown"}
                                    options={fuelList || []}
                                    value={selectedFuelType || []}
                                    onChange={this.handleChange('fuel_type')}
                                    isSelectAll={true}
                                    placeholder="Fuel Type"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                                <label data-label={"Fuel Type"} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className="form-group multiselect-dropDown year-sec">
                            <div className={`material animation-effect ${filterData?.certification_status_id ? 'active-label' : ''}`}>

                                <Select
                                    options={AUCTION_INSPECTION_STATUS}
                                    value={AUCTION_INSPECTION_STATUS.filter(status => status.value === filterData.certification_status_id) || []}
                                    placeholder="Inspection Status"
                                    onChange={this.handleChange('certification_status_id')}
                                    classNamePrefix="react_select"

                                />
                                <label data-label={"Inspection Status"} className="form-label"></label>

                            </div>
                        </fieldset>

                        {/* <fieldset className="toggle-switch">
                            <label>Inspected Cars</label>
                            <ToggleSwitchMain />
                            <div className="toggleApp">
                                <div className="d-flex">

                                    <div onClick={this.handleChange('inspectedCars')} className={filterData.inspectedCars ? 'ToggleSwitch active' : 'ToggleSwitch'}>
                                        <div className={filterData.inspectedCars ? 'knob active' : 'knob'} />

                                    </div>
                                </div>
                            </div>
                        </fieldset> */}
                    </div>
                    <div className="btn-submit-reset">
                        <button className="btn-primary" onClick={this.searchInventory} >Submit</button>
                        <button className="btn-reset" onClick={this.resetFilter} >Reset</button>
                    </div>
                </div>
                <div className="auction-filter-list">
                    <ul>
                        {
                            labels
                        }

                    </ul>
                </div>
            </div>

        )
    }
}

export default AuctionInventoryFilter;