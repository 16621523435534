import React, { useEffect, useState } from 'react';
import Pagination from "../Common/Pagination"
import * as HELPER from "./AuctionCommonData";
import { withRouter } from "react-router-dom";

function LiveAuctionTable(props) { 

    const [listData, setListData] = useState([]);
    const [endDateArray, setEndDateArray] = useState({});

    const getAuctionInventory = (auctionId) => {
        props.history.push("/auction-inventory/"+auctionId);

    }

    const datetimeFormat =  (endate) => {
        
        const datetime =  HELPER.getRemainingTime(endate)
        return datetime; 
    }

    useEffect(()=> {

        setListData(props.listData);

        let intervalManger
        
        if(!Object.keys(endDateArray).length){

            intervalManger = setInterval(()=> {
                    
                // if(props.listData && props.listData.length && !props.loading){

                //     props.sendRequestDataToParent({})
                // }
                let endDates = {}
                if(props.listData && props.listData.length && !props.loading){
                    for(let auction of props.listData){

                        if( new Date(auction.end_date_time).getTime() - new Date().getTime() > 0){
                            endDates = {  ...endDates, [auction.id]: {end_date_time: auction.end_date_time}  }
                        }
                        setEndDateArray(endDates)
                    
                    }
                    
                }
                if(props.listData && props.listData.length && !props.loading && Object.keys(endDates).length !== props.listData.length){
                    clearInterval(intervalManger)
                    props.sendRequestDataToParent({byPassRedis: true})
                } 
            },1000);
        }

        return () => {
            setEndDateArray({})
            clearInterval(intervalManger)
        } 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    /**
     * HANDLE PAGINATION DATA
     * @param {*} data 
     */
    const handleRequestData = async (data) => {

        props.sendRequestDataToParent(data)
    }

    const {  paginationData, page, runPaginationFunc, loading, totalLengthWithoutPagination } = props;
    return (
        <div className="auction-table-data">
            <table>
                <thead>
                    <tr>
                        <th>Auction Name</th>
                        <th>Location</th>
                        <th>Auction Type</th>
                        <th>Inventory</th>
                        <th>Ends In</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listData && listData.length && !loading
                        ?
                            listData.map((el, index)=>{
                                return (<tr onClick={()=>getAuctionInventory(el.hash_id)} key={index}>
                                        <td>{el.auction_name}</td> 
                                        <td>{el.location}</td>
                                        <td>{el.business_type_name || ''}</td>
                                        <td>{(el.inventory)?el.inventory+' cars': 0}</td>
                                        <td>
                                            <span className="time-left">
                                                <i className="ic-access_timequery_builderschedule"></i> { endDateArray[el.id] && endDateArray[el.id]['end_date_time'] ? datetimeFormat(endDateArray[el.id]['end_date_time']) : datetimeFormat(el['end_date_time']) }
                                            </span>
                                        </td>
                                    </tr>)
                            }) 
                        :
                            (!loading) ? <tr><td colSpan='5' style={{"textAlign":'center'}}>Not Record Found</td></tr> : <tr></tr>
                }
                </tbody>
            </table>
            <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': listData, totalLengthWithoutPagination, paginationData }} sendRequestDataToParent={handleRequestData} />
        </div>

    );

} 

export default  React.memo(withRouter(LiveAuctionTable));







