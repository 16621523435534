import React, { Component } from 'react';
import Noimage from '../../../webroot/images/noimage.jpg';
import CheckBox from '../../elements/CheckBox';
import ModalPopup from '../../elements/Popup';
import ShareDetails from './ShareDetails';
import AddBuyerLead from './Addbuyerlead';
import MakeFeatured from './MakeFeatured';
import AddToStock from './AddToStock';
import MakeClassified from './MakeClassified';
import MakeReset from './MakeReset';
import MakeBumpUp from './MakeBumpUp';
import ViewHistory from './ViewHistory';
import DeleteReason from './DeleteReason';
import Carousel from './ListingCarousal';
import RejectedCarousel from './RejectedListingCarousal';
import ButtonGroup from '../../elements/ButtonGroup';
import TagPhotos from './TagPhoto';
import ToolTip from './../../elements/Tooltip'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { NumericFormat as NumberFormat } from "react-number-format";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {BUMP_UP_LIMIT} from './../../../config/constant';
// var tempResetCarIdarr = [];//deleteme not in use
class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showgallerymodal: false,
            showAddLeadPopup: false,
            showFeaturedPopup: false,
            showClassifiedPopup: false,
            showResetPopup: false,
            showDeleteReasonPopup: false,
            showAddToStockPopup: false,
            showSharePopup: false,
            showBumpUpPopup:false,
            showViewHistory:false,
            removeReasonList: [],
            carouselSlidesData: [],
            imageTagListDpd: [],
            resetStockCarId: [],
            carouselSlidesRejectedImageData:[],
            carId: 0,
            dealerId : 0,
            currentIndex: 0,
            min_car_price: 1000,
            featuredVal: '',
            editPrice: {},
            updateCarPriceParams: {},            
            statusClassname: {
                'active': 'nav-item active',
                'removed': 'nav-item',
                'all': 'nav-item'
            },
            showViewPhoto: true,
            showRejectedPhoto: false,
            showTaggingPhoto: false,
            showBumpUpFlag: true,
            totalBumpUp: 0 ,
            bumpUpExpiryMin:null,
            stockCategory:this.props.stockCategory || 1,
            bump_up_flag_setting_from_master_api:0,
            stockList:this.props.stockData || [],
            selectAll:false,
            totalClassifiedLength:this.props.stockData.length
        }
    }

    static getDerivedStateFromProps(nextProps,prevState){
        const updatedState = {};
        if (prevState.stockList !== nextProps.stockData) {
            //updatedState["resetStockCarId"] = nextProps.stockData.map(v=>v.id);
            updatedState["totalClassifiedLength"] = (nextProps.stockData.map(v=>v.id)).length;
            updatedState["resetStockCarId"] = [];
            updatedState["stockList"] = nextProps.stockData;
        }
        return updatedState;
      }

    componentDidMount = () => {
        this.getStockRemoveReason();
        this.getbumpUpStatus();
        this.getImageTagList();
        this.getMasterData();
    }

    getMasterData = () => {
        MasterService.getMasters(['bump_up_flag','min_car_price']).then((response) => {
            if (response.status == 200 && response.data.status == 200 && response.data.data) {
                if(response.data.data.min_car_price) {
                    this.setState({
                        min_car_price: response.data.data.min_car_price,
                        bump_up_flag_setting_from_master_api: response.data.data.bump_up_flag
                    })
                }
            } else if (response.status == 401 && response.data.status == 401) {
                this.setState({ redirect: true })
            }
        }).catch((error) => {
        });

    }
    getbumpUpStatus = async () => {
        var thisObj = this;
        MasterService.get('inventory/inventory/bump_up_status')
            .then(function (response) {
                if (response.data.status == 200 && response.data.data.length) {
                    let total_bump_up = response.data.data.length;
                    let bump_up_expiry_min = response.data.data[0].min;
                    let show_bump_up = (total_bump_up < BUMP_UP_LIMIT)?true:false;   
                    thisObj.setState({ showBumpUpFlag: show_bump_up,totalBumpUp: total_bump_up,bumpUpExpiryMin:bump_up_expiry_min});
                }else{
                    thisObj.setState({ showBumpUpFlag: true});
                }
            })
            .catch(function (response) {
            });
    }
    getImageTagList=()=> {   
        var postObj = {};     
        MasterService.post('inventory/inventory/image_tagging_list',postObj)
            .then( (response) => {               
                if (response.data.status == 200) {                    
                    if (response.data.data.length) {
                        this.setState({ imageTagListDpd: response.data.data },()=>{});
                    }
                }
            })
            .catch(function (response) {
            });
    }
    getUsedCarImageList = async (stock_used_car_id) => {       
        var thisObj = this;
        var postObj = { stock_used_car_id: stock_used_car_id,image_status:'1' };
        MasterService.post('inventory/inventory/usedcarimagelist', postObj)
            .then(function (response) {  
                if (response.data.status == 200) {                    
                    let carImageListArr = [];
                    response.data.data.forEach(function (result) {
                        let imageUrl = result.url;
                        let tempCarImagedetail = {
                            content: <div><img src={imageUrl+'?resize=620x400'} alt="car-img" /></div>,
                            alt: 'Front',
                            src: imageUrl+'?resize=100x60',
                            comment:result.comment,
                            tag_id:result.tag_id,
                            id:result.id
                        }
                        carImageListArr.push(tempCarImagedetail);
                    });
                    thisObj.setState({
                        carouselSlidesData: carImageListArr
                    });
                }
            })
            .catch(function (response) {
            });
    }
    getUsedCarRejectedImageList = async (stock_used_car_id) => {       
        var thisObj = this;
        var postObj = { stock_used_car_id: stock_used_car_id,image_status:'0' };
        MasterService.post('inventory/inventory/usedcarimagelist', postObj)
            .then(function (response) {                  
                if (response.status == 200) {                    
                    let carImageListArr = [];
                    response.data.data.forEach(function (result) {
                        let imageUrl = result.url;
                        let reject_comment_obj=JSON.parse(result.reject_comment);
                        let reject_comment=[];
                        if(reject_comment_obj && reject_comment_obj.length){
                            reject_comment_obj.forEach(reject_comment_obj_data => {                            
                                    reject_comment.push(reject_comment_obj_data.issue_label);                                                                
                            });    
                        }
                        let tempCarImagedetail = {
                            content: <div><img src={imageUrl+'?resize=620x400'} alt="car-img" /></div>,
                            alt: 'Front',
                            src: imageUrl+'?resize=100x60',
                            rejected_reason:reject_comment,
                            comment:result.comment,
                            tag_id:result.tag_id
                        }
                        carImageListArr.push(tempCarImagedetail);
                    });                    
                    thisObj.setState({
                        carouselSlidesRejectedImageData: carImageListArr                        
                    },() => {
                        console.log(thisObj.state.carouselSlidesRejectedImageData);
                    });
                }
                console.log('response.data.data', response.data.data);
            })
            .catch(function (response) {
            });
    }
    showDevInfo = (event) => {
        if (event.target == event.currentTarget) this.setState({ showDevCount: (this.state.showDevCount || 0) + 1 });
    }
    showModal = (type, carId, index) => {
        this.setState({ [type]: true, carId: carId, currentIndex: index });
    }
    pushToEs = (carOne, index) => {
        this.setState({loading: 'loading'});
        MasterService.post('inventory/cron/es_push_invnetory', { "dccarid": carOne.id })
            .then((response) => {
                this.setState({loading: ''});
                console.log("/es_push_invnetory ---> stock_id:", carOne.id, " || response.data.data", response.data.data);
            })
            .catch((error) => {
                this.setState({loading: ''});
                console.error("/es_push_invnetory ---> stock_id:", carOne.id, " || error", error);
            });
    }
    showFeatureModal = (type, carId, featuredVal, index, isclassified) => {
        let updateFeature = '' , updateClassified = '';
         if (featuredVal == 1) {
            updateFeature = 'false';
        }
        else if (featuredVal == 0) {
            updateFeature = 'true';
        }
        if (isclassified == 1) {
            updateClassified = 'true';
        }
        else if (isclassified == 0) {
            updateClassified = 'false';
        }
        this.setState({ [type]: true, carId: carId, featuredVal: updateFeature,currentIndex: index,classifiedVal: updateClassified });
    }
    showClassifiedModal = (type, carId, e, index,ispremium,is_bump_enable) => {
        alert(`${ispremium} ${is_bump_enable}`);
        let updateClassified = '';
        if (ispremium == 1)
            ispremium = 'true';
        else
            ispremium = 'false';
        if (e.target.checked == true) {
            updateClassified = 'true';
        } else {
            updateClassified = 'false';
        }
        this.setState({ [type]: true, carId: carId, classifiedVal: updateClassified, featuredVal: ispremium,is_bump_enable: is_bump_enable, currentIndex: index });
    }
    ShowSubmitResetModel = (type) => {
        this.setState({ [type]: true});
    }
    
    showResetModal = (carId, e, index) => {
        let tempResetCarIdarr = this.state.resetStockCarId;
        if (e.target.checked == true) {
            tempResetCarIdarr.push(carId);
        } 
        else {
            tempResetCarIdarr = tempResetCarIdarr.filter(function (item) {
                return item !== carId
            })
        }
         this.setState({resetStockCarId: tempResetCarIdarr });
    }
    selectAllInventory = (e) => {
        if (e.target.checked == true) {
            this.setState({resetStockCarId: this.props.stockData.map(v=>v.id) ,selectAll:true});
        } else{
            this.setState({resetStockCarId: [] ,selectAll:false});
        }
    }

    showBumpUpFieldModal = (type, carOne, index, is_bump_enable) => {
        let isbumup = '';
        if (is_bump_enable == '0' ){
            isbumup = 'true';}
        else{
            isbumup = 'false';
        }
        this.setState({ [type]: true, carId: carOne.id, carOne: { ...carOne }, is_bump_enable: isbumup, currentIndex: index });
    }

    
    showgallaryModal = async (carId) => {
        this.changeTabs(1);
        await this.getUsedCarImageList(carId);
        await this.getUsedCarRejectedImageList(carId);
        this.setState({ showgallerymodal: true });
    }
    closeModal = () => {
        this.setState({ showgallerymodal: false, showAddLeadPopup: false, showFeaturedPopup: false, showClassifiedPopup: false, showResetPopup: false, showDeleteReasonPopup: false, showAddToStockPopup: false, showBumpUpPopup: false, showViewHistory: false, showSharePopup: false });
    }

    showCarPrice = (id) => {

        let editPriceArr = { ...this.state.editPrice };
        // eslint-disable-next-line array-callback-return
        Object.keys(editPriceArr).map(function (key, index) {
            editPriceArr[key] = 'hide';
        });
        editPriceArr['car_' + id] = 'show';

        this.setState({
            editPrice: {
                ...editPriceArr
            }
        }, () => {

        });
    }
    hideCarPrice = (id) => {        
        let editPriceArr = { ...this.state.editPrice };        
        this.setState({
            editPrice: {
                editPriceArr,
                ['car_' + id]: 'hide'
            }
        }, () => {

        });
    }
    changeCarPrice = (id, e) => {
        this.setState({
            updateCarPriceParams: {
                stock_used_car_id: id,
                car_price: e.target.value.replace(/\./g,'')
            }
        });
    }
    getStockRemoveReason() {        
        var thisObj = this;
        MasterService.get( 'core/commonservice/stock_remove_reason')
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        thisObj.setState({ removeReasonList: response.data.data });
                    }
                }
            })
            .catch(function (response) {
            });
    }

    saveUsedcarPrice = (id, index) => {
        console.log(this.state.updateCarPriceParams);
        if (this.state.updateCarPriceParams.stock_used_car_id == id && this.state.updateCarPriceParams.car_price >= this.state.min_car_price) {
            var postObj = { car_price: this.state.updateCarPriceParams.car_price, stock_used_car_id: this.state.updateCarPriceParams.stock_used_car_id };
            MasterService.post('inventory/inventory/updateusedstockcar', postObj)
                .then((response) => {
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        this.updateListData({ car_price: this.state.updateCarPriceParams.car_price }, index);
                        this.hideCarPrice(id);
                    }
                    else{
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                });
        } else {
            toast.error(this.props.t('listInventory.validation_car_price_min',{min_car_price: this.state.min_car_price}));
        }

    }

    statusClickHandler = (type, statusVal) => {
        this.props.handleStatusChange(statusVal);
        this.setState({
            statusClassname: {
                'active': 'nav-item',
                'removed': 'nav-item',
                'all': 'nav-item',
                [type]: 'nav-item active'
            }
        })
    }    

    handleRemovedCar = () =>{
        this.props.handleRemovedCar(this.state.currentIndex);
    }

    updateListData = (updatedObj, index) => {        
        this.props.updateListData(updatedObj, index);
    }

    __changeToRupiah(price){
        var rev     = parseInt(price, 10).toString().split("").reverse().join("");
        var rev2    = "";
        for(var i = 0; i < rev.length; i++){
            rev2  += rev[i];
            if((i + 1) % 3 === 0 && i !== (rev.length - 1)){
                rev2 += ".";
            }
        }
        return "Rp. " + rev2.split("").reverse().join("");
    }

    getExpiryTime(expiry_date) {
        let currentDate = new Date();
        let expirydate = new Date(expiry_date);
        var diff = (expirydate.getTime() - currentDate.getTime()) / 1000;
        diff /= 60;
        let min = Math.abs(Math.round(diff));
        return (min+1)
    }

    changeTabs = (tab_id) =>{
        this.setState({
            showViewPhoto :false,
            showRejectedPhoto:false,
            showTaggingPhoto: false
        })
        if(tab_id==1){
            this.setState({
                showViewPhoto :true
            })
            
        }else if(tab_id==2){
            this.setState({
                showRejectedPhoto :true
            })
        }else if(tab_id==3){
            this.setState({
                showTaggingPhoto :true
            })
        }
    }
        dateTimeFormate(bumpupDate) {
            let date = new Date(bumpupDate);
            let formateTime = ""; //date.getHours() >= 12 ? " PM" : " AM";
            let month = ("00" + (date.getMonth() + 1)).slice(-2) + "/"; 
            let day =  ("00" + date.getDate()).slice(-2) + "/";
            let year =  date.getFullYear()+" ";
            let hours = ("00" + date.getHours()).slice(-2)+ ":";
            let min = ("00" + date.getMinutes()).slice(-2) ;
            let dateStr = month + day + year + hours + min;
            return dateStr +formateTime;
        }

    render() {    
        let viewPhotosLength=this.state.carouselSlidesData.length;
        let rejectedPhotosLength=this.state.carouselSlidesRejectedImageData.length;        
        let btngroup=[];
        if(viewPhotosLength>0){
            btngroup=[{
                label: 'View Photos',
                type: 'button',
                class: 'btn btn-default btn-sm',
                selectedClass: 'btn btn-primary btn-sm',
                key: "1",
                id: "1"
              }];
        }
        if(rejectedPhotosLength>0){
            let tempBtn=  {
                label: 'Rejected Photos',
                type: 'button',
                class: 'btn btn-default btn-sm',
                selectedClass: 'btn btn-primary btn-sm',
                key: "2",
                id: "2"
              }
              btngroup.push(tempBtn);
        }
        if(viewPhotosLength>0){
            let tempBtn={
                label: 'Tag photos',
                type: 'button',
                class: 'btn btn-default btn-sm',
                selectedClass: 'btn btn-primary btn-sm',
                key: "3",
                id: "3"
              };
              btngroup.push(tempBtn);
        }
    //   let expiryTime =  this.state.bumpUpExpiryMin + " Mins";//deleteme var not in use
    //   let expirymsg = this.props.t('listInventory.bump_up_expiry_msg');//deleteme var not in use
      let BUMP_UP_ENABLE= this.state.bump_up_flag_setting_from_master_api;
      let stockCategory = this.state.stockCategory;
      let edit_url = 'add-stock';
      let resetStockArray = this.state.resetStockCarId;
      let total_classified_length = this.state.totalClassifiedLength;
      let number_of_reset_inventory = resetStockArray.length;
      if(stockCategory==3) edit_url = 'add-truck';
      console.log(edit_url);
        return (
            
            <div className={`card ${this.state.loading}`}>
                <div>
                    <ul className="list-inline nav-tabs" onClick={this.showDevInfo}>
                        {/* <li className={this.state.statusClassname.active} onClick={() => { this.statusClickHandler('active', [1]) }}>
                            <div>
                                {this.props.t('listInventory.active_status')}<span>({this.props.statusCount.active})</span>
                            </div>
                        </li>
                        <li className={this.state.statusClassname.removed} onClick={() => { this.statusClickHandler('removed', [2]) }}>
                            <div>
                                {this.props.t('listInventory.removed_status')}<span>({this.props.statusCount.removed})</span>
                            </div>
                        </li> */}
                        <li className={this.state.statusClassname.all} onClick={() => { this.statusClickHandler('all', [1]) }}>
                            <div>
                                {this.props.t('listInventory.all_status')}<span>({this.props.stockData.length})</span>
                            </div>
                        </li>
                    </ul>
                   
                </div>
                <div className="wrap row list-inline nav-tabs">
                    <div className="col-sm-8"></div>
                    <div className="col-sm-2">Classified Stock ({number_of_reset_inventory}/{total_classified_length})</div>
                    <div className="col-sm-2">
                        <CheckBox
                            id="select_all"
                            type="checkbox"
                            name="select_all"
                            value="1"
                            label={this.props.t('listInventory.select_all')}
                            checked={this.state.selectAll ? true : false}
                            onChange={(e) => this.selectAllInventory(e)}
                        />
                    </div>
                </div>

                <div className="card-body">
                    <ul className="car-list">
                        {
                            this.props.stockData.length == 0 && this.props.firstLoad ? 
                                <li><h6 className="text-center text-danger text-bold">{this.props.t('listInventory.loading')}</h6></li> 
                                : this.props.stockData.length == 0 ? 
                                    <li><h6 className="text-center text-danger text-bold">{this.props.t('listInventory.no_record_found')}</h6></li> 
                                : ''
                        }
                        {this.props.stockData.map((car, index) =>
                            <li key={car.id}>
                                <div className="wrap row">
                                    <div className="col-sm-2">
                                        <div className="img-box">

                                            {car.car_profile_image ? (
                                                <a href={() => false} onClick={() => { this.showgallaryModal(car.id) }}>
                                                    <div>
                                                        <div className="img_thumb"><img src={car.car_profile_image+'?resize=215x160'} alt=''/></div>
                                                        <span className="link">{this.props.t('listInventory.view_photos')}</span>
                                                    </div>
                                                </a>
                                            ) : (
                                                        <div className="img_thumb"><img src={Noimage} alt=''></img></div>
                                                )}

                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-7">
                                                <h2 className="carname">
                                                    <React.Fragment>{car.make} {car.modelVersion}</React.Fragment>                                                
                                                &nbsp;{car.qc_status_txt ?
                                                        <>
                                                        <span style={{ padding: "0.3em 0.7em 0.3em 0.4em" }} className="label red-status"> {car.qc_status_txt}</span>
                                                        &nbsp;<ToolTip id="ask" title={car.qc_status_comment && car.qc_status_comment.join(',')} ><i className="ic-error text-light"></i></ToolTip></>
                                                        : ''}
                                               &nbsp;{(car.ispremium == 1) && <span style={{padding: "0.3em 0.7em 0.3em 0.4em"}} className="label green-status"><i className="ic-cd-featured"></i> Featured</span> }
                                               &nbsp; {car.certification_status != "0" && <span style={{padding: "0.3em 0.7em 0.3em 0.4em"}} className="label orange-status"><i className="ic-cd-featured"></i> Inspected</span>}
                                                {this.state.showDevCount>=3 && "   (carId:"+car.id+")"}
                                                {this.state.showDevCount>=3 &&  <Link to="#" onClick={() => this.showModal('showViewHistory',car.id, index)}> &nbsp;&nbsp;{this.props.t('viewHistory.view_history')}</Link>}
                                                {this.state.showDevCount>=3 &&  <Link to="#" onClick={() => this.pushToEs(car, index)}> &nbsp;&nbsp;{this.props.t('ES_Push')}</Link>}
                                                </h2>
                                                <ul className="list-inline stock-listed">
                                                    <li>{car.reg_car_city_name}</li>
                                                    <li>{this.props.t('listInventory.listed_on')}: {car.listed_on}</li>
                                                    { BUMP_UP_ENABLE=='1' && car.bump_up == '1' && car.bump_up_date ? <li>{this.props.t('listInventory.bumpup_date')}: {this.dateTimeFormate(car.bump_up_date)}</li>: '' }
                                                </ul>

                                                <ul className="row car-specs">
                                                    <li className="col-xs-6 col-sm-6  col-md-4 col-lg-3 small"><i className="ic-cd-kilometer icons-normal"></i>{(car.km_driven)} {this.props.t('listInventory.km')}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small">
                                                        <i className="ic-cd-year icons-normal"></i>{car.display_reg_date}
                                                    </li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-owner icons-normal"></i>{car.display_owner_type}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-fuel icons-normal"></i>{(car.fuel_type)?car.fuel_type:'N/A'}</li>
                                                    {car.reg_no &&
                                                        <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-rc-file icons-normal"></i>{car.reg_no}</li>
                                                    }

                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-Transmission icons-normal"></i>{car.transmission}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-colors icons-normal"></i>{car.uc_colour}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-engine icons-normal"></i>{(car.engine_capacity)?car.engine_capacity:'N/A'}</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-2 col-md-2 text-center">
                                               
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                                <div className="mrg-b15">
                                                    <strong>{this.props.t('listInventory.retail_price')}
                                                    <span className=" text-primary mrg-l5" id={`show_retail_price_${car.id}`} > {this.__changeToRupiah(car.car_price)}</span></strong>
                                                    <div className={`editprice hide ${this.state.editPrice['car_' + car.id]}`}>
                                                        <div className="input-group form-group">
                                                            <NumberFormat thousandSeparator="." decimalSeparator="," maxLength="15"  name="edit_retail_price" onChange={(e) => { this.changeCarPrice(car.id, e) }}/>
                                                            <div className="input-group-append">
                                                                <button type="button" className="btn btn-primary" onClick={() => this.saveUsedcarPrice(car.id, index)}>{this.props.t('listInventory.save')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {car.car_status == '1' && stockCategory == 1 ?
                                                  <div className="inlinecheck">
                                                  <CheckBox
                                                      id="reset_stock"
                                                      type="checkbox"
                                                      name="reset_stock"
                                                      value="1"
                                                      checked={(resetStockArray.includes(car.id)) ? true : false}
                                                      onChange={(e) => this.showResetModal(car.id, e, index)}
                                                  />
                                              </div> : ''}
                                              

                                               {/* {
                                                car.is_bump_enable ?                                          
                                                <p className="mrg-t15 text-danger"><span>{this.props.t('listInventory.bump_up_expiry_msg')}:</span>{this.getExpiryTime(car.bump_up_expiry_date)} Mins</p>
                                                : ''
                                               } */}
                                            </div>
                                            <div className="col-sm-12 actionbtn">
                                                <div className="btn-toolbar clearfix" data-gs-csstooltip-wrapper="tooltip">
                                                    {car.car_status == '2'? 
                                                        <div>
                                                            <div aria-label="First group" role="group" className="btn-group mrg-r10">
                                                                <button type="button" className="btn btn-default" onClick={() => this.showModal('showAddToStockPopup',car.id, index)} title={this.props.t('listInventory.add_to_stock')}><i className="ic-add"></i></button>
                                                            </div>
                                                        </div>: ''
                                                    }                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                    <div className='wrap row fixedFooter'>
                        <div className='col-sm-8'>
                        </div>
                        <div className='col-sm-2'>
                        <Link to={`/manage-stock`} className='btn btn-primary mrg-r10'>{this.props.t('listInventory.cancel')}</Link>
                        </div>
                        <div className='col-sm-2'>
                            <button type="submit" onClick={(e) => this.ShowSubmitResetModel('showResetPopup')} className="btn btn-primary mrg-r10">{this.props.t('listInventory.reset')}</button>
                        </div>
                    </div>
                </div>
                {this.state.showAddLeadPopup == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('listInventory.add_buyer_lead')} modalClose={this.closeModal}>
                        <AddBuyerLead handleLeadAdd={(count) => this.props.handleLeadAdd(this.state.currentIndex, count)} modalClose={this.closeModal} carId={this.state.carId} />
                    </ModalPopup>
                }

                {this.state.showAddToStockPopup == false? '':
                    <ModalPopup id="add_to_stock" className="modal" title={this.props.t('listInventory.add_to_stock')} modalClose={this.closeModal}>
                        <AddToStock handleAddToStock={this.handleRemovedCar} modalClose={this.closeModal} carId={this.state.carId} stockType={this.props.stockType} stockCategory={this.state.stockCategory}/>
                    </ModalPopup>
                }                    

                {this.state.showFeaturedPopup == false ? '' :
                    <ModalPopup id="featured_popup" className="modal" title={this.props.t('listInventory.add_feature')} modalClose={this.closeModal}>
                        <MakeFeatured modalClose={this.closeModal} carId={this.state.carId} featuredVal={this.state.featuredVal} classifiedVal={this.state.classifiedVal}   updateStockData={this.updateListData} currentIndex={this.state.currentIndex} />
                    </ModalPopup>
                }
                {this.state.showClassifiedPopup == false ? '' :
                    <ModalPopup id="classified_popup" className="modal" title={this.props.t('listInventory.add_classified')} modalClose={this.closeModal}>
                        <MakeClassified modalClose={this.closeModal} carId={this.state.carId} classifiedVal={this.state.classifiedVal} featuredVal={this.state.featuredVal} is_bump_enable={this.state.is_bump_enable} updateStockData={this.updateListData} currentIndex={this.state.currentIndex} getbumpUpStatus={this.getbumpUpStatus}/>
                    </ModalPopup>
                }
                {this.state.showResetPopup == false ? '' :
                    <ModalPopup id="reset_popup" className="modal" title={this.props.t('listInventory.reset_title')} modalClose={this.closeModal}>
                        <MakeReset modalClose={this.closeModal} resetcarId={this.state.resetStockCarId} dealerId = {this.props.dealerId}/>
                    </ModalPopup>
                }
                {this.state.showDeleteReasonPopup == false ? '' :
                    <ModalPopup id="delete_reason_popup" className="modal" title={this.props.t('listInventory.select_remove_reason',{stock_type:this.props.stockType})} modalClose={this.closeModal}>
                        <DeleteReason handleRemovedCar={this.handleRemovedCar} modalClose={this.closeModal} removeReasonList={this.state.removeReasonList} carId={this.state.carId} stockType={this.props.stockType} placeholder ={this.props.t('listInventory.select_remove_reason',{stock_type:this.props.stockType})}/>
                    </ModalPopup>
                }
                {this.state.showgallerymodal == false ? '' : <ModalPopup id="gallary" className="modal modal-lg" title={this.props.t('listInventory.view_photos')} modalClose={this.closeModal}>
                    <ButtonGroup className="asd" changeTabs={this.changeTabs} btngroup={btngroup}/>
                    {this.state.showViewPhoto && 
                        <Carousel slides={this.state.carouselSlidesData} id="abc" ></Carousel>
                    }
                    {this.state.showRejectedPhoto && 
                        <RejectedCarousel slides={this.state.carouselSlidesRejectedImageData} id="abc" ></RejectedCarousel>
                    }
                    {this.state.showTaggingPhoto && 
                        <TagPhotos slides={this.state.carouselSlidesData} imageTagListDpd={this.state.imageTagListDpd}/>
                    }
                </ModalPopup>
                }
                {this.state.showSharePopup == false ? '' : <ModalPopup id="gallary" className="modal modal-lg" title={this.props.t('listInventory.send_car_detail')} modalClose={this.closeModal}>                    
                    <ShareDetails modalClose={this.closeModal} carId={this.state.carId} />                   
                </ModalPopup>
                }                
                {this.state.showViewHistory == false ? '' :
                    <ModalPopup id="" className="modal ViewHistory" title={this.props.t('viewHistory.view_stock_history')} modalClose={this.closeModal}>
                        <ViewHistory modalClose={this.closeModal} carId={this.state.carId}  />
                    </ModalPopup>
                }
                {this.state.showBumpUpPopup == false ? '' :
                    <ModalPopup id="" className="modal" title={this.props.t('listInventory.bump_up_modal_pop_title_msg')} modalClose={this.closeModal}>
                       <MakeBumpUp modalClose={this.closeModal}  carId={this.state.carId} carOne={this.state.carOne} is_bump_enable={this.state.is_bump_enable} currentIndex={this.state.currentIndex} updateStockData={this.updateListData} getbumpUpStatus={this.getbumpUpStatus}/>
                    </ModalPopup>
                }
            </div>
        )
    }
}
export default withTranslation('inventory')(Reset);
