import MasterService from './MasterService';

const AuctionService = {
    ...MasterService,

    getAuctionCarsList(params) {
        return this.post('/auction-dealer/auction/getAuctionCarsList', params)
    },

    getAuctionDetails(params) {
        return this.post('/auction-dealer/auction/getAuctionDetails', params)
    },

    getAuctionCarDetails(params) {
        return this.post('/auction-dealer/auction/getAuctionCarDetails', params)
    },
    
    getLiveAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerLiveAuctions', params)
    },
    getUpcommingAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerUpcommingAuctions', params)
    },
    getMybidAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerBidAuctions', params)
    },
    getWinAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerWinAuctions', params)
    },
    getPurchasedAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerPurchasedAuctions', params)
    },
    getWatchlistAuctionsByBuyerId(params){
        return this.post('/auction-dealer/auction/getBuyerWatchedListAuctions', params)
    },
    addRemoveWatchList(params){
        return this.post('/auction-dealer/auction/addRemoveWatchList', params)
    },
    saveBid(params){
        return this.post('/auction-dealer/auction/saveBuyerBid', params)
    },
    saveBidSocketUser(params){
        return this.post('/auction/api/saveBidSocketUser', params)
    }
    
}

export default AuctionService;
