import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { connect } from 'react-redux';
import secureStorage from '../../../config/encrypt';
import AuctionService from '../../../service/AuctionService';
import { toast } from 'react-toastify';

import Socket from '../Common/socket';
import * as HELPER from "./AuctionCommonData";
import FullPageLoader from '../Common/FullPageLoader';
import WishlistIcon from '../Common/WishlistIcon';
import NO_CAR_IMAGE from '../../../webroot/images/NoCar.jpg';
import DateFormat from 'dateformat';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import CurrencyInputField from './../../elements/CurrencyInputField';

function AuctioInventoryDetails(props) {

    // this.socketChild = React.createRef();
    const socketChild = React.useRef(null);

    const [pageData, setPageData] = React.useState({ loading: false, auctionCarId: props.match.params.auctionCarId });
    const [buyerName, setBuyerName] = React.useState({ name: '' });
    const [buyerId, setBuyerId] = React.useState({ buyer_id: '' });
    const [carDetails, setCarDetails] = React.useState({ auctionCarDetails: {} });
    const [showLoader, setShowLoader] = React.useState(false);
    const [autoBidPopup, setAutoBidPopup] = React.useState(false);

    const [isOpenLightBox, setIsOpenLightBox] = useState(false)
    // const [sliderIndex, setSliderIndex] = useState(0);
    const [sliderImages, setSliderImages] = useState([]);

    React.useEffect(() => {

        updateReduxData();
    // eslint-disable-next-line
    }, []);

    /**
     * GET AUCTION INVENTORY DETAIL
     */
    const getAuctionInventoryDetail = async (buyerId) => {

        setPageData({ ...pageData, loading: true })
        await AuctionService.getAuctionCarDetails({ buyer_id: buyerId, auction_car_id: pageData.auctionCarId }).then(resp => {
            setPageData(prevState => ({
                ...prevState,
                loading: false
            }));
            
            if(resp && resp.status === 200){
                setCarDetails({ auctionCarDetails: resp.data.data ? resp.data.data : {} })
            }
        })
    }

    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');

            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer = loginResponse.data.dealer_data[0];

            setBuyerName({ name: dealer.organization });
            setBuyerId({ buyer_id: dealer.dealer_id });

            getAuctionInventoryDetail(dealer.dealer_id);

        }
    }

    /**
     * UPDATE BID AMOUNT RECEIVED FROM SOCKET 
     * @param {*} updatedBidAmntData 
     */
    const updatedRealTimeBidAmount = async (updatedBidAmntData) => {
        let { auctionCarDetails } = carDetails;
        let { buyer_id } = buyerId;
        
        
        if(auctionCarDetails.auctionData && auctionCarDetails.auctionData.hash_id === updatedBidAmntData['auction_inventory_id_hash']){
            
            auctionCarDetails =  {...auctionCarDetails, auctionData: {...auctionCarDetails.auctionData, bid_amount: updatedBidAmntData['bid_amount'], winning_buyer: updatedBidAmntData['buyer_id']} };

        }
            //UPDATE COUNT & LAST BID AMOUNT FOR BID USER
        if (+buyer_id === +updatedBidAmntData['buyer_id']) {
            if(auctionCarDetails.auctionData && auctionCarDetails.auctionData.hash_id === updatedBidAmntData['auction_inventory_id_hash']){

                auctionCarDetails['auctionData']['total_used_bid_count'] = updatedBidAmntData['total_used_bid_count'] 
                auctionCarDetails['auctionData']['last_bid_amount'] = updatedBidAmntData['bid_amount']

                //DELETE LOCAL INCREMENTED AMOUNT
                delete auctionCarDetails['auctionData'].update_bid_amount;
            }
        }

        await setCarDetails({ auctionCarDetails })
    }

    /**
     * SAVE NEW BID
     */
    const addBid = async (buyer_id, auctionId, car_id, auctionInventoryHash, cars) => {

        //check auction status
        let carbidStatus = checkCarAuctionStatus(cars);
        if (carbidStatus) {
            toast.error('Bidding not allowed in this car')
            return false;
        }

        //CALCULATE BIDDING AMOUNT
        let bid_amount = HELPER.calculateUpdatedBidAmount(cars);
        if(typeof bid_amount==='string'){
            bid_amount=HELPER.removeCurrencyFormatting(bid_amount);
        }

        let origBidAmount = cars['bid_amount'] || cars['base_price'];

        //VALIDATION FOR BID AMOUNT
        if (origBidAmount && +bid_amount > +origBidAmount) {

            //CHECK FOR BIDs LEFT
            if (cars.bid_count - cars.total_used_bid_count > 0) {

                let bidParams = {
                    buyer_id: buyer_id,
                    auction_id_hash: auctionId,
                    car_id,
                    bid_amount,
                    auction_inventory_id_hash: auctionInventoryHash,
                    bid_left : (cars.total_used_bid_count)?cars.total_used_bid_count+1:0
                };

                setShowLoader(true);

                await AuctionService.saveBid(bidParams).then(async response => {

                    if (response.data.status === 200) {
                        setShowLoader(false);

                        toast.success(response.data.message);

                        // socketChild.current.dispatchBidAmountToSocket({auctionInventoryHash, bid_amount, buyer_id});

                    } else {
                        setShowLoader(false);
                        toast.error(response.data.message);

                    }
                });
            } else {
                toast.error('You don\'t have any bids left');
            }

        } else {
            toast.error('Bid amount should be greater than last bid amount');
        }

    }

    /**
     * BID INPUT INCREMENT HANDLER
     */
    const incrementVal = async (e, car_id, auction_id) => {

        let { auctionCarDetails } = carDetails;

        if ((+auctionCarDetails.auctionData.vid === car_id || +auctionCarDetails.auctionData.car_id === car_id) && +auctionCarDetails.auctionData.auction_id === auction_id) {
            
            auctionCarDetails['auctionData']['update_bid_amount'] = e
        }

        await setCarDetails({ auctionCarDetails })

    }


    /**
     * Check Car status for eligibility for bidding
     */
    const checkCarAuctionStatus = (car) => {
        let disablebuttons = true;

        if(car && car.start_date_time && new Date(car.start_date_time).getTime() <= new Date().getTime() && new Date(car.end_date_time).getTime() >= new Date().getTime()){
            disablebuttons = false
        }
        return disablebuttons;
    }

    /**
     * HANDLE DATA FROM WISHLIST COMPONENT
     * @param {*} params 
     */
    const wishlistUpdate = (params) =>{
        setShowLoader(params.loading);
    }

    /**
     * DISABLE MANUAL AMOUNT TYPING
     * @param {*} e 
     */
     const onKeyDownevent = (e) => {
        e.preventDefault();
    }

    /**
     * AUTO BID HANDLER
     */

    const showModalAuctionBid = (cars) => {

        //check auction status
        // let carbidStatus = checkCarAuctionStatus(cars);
        // if (carbidStatus) {
        //     return false;
        // } else {
            setAutoBidPopup( true );
            document.body.classList.add("overflow-hidden");

        // }

    }

    const hideModalAuctionBid = () => {
        setAutoBidPopup( false );
        document.body.classList.remove("overflow-hidden");
    }


    /**
     * UPDATE BID AMOUNT COMMING FROM SOCKET AFTER BID CANCEL
     * @param {*} activeBid 
     */
    const updatedRealTimeActiveBidAmount = async (updatedBidAmntData) => {
        let { auctionCarDetails } = carDetails;
        let { buyer_id } = buyerId;

        let auctionCarDetailData = {};

        for(let bid of updatedBidAmntData){

            auctionCarDetailData = auctionCarDetails.auctionData;

                if(auctionCarDetailData.hash_id === bid.auction_inventory_id_hash){ 
                    auctionCarDetailData['bid_amount'] = bid['bid_amount'];
                    auctionCarDetailData['winning_buyer'] = bid['buyer_id'];
                     
                    if (+buyer_id === +bid['cancel_buyer_id']) { 
                        auctionCarDetailData['total_used_bid_count'] = bid.total_used_bid_count;
                        auctionCarDetailData['last_bid_amount'] = bid['last_amount'];
                    }
                    if (+buyer_id === +bid['buyer_id']) {
                        //auctionCarDetailData['last_bid_amount'] = bid['bid_amount'];
    
                        //DELETE LOCAL INCREMENTED AMOUNT
                        delete auctionCarDetailData.update_bid_amount;

                    }else{
                        //auctionCarDetailData['last_bid_amount'] = 0;
                    }
                }
        }
        
        await setCarDetails({ auctionCarDetails: {...auctionCarDetails, auctionData: auctionCarDetailData}  })
    }

    let { auctionData, basicList } = carDetails.auctionCarDetails || {};
    let { loading } = pageData;
    let { buyer_id } = buyerId;

    let disableButtonsStatus = checkCarAuctionStatus(auctionData);
    
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "300px",
        variableWidth: true,
        slidesToShow: 1,
        speed: 500
    }; 
  
    //SET LIGHTBOX IMAGE & INDEX
    const set_image_index =(isOpen,Index)=>
    {   

        let images =  auctionData?.car_details && auctionData.car_details.car_image.length ? auctionData.car_details.car_image.map(img=> img) : [];

        images = [...images.slice(Index), ...images.slice(0,Index)];

        setSliderImages(images);
                
        setIsOpenLightBox(isOpen);
    }
    //IMAGES ARRAY

    return (
            <>
                {
                    auctionData && auctionData.auction_id_hash && !disableButtonsStatus
                    ?
                        <Socket componentData={{auctionId: auctionData.auction_id_hash , buyerName: ((buyerName && buyerName.name) || '') , buyer_id: ((buyerId && buyerId.buyer_id) || ''), inventoryCars: [auctionData]}} ref={socketChild} updatedRealTimeBidAmount={updatedRealTimeBidAmount} updatedRealTimeActiveBidAmount={updatedRealTimeActiveBidAmount}/>
                    :
                        ''
            }

            <FullPageLoader show={showLoader || loading} />

            <div>
                <div className="inventory-slider">
                
                    <Slider {...settings}>
                        {
                            auctionData && auctionData.car_details && auctionData.car_details.car_image && auctionData.car_details.car_image.length
                            ?
                                auctionData.car_details.car_image.map((img, key)=>
                                    <div key={key}  onClick={() => set_image_index(true,key)}>
                                        <img src={img} className="" style={{ width: '645px', height: '345px' }} alt="pic" />
                                    </div>
                                )
                                :
                                    <div>
                                        <img src={NO_CAR_IMAGE} className="" style={{ width: '645px', height: '300px' }} alt="pic" />
                                    </div>
                        }
                    </Slider>
                    {
                        auctionData
                        ? 
                            <WishlistIcon auctionDetails={{carDetails: carDetails.auctionCarDetails, buyer_id}} sendDataToParent={wishlistUpdate}/>
                         :
                            ''
                    }
                </div>
                <div className="container-min">
                    <div className="bid-detail">
                        <div className="heading-time-detail">
                            <div className="heading">
                                <h1>
                                    { auctionData ? (auctionData.make_name || '') + ' ' +  (auctionData.model_name || '') : ''}

                                    {
                                        auctionData
                                        ?
                                            <>
                                            <i className="ic-info-icon" title={auctionData ? auctionData.name : ''}></i>
                                            {
                                                (auctionData.isInspected && +auctionData.isInspected === 1)?
                                                <div className="inspected-tag inspected-detail"> Inspected </div>:null
                                            } 
                                            </>
                                        :
                                            ''
                                    }

                                </h1>
                                <ul className="car-spec">

                                    {
                                        auctionData && auctionData.car_details 
                                        ? 
                                            <>
                                                {
                                                    auctionData.car_details.reg_no 
                                                    ?
                                                        <li>{ auctionData.car_details.reg_no }</li>
                                                    :
                                                        '' 
                                                }
                                                {
                                                    auctionData.car_details.make_year 
                                                    ?
                                                        <li>{ auctionData.car_details.make_year }</li>
                                                    :
                                                        '' 
                                                }
                                                {
                                                    auctionData.car_details.fuel_type 
                                                    ?
                                                        <li>{ auctionData.car_details.fuel_type }</li>
                                                    :
                                                        '' 
                                                }
                                                {
                                                    auctionData.car_details.km_driven 
                                                    ?
                                                        <li>{ auctionData.car_details.km_driven + ' kms' }</li>
                                                    :
                                                        '' 
                                                }
                                            </>
                                            :
                                            ''
                                    }

                                </ul>
                            </div>
                            {
                                auctionData
                                ?
                                    <div className="timeleft">
                                        <i className="ic-access_timequery_builderschedule"></i>
                                        <span>{HELPER.calculateAuctionTime(auctionData)}</span>
                                    </div>
                                :
                                    ""
                            }
                            
                        </div>

                        {
                             /**
                              * IF PURCHASED SHOW AMOUNT & DATE
                              */
                        }

                        {
                            auctionData && auctionData.purchased_buyer 
                            ?
                                <div className='heading-time-detail'>
                                    <div className='heading'>
                                        <div className="auction-price">
                                             {HELPER.addCurrencyFormatting(auctionData.purchased_amount) || 0}
                                        </div>
                                        <div className="purchase-date">
                                            Won on {auctionData.purchased_date ? DateFormat(new Date(auctionData.purchased_date),"dd/mm/yyyy") : '-'}
                                        </div>
                                    </div>
                                </div>
                            :
                                ''
                        }
                        
                         {
                             /**
                              * BELOW DOWN SHOW FIELDS IF NOT PURCHASED
                              */
                         }

                        {
                            
                                auctionData && auctionData.bid_amount && !auctionData.purchased_buyer
                            ?

                                <>

                                    <div className="bid-sec">
                                        <div className="bid-status-amt">
                                            {
                                                auctionData
                                                ?
                                                    <span>Last Bid : { auctionData.last_bid_amount ? HELPER.addCurrencyFormatting(auctionData.last_bid_amount) : '0'}</span>
                                                :
                                                    ""
                                            }
                                        {
                                            (auctionData && auctionData.winning_buyer)
                                            ?
                                                <>
                                                    {
                                                        (buyerId && buyerId.buyer_id && +buyerId.buyer_id === +auctionData.winning_buyer)
                                                        ?
                                                            <div className="bid-status win-bg">
                                                                Winning
                                                            </div>
                                                        :
                                                            (auctionData.total_used_bid_count) 
                                                            ?
                                                                <div className="bid-status lose-bg">
                                                                    {'Losing'}
                                                                </div>
                                                            :
                                                                ''
                                                    }
                                                
                                                </>
                                            :
                                                ''
                                        }

                                        </div>
                                        <div className="bid-left">
                                            {
                                                auctionData
                                                ?
                                                    ((auctionData.bid_count - auctionData.total_used_bid_count)>0)
                                                    ?
                                                        (auctionData.bid_count - auctionData.total_used_bid_count) + " Bid Left"
                                                    :
                                                        "0 Bid Left"
                                                :
                                                    ""
                                            } 
                                        </div>
                                    </div>

                                    <div className="bid-sec">
                                        <div className="bid-status-amt">
                                            <span>Winning Bid: {auctionData && (HELPER.addCurrencyFormatting(auctionData.bid_amount) || HELPER.addCurrencyFormatting(auctionData.base_price))}</span>
                                        </div>
                                        
                                    </div>
                                </>
                                
                            :
                                ""
                        }
                        

                        <div className="numric-btn numeric-btn-sec-inventory">
                            
                            {
                                auctionData 
                                ?
                                    <>
                                        {
                                            !auctionData.purchased_buyer 
                                            ?
                                                <> 
                                                     <CurrencyInputField 
                                                        inputProps={{
                                                            id: "bid_amount",
                                                            type: "text",
                                                            name: "bid_amount",
                                                            placeholder: " ",
                                                            value:HELPER.calculateUpdatedBidAmount(auctionData),
                                                            disabled:checkCarAuctionStatus(auctionData),
                                                            min:(HELPER.calculateMinBidAmount(auctionData))?HELPER.calculateMinBidAmount(auctionData):0,
                                                            step:+auctionData.increment_amount
                                                          }}
                                                          mobile onChange={(value) => incrementVal(value, auctionData.car_id, auctionData.auction_id)} onKeyDown={onKeyDownevent}
                                                          onFocus={() => { }}
                                                          onBlur={() => { }}/>

                                                     <button type="" disabled={checkCarAuctionStatus(auctionData)} className="bid-btn" onClick={()=>addBid(buyerId.buyer_id, auctionData.auction_id_hash, (auctionData.vid || auctionData.car_id),  auctionData.hash_id, auctionData)}>
                                                        Bid
                                                    </button>
                                                    <i className={(checkCarAuctionStatus(auctionData)) ? 'bid-icn ic-bid-icn' : 'bid-icn ic-bid-icn'} onClick={() => showModalAuctionBid(auctionData)} ></i>
                                                </>
                                                
                                            :
                                                ''
                                        }
                                        

                                        {
                                            auctionData && auctionData.car_details && auctionData.car_details.certification_url
                                            ?
                                                <a href={auctionData.car_details.certification_url} target="_blank" className="bid-icn view-report ic-save_alt" title="View Report" rel="noopener noreferrer"></a>
                                            :
                                                ''
                                        }

                                    </>
                                :
                                    ''
                            }


                        </div>
                    </div>
                    
                    {
                        basicList && basicList.length
                        ?
                            basicList.map((list, key)=>
                                <div className="spec-detail-card" key={key}>
                                    <h2>{list.title}</h2>
                                    <ul className=" ">
                                    
                                        {
                                            list.detailsList.map((details, key)=> 
                                                <li key={key}>
                                                    <span>{details.textFieldName}</span>
                                                    <label>{details.textFieldValue}</label>
                                                </li>
                                            )
                                        }                                
                                    </ul>
                                </div>
                            )
                        :
                            ''
                    }
                    
                </div>
            </div>

            <div className="auction-bid-popup pop-main-outer">
                <Modal show={autoBidPopup} handleClose={hideModalAuctionBid} >
                    <div className="modal-header">
                        
                    </div>
                    <div className="modal-body" >
                        <h2 className="text-center">Coming Soon</h2>

                    </div>
                </Modal>
            </div>
       


            {
                isOpenLightBox && (
                    <Lightbox
                        open={isOpenLightBox}
                        close={() => setIsOpenLightBox(false)}
                        slides={
                            sliderImages.map(val=> ({src: val}))
                        }
                    />               
                )
            }

        </>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(AuctioInventoryDetails));