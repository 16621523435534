import React, { Component } from "react";
import DateFormate from 'dateformat';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class UserFieldHistoryPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: this.props.currentIndex,
            title: this.props.title,
            changeFieldHistoryData: this.props.changeFieldHistoryData,
            errors: {},
        };
    }

    componentDidMount = () => {
        this.loadAllComponentData();
    }

    loadAllComponentData = async () => {

    }

    render() {
        const { changeFieldHistoryData, title } = this.state;
        return (
            <div>
                {(changeFieldHistoryData && changeFieldHistoryData.length) > 0 ?
                    <div className="row table-responsive">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>{title}</th>
                                    <th>Updated By</th>
                                    <th>User role</th>
                                </tr>
                                {
                                    changeFieldHistoryData.map((history_data, i) =>
                                        <tr>
                                            <td>{DateFormate(new Date(history_data.created_date), 'dd mmm, yyyy')}</td>
                                            <td>{history_data.pk_value}</td>
                                            <td>{history_data.created_by_name}</td>
                                            <td>{history_data.created_by_type}</td>
                                        </tr>
                                    )
                                }


                            </tbody>
                        </table>
                    </div> : "No records Found"}
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('dealers')(connect(mapStateToProps)(UserFieldHistoryPopup));