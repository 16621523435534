import React, { Component } from 'react';
import Navigation from './Navigation';
import MasterService from './../../../service/MasterService';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';

// import { Redirect } from 'react-router-dom';
class HeaderAuction extends Component {
    constructor(props) {
        super(props);

        // this.toggle = this.toggle.bind(this);
        this.state = {

        };
    }

    logout = () => {
        this.logoutUser();
        secureStorage.removeItem('loginUserInfo');
        this.setState({ redirect: 1 }, () => {this.props.updateStateIntoRedux('LOGOUT_USER', '')} );
      }
    
    logoutUser() {
        MasterService.post('account/user/logout', {})
        .then((res) => {
        console.log("logout");
        window.location.reload();
        })
        .catch(function (err) {
        console.error("searchError", err)
        });
    }
    
    render() {

        return (
            <header className="auction-header">
                <Navigation logoutUser = {this.logout}/>
            </header>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
  }
  export default connect(null, mapDispatchToProps)(HeaderAuction);