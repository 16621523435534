import { CURRENCY_SEPARATOR } from '../config/constant';
const CommonService = {
  getThousandsGroupRegex(thousandsGroupStyle) {
    switch (thousandsGroupStyle) {
      case 'lakh':
        return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;

      case 'wan':
        return /(\d)(?=(\d{4})+(?!\d))/g;

      case 'thousand':
      default:
        return /(\d)(?=(\d{3})+(?!\d))/g;
    }
  },

  __numberWithCommaSeperator(str) {
    str = str.toString().split('.');
    let thousandsGroupRegex = this.getThousandsGroupRegex('thousand');
    let index = str[0].search(/[1-9]/);
    index = index === -1 ? str[0].length : index;
    return (
      str[0].substring(0, index) +
      str[0].substring(index, str[0].length).replace(thousandsGroupRegex, '$1.') +
      (str[1] !== undefined ? ',' + str[1].substring(0, 2) : '')
    );
  },
};

export default CommonService;
