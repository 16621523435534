import React, { Component } from 'react';
import InputField from './../../elements/Input';
import Button from './../../elements/Button';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import secureStorage from './../../../config/encrypt';
import { DealerService } from '../../../service';
import { MOBILE_VALIDATION, COUNTRY_CODE } from './../../../config/constant';

let formIsValid = true;
class AddAccountManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: (props.basic_details) ? false : false,
            basic_details:  {},
            errors: {},
            redirect:false
        }
    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    afterLoginSetState = (loginResponse) => {
        // let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;//deleteme
        let role = loginResponse?.data?.user_data?.role || null;
        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        let ac_manager_id = this.props.match.params.ac_manager_id;
        if (ac_manager_id) {
            this.getAccountManagerData(ac_manager_id);
        }
    }

    handleInputChange = (datefield, event) => {
        let basic_details = this.state.basic_details;
        const errors = this.state.errors;
        event.target.name = datefield;
        basic_details[event.target.name] = event.target.value;
        if (event.target.name == 'mobile') {
            if (!MOBILE_VALIDATION.test(event.target.value)) {
                formIsValid = false;
                errors[event.target.name] = this.props.t('Listing.AddAccountManager.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
            } else {
                formIsValid = true;
                delete errors[event.target.name];
            }
        }
        this.setState({ basic_details, errors }, () => {
        });
    }

    validForm = () => {
        let fields = this.state.basic_details;
        let errors = { ...this.state.errors };
        if (!fields["name"]) {
            errors['name'] = this.props.t('Listing.AddAccountManager.validation_texts.name');
            formIsValid = false;
        }
        if (!fields["mobile"]) {
            errors['mobile'] = this.props.t('Listing.AddAccountManager.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
            formIsValid = false;
        } 
        this.setState({ errors: errors });
        return formIsValid;
    }

    // saveAccountManagerForm = (event) => {//deleteme duplicate function
    //     event.preventDefault();
    //     if (this.validForm()) {
    //        this.saveAccountManagerBasicInfo(event,this.state.basic_details)
    //     } else {
    //         toast.error(this.props.t('Listing.AddAccountManager.validation_texts.fill_required_fields'));
    //     }
    // }

   
    getAccountManagerData = (ac_manager_id) =>{
        DealerService.getAccountManagerData({id:ac_manager_id}).then(response => {
            if (response.data.status == 200 && response.status==200) {
                let responseData = response.data;
                this.setState({basic_details:responseData.data[0]},()=>{
                });
            }
        }).catch(error => {
            toast.error(error.message);
            this.setState({loading:true},()=>{
            });
        });
    }

    saveAccountManagerForm = (event) => {
        event.preventDefault();
        if (this.validForm()) {
           this.saveAccountManagerBasicInfo(event,this.state.basic_details)
        } else {
            toast.error(this.props.t('Listing.AddAccountManager.validation_texts.fill_required_fields'));
        }
    }

    saveAccountManagerBasicInfo = (event, ac_manager_data) =>{
        this.setState({loading:true},()=>{
        });
        // var parentObj = this;//deleteme
        event.preventDefault();
        let data2save = Object.assign({},ac_manager_data);
        var METHOD='post';
        // if(data2save.id){
        //    METHOD='put';
        // } 
        DealerService.saveAccountManagerData(data2save,METHOD).then(response => {
            this.setState({loading:false},()=>{
            });
            if (response.data.status == 200 && response.status==200) {
                // let responseData = response.data;
                toast.success( (response.data.message) ? response.data.message : "Success" );
                this.setState({redirect:true},()=>{
                });
            }else{
                this.setState({basic_details:ac_manager_data}, () => {
                    toast.error( (response.data.message) ? response.data.message : "Error" )
                });
            }
        }).catch(error => {
            toast.error(error.message);
            this.setState({loading:true},()=>{
            });
        });
    }


    render() {
        // const isLoggedIn = this.props.user_loggedin;//deleteme
        let basicinfo = {};
        basicinfo = this.state.basic_details;
        // let ownerinfo, dealership_list;//deleteme
        let { errors } = this.state;
        if (this.state.redirect) {
        return (<Redirect to={'/admin/ac-manager'} />);
        }

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <h1>{this.props.t('Listing.AddAccountManager.add_new_acount_manager')} </h1>
                    </div> 
                    </div><form className={(this.state.loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="basicdetail_form" onSubmit={this.saveAccountManagerForm}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <InputField type="text" name="name" error={(errors.name) ? errors.name : ''}  value={(basicinfo.name) ? basicinfo.name : ''} id="name" placeholder={this.props.t('Listing.AddAccountManager.ac_manager_name_placeholder')} label={this.props.t('Listing.AddAccountManager.ac_manager_name_label')} onChange={this.handleInputChange.bind(this, 'name')}  required={true}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                <InputField maxLength="12" type="tel"  name="mobile" error={(errors.mobile) ? errors.mobile : ''} id="mobile" value={(basicinfo.mobile) ? basicinfo.mobile : ''} placeholder={this.props.t('Listing.AddAccountManager.ac_manager_mobile_placeholder')} label={this.props.t('Listing.AddAccountManager.ac_manager_mobile_label')} onChange={this.handleInputChange.bind(this, 'mobile')} required={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <InputField type="email" name="email" id="email" placeholder={this.props.t('Listing.AddAccountManager.ac_manager_email_placeholder')} value={(basicinfo.email) ? basicinfo.email : ''} label={this.props.t('Listing.AddAccountManager.ac_manager_email_label')} onChange={this.handleInputChange.bind(this, 'email')}  />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer text-center">
                        <Button btnClass="btn btn-primary mrg-r30" title={this.props.t('Listing.AddAccountManager.save')} type="submit" />
                        {/* {
                            (basicinfo && basicinfo.id && basicinfo.id > 0) ? <NavLink to={this.props.nextpage} className="btn btn-default">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink> : ''
                        } */}
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(AddAccountManager));