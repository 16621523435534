import React, { useState,useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const ImageDraggableList = (props) => {
  const [imageList, setImageList] = useState([]);
  useEffect(() => () => {
    setImageList(props.images)
  }, [props.images]);
  const onDragEnd = (result) => {
    if (!result.destination) return; // Dragged outside the list
    const updatedList = Array.from(imageList);
    const [reorderedItem] = updatedList.splice(result.source.index, 1);
    updatedList.splice(result.destination.index, 0, reorderedItem);
    setImageList(updatedList);
    props.onSort(updatedList);
  };
  let clsName = imageList.length ? 'stock_image_list imageList' : 'imageList';

  return (
    <div className={clsName}>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="imageList" direction="horizontal" className="imageList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {imageList.map((image, index) => (
              <Draggable key={image.id} draggableId={image.id} index={index}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // style={{
                    //   padding: '8px',
                    //   margin: '8px',
                    //   border: '1px solid #ccc',
                    //   borderRadius: '4px',
                    //   backgroundColor: 'white',
                    // }}
                  >
                    {/* <img src={image.src} alt={`Image ${index}`} style={{ maxWidth: '100%' }} /> */}
                    {image.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    </div>
  );
};
export default ImageDraggableList;