import { Component } from 'react';
import { CONF_VAL } from '../../../config/constant';
import AuctionService from '../../../service/AuctionService';
import socketIOClient from "socket.io-client";

class Socket extends Component {
    
    _BiddingSocket = '';
    
    constructor(props) {
        
        super(props);

        this.state = {
            auctionId: this.props.componentData.auctionId, 
            dealerName: '',
            groupJoined: false,
            users:[]
        }
    }

    componentWillUnmount(){
        
        // this.leaveBiddingRoom();

        // window.removeEventListener("beforeunload", this.onUnload)

    }

    leaveBiddingRoom = () => {
        let { auctionId, buyerName, inventoryCars } = this.props.componentData;
        let params = {
            name: buyerName,
            room: ''
        }

        for(let cars of inventoryCars){
            params['room'] = auctionId+'-'+cars.hash_id;
            
            this._BiddingSocket.emit('leave', params, function (err) {
                if (err) {
                    // console.log(err)
                }
            });
        }
    }

    

    componentDidMount = () => {
        this.enableSocketEvents()
       
    }

    enableSocketEvents = () => {

        let socketServer = CONF_VAL.SOCKET_CONNECT_URL

        this._BiddingSocket = socketIOClient(socketServer, {
            upgrade: false
        });

        // this.joinRoom(this._BiddingSocket);

        this.disconnect(this._BiddingSocket);

        this.updateRealTimeBidAmount(this._BiddingSocket)
        
        //REMOVE CANCELED BID & SHOW ACTIVE BID
        this.updatedRealTimeActiveBidAmount(this._BiddingSocket)
        
        this.adminIncomingMsg(this._BiddingSocket)
        
    }

    /**
     * CHECK FOR INCOMING BID AMOUNT FROM SOCKET
     * @param {*} socket 
     */
     updateRealTimeBidAmount = (socket) => {
        socket.on('updatedRealTimeBidAmount', (message) => {
            this.props.updatedRealTimeBidAmount(message);
        });
    }


    /**
     * LISTEN INCOMING ADMIN MSGS
     * @returns 
     */
     adminIncomingMsg = (socket) => {
        socket.on('newMessage', function (message) {
            console.log('Message from Auction Admin. ', message);

        });
    }


    /**
     * JOIN ROOM 
     * @param {socket object} socket 
     */
    joinRoom = (socket) => {
        let { buyerName, inventoryCars, buyer_id } = this.props.componentData;
        let params = [];
        //CREATE CUSTOM ROOM IDs FOR ALL INVENTORIES
        for(let cars of inventoryCars){
            let singleRoom = {
                'room': cars.auction_id_hash+'-'+cars.hash_id,
                'name': buyerName
            }
            
            let auctionStartStatus = 0;

            if( cars && cars.start_date_time && new Date(cars.start_date_time).getTime() <= new Date().getTime() && new Date(cars.end_date_time).getTime() >= new Date().getTime()){
                auctionStartStatus = 1
            }
            if(auctionStartStatus) params.push(singleRoom)
        }

        if(params && params.length){
            //PASS ROOM DATA TO SOCKET
            socket.emit('join', params, function (err) {
                if (err) {
                    // console.log(err)
                }else{
                    //SAVE SOCKET USER INFO
                    let socketUsersInfo = [];

                    for(let user of params){
                        let socketUser = {
                            socket_id: socket.id,
                            seller_id: buyer_id,
                            room_id: user.room
                        }

                        socketUsersInfo.push(socketUser)
                    }

                    if(socketUsersInfo.length){
                        AuctionService.saveBidSocketUser(socketUsersInfo);
                    }
                }
            });
        }
        
    }

    

    /**
     * SOCKET DISCONNECT
     * @param {*} socket 
     */
    disconnect = (socket) => {
        socket.on('disconnectSocket',  (err) => {
            socket.connect()

        });
    }

    /**
     * 
     * @returns 
     */
    updatedRealTimeActiveBidAmount = (socket) => {
        socket.on('updatedRealTimeActiveBidAmount',  (activeBids) => {
            this.props.updatedRealTimeActiveBidAmount(activeBids);
        });
    }


    render() {
        return (
            ''
        )
    }
}

export default Socket;