import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LeftPanel from './left-panel';
import LoginForm from './login-form';
import ContactUs from './contact-us';
import JoinUs from './join-us';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import ForgotPassword from './ForgotPassword';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {"captchaVal":"", captchaId : '', otpId: '', otpValue:''},
            errors: {},
            key: 1,
            loading: false,
            redirect: 0,
            show: false,
            renderSection: 'login',
            lang_id: null,
            reloadCaptcha:1,
            langOption : [],
            showOtp:false,
            showotpFrm: false
        };
    }

    componentWillMount = () => {
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({ langOption: langData });
                    this.setDefaultLanguage(null, langData)
                }
            });
        } else {
            this.setState({ langOption: localLangData });
            this.setDefaultLanguage(null, localLangData);
        }

        if (window.location.search) {
            let search_params = this.getParameterByName('userdata')
            if (search_params) {
                search_params = JSON.parse(search_params)                
                if (search_params?.accessToken && search_params.accessToken) {
                    let user_email = search_params?.email || ""
                    secureStorage.setItem('login_email', user_email);
                    window.location.href = "/"
                } else {
                    toast.error("Email is not valid");
                }
            }
        }
    }

    componentDidMount() {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        }

        let user_email = secureStorage.getItem('login_email')
        if(user_email){
            let temp_fields = { ...this.state.fields }
            temp_fields.email = user_email
            let is_google_login = true
            this.setState({ fields: temp_fields })
            setTimeout(() => {
                this.submitAndValidateLoginForm("", is_google_login, temp_fields)
            }, 500);
        }
    }

    afterLoginSetState = (loginResponse) => {
        // let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;//deleteme
        let role = loginResponse?.data?.user_data?.role || null;
        if (role == "admin") {
            this.setState({ redirect: 2 })
        }
        else if (role == "dealercentral") {
            this.setState({ redirect: 1 })
        }
    }
    handleChangeEvent = event => {        
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        if(event && event.captchaData && event.captchaData.status==200){    
            fields["captchaVal"] = event.captchaVal;
            fields['captchaId'] = event.captchaData.data.value;
            if(event.captchaVal ==''){
                errors['captchaErr'] = 'Please enter valid captcha';
            }
        } else if(event?.target?.dataset?.key == "dealer_login") {
            fields["displayForm"] = "dealer_login";
        }else{
            fields[event.target.name] = event.target.value;
            if(event.target.value !== ''){
                delete errors[event.target.name];
            }else{
                errors[event.target.name] = 'Please enter '+event.target.name;
            }
        }                
        this.setState({ fields: fields,errors: errors });
    }

    submitAndValidateLoginForm = (event, is_google_login = false, temp_fields) => {
        if(event) 
            event.preventDefault();
        let self = this
        let fields = temp_fields ? temp_fields : {...this.state.fields};
        const errors = {...this.state.errors};
        if (this.validForm(is_google_login)) {
            self.setState({loading:true})
            fields.withCaptcha = true
            fields.is_google_login = false
            fields.displayForm = fields.displayForm || "admin_login"
            if(is_google_login && !self.state.showOtp){
                fields.withCaptcha = false
                fields.is_google_login = true
            }
            MasterService.post('account/user/login', fields)
                .then((response) => {
                    if (response.status == 200 && response.data.status == 200 && response.data) {
                        // if (is_google_login || !self.state.showOtp) {
                        //     self.sendOtpEmail()
                        //     self.setState({showotpFrm: true})
                        // } else {
                            response = response.data;
                            let cityIds = (response.data && response.data.dealer_data && response.data.dealer_data[0] && response.data.dealer_data[0].city_ids)
                                ? response.data.dealer_data[0].city_ids
                                : [];
                            let localityApi = (cityIds.length > 0)
                                ? MasterService.post('core/commonservice/locality_list', { city: cityIds })
                                : Promise.resolve();
                            localityApi.then((locRes) => {
                                if (locRes && locRes.status == 200 && locRes.data.status == 200 && locRes.data.data) {
                                    response.locality = locRes.data.data;
                                }
                                secureStorage.setItem('loginUserInfo', response);
                                secureStorage.setItem('login_email', "");
                                // const {i18n } = this.props;
                                let lang_id = null;
                                if (response.data.dealer_data && response.data.dealer_data[0] && response.data.dealer_data[0].default_lang_id)
                                    lang_id = response.data.dealer_data[0].default_lang_id;
                                else if (response.data.user_data && response.data.user_data.lang)
                                    lang_id = response.data.user_data.lang;
                                this.setDefaultLanguage(lang_id)
                                // let lang = (response.data.dealer_data[0].default_lang_id == 1) ? 'en': 'id';
                                // secureStorage.setItem('lang', lang);
                                // i18n.changeLanguage(lang)
                                this.props.updateStateIntoRedux('LOGIN_USER', response);
                                this.afterLoginSetState(response)
                                self.setState({loading:false})
                            }).catch((error) => {
                                self.setState({loading:false})
                                window.commonErrorToast(error.message);
                                this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
                            });
                        // }
                    } else {
                         

                        if(response?.data?.message){
                            window.commonErrorToast(response?.data?.message)
                        }
                        if (response?.data?.errors?.[0] && response.data.errors[0] == "ACCOUNT_BLOCKED") {
                            errors['email'] = response.data.message;
                            this.setState({ errors: errors });
                        } else {
                            delete errors['email'];
                        }
                        self.setState({loading:false})
                        this.setState({ reloadCaptcha: !(this.state.reloadCaptcha), fields: { ...this.state.fields, "captchaVal": "" } });
                    }
                }).catch(function (error) {
                    self.setState({loading: false})
                    window.commonErrorToast(error.message);
                });
        }
    }

    getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    sendOtpEmail = () => {
        let self = this
        let fields = {...this.state.fields};
        fields.withCaptcha = self.state.showOtp ? false : true
        let sendData = {
            email: fields.email
        }
        MasterService.post('account/user/send_email_otp', sendData)
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    if (response.data.data && response.data.data.value) {
                        fields["otpId"] = response.data.data.value
                        self.setState({ fields , showOtp: true})
                        toast.success(response.data.message)
                    }
                    self.setState({loading:false})
                } else {
                    self.setState({loading:false})
                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                self.setState({loading:false})
                window.commonErrorToast(error.message);
            });
    }

    setDefaultLanguage = (lang_id, localLangData) => {
        let lang = null;
        lang_id = lang_id || this.state.lang_id;
        localLangData = localLangData || secureStorage.getItem('langOption');
        if (lang_id)
            this.setState({ lang_id: lang_id })
        if (lang_id && localLangData && localLangData.length > 0)
            lang = (localLangData.filter(v => v.id == lang_id).map(v => v.iso_code))[0];
        if (lang) {
            secureStorage.setItem('lang', lang);
            if (this.props.i18n) this.props.i18n.changeLanguage(lang)
        }
    }

    getDealerLocality = (cityIds, callback) => {
        MasterService.get('core/commonservice/locality_list?city=' + cityIds[0]).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                callback(response.data.data);
            }
        })
            .catch((response) => {
            });
    }

    validForm = (is_google_login)=>{
        let fields = {...this.state.fields};
        const errors = {...this.state.errors}; 
        let showOtp = this.state.showOtp       
        let formIsValid = true;         
        errors['captchaErrorMessage'] = '';
        if(showOtp){
            if (errors["otpValue"] || (!fields.otpValue)) {  
                if(!fields["otpValue"]){
                    errors['otpValue'] = 'OTP is required';  
                    errors['OtpErrorMessage'] = 'OTP is required';
                } else if(errors["otpValue"]){
                    errors['otpValue'] = 'Please enter valid OTP';  
                    errors['OtpErrorMessage'] = 'Please enter valid OTP';
                }                   
                formIsValid = false;
            }else if(fields.otpValue.length != 6){
                errors['otpValue'] = 'Please enter valid OTP';  
                errors['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            } else if(errors["otpValue"]){
                errors['otpValue'] = 'Please enter valid OTP';  
                errors['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            } 
        } else {
            if (!fields["email"]) {
                errors['email'] = this.props.t('login.enter_email');
                formIsValid = false;
                // eslint-disable-next-line no-useless-escape
            // }else if(! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields["email"])){
            //     errors['email'] = this.props.t('login.valid_email');
            //     formIsValid = false;
            }
            if (!fields["password"] && !is_google_login) {
                errors['password'] = this.props.t('login.enter_password');
                formIsValid = false;
            }
            if ((errors["captchaErr"] || (!fields["captchaVal"])) && !is_google_login) {
                if(!fields["captchaVal"]){
                    errors['captchaErr'] = 'Captcha is required';  
                    errors['captchaErrorMessage'] = 'Captcha is required';
                }else if(errors["captchaErr"]){
                    errors['captchaErr'] = 'Please enter valid captcha';  
                    errors['captchaErrorMessage'] = 'Please enter valid captcha';
                    this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
                }                   
                formIsValid = false;
            }
        }
        
        
        this.setState({errors: errors});
        return formIsValid;
    }

    changeContent = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.props.updateStateIntoRedux('CHANGE_CONTENT', showingSection);
        this.setState({
            renderSection: showingSection
        })
    }
    switchLanguage = (event) => {
        let lang = event.currentTarget.dataset.key;
        const { i18n } = this.props;
        secureStorage.setItem('lang', lang);
        i18n.changeLanguage(lang)
    }

    render() {
        if (this.state.redirect == 1) {
            return (<Redirect to={'/dashboard'} />);
        }
        else if (this.state.redirect == 2) {
            return (<Redirect to={'/admin/dealer-list'} />);
        }
        const contentSection = this.state.renderSection;
        let dynamicComponent = '';
        if (contentSection === 'login') {
            dynamicComponent = <LoginForm errorMessage={this.state.errors} showOtp={this.state.showOtp} onClick={this.submitAndValidateLoginForm} onChange={this.handleChangeEvent} reloadCaptcha={this.state.reloadCaptcha} sendOtpEmail={this.sendOtpEmail}  showotpFrm={this.state.showotpFrm}/>; 
        } else if (contentSection === 'forgot') {
            dynamicComponent = <ForgotPassword />;
        } else if (contentSection === 'contactus') {
            dynamicComponent = <ContactUs />;
        } else {
            dynamicComponent = <JoinUs />;
        }
        // const { t, i18n } = this.props;//deleteme var not in use
        // const selLang = secureStorage.getItem('lang') || 'en';//deleteme var not in use
        // const { langOption } = this.state;//deleteme var not in use
        return (
            <div className={this.state.loading?"container-fluid loading":"container-fluid"} id="homePage">
                <LeftPanel />
                <div className="rightWhiteBg">
                    <nav className="navbar" role="navigation">
                        <ul className="nav navbar-nav">
                            <li data-key="login" className={contentSection == 'login' ? 'active' : ''} onClick={this.changeContent}>{this.props.t('login.login')}</li>
                            <li data-key="joinus" className={contentSection == 'joinus' ? 'active' : ''} onClick={this.changeContent}>{this.props.t('joinus.join_us')}</li>
                            <li data-key="contactus" className={contentSection == 'contactus' ? 'active' : ''} onClick={this.changeContent}>{this.props.t('login.contact_us')}</li>
                            {/* {langOption.length > 1 ?
                                <li>
                                    <div className="language-change language-login">
                                        {langOption && langOption.map((lang, index) =>
                                            <button key={index} data-key={lang.iso_code} type='button' className={selLang==lang.iso_code?'btn btn-primary':'btn btn-default'} onClick={this.switchLanguage}>{lang.iso_code}</button>
                                        )}
                                    </div>
                                </li>
                                : ''
                                } */}
                        </ul>
                    </nav>
                    <section className="slide-wrapper">
                        <div id="myCarousel" className="carousel slide carousel-fade">
                            <ol className="carousel-indicators">
                                <li data-key="login" className={contentSection == 'login' ? 'active' : ''} onClick={this.changeContent}></li>
                                <li data-key="joinus" className={contentSection == 'joinus' ? 'active' : ''} onClick={this.changeContent}></li>
                                <li data-key="contactus" className={contentSection == 'contactus' ? 'active' : ''} onClick={this.changeContent}></li>
                            </ol>
                            <div className="carousel-inner">
                                {dynamicComponent}
                            </div>
                        </div>
                    </section>
                    {/* <footer>
                        <div className="footer">{this.props.t('site_content.copyright')}</div>
                    </footer> */}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Login));