import React from 'react';
// import Navigation from '../Common/Navigation';//deleteme
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SITE_CONTENTS } from './../../../config/constant';

let SITE_COPYRIGHT=SITE_CONTENTS.AUCTION_COPYRIGHT;


function FooterAuction(props) {

        return (

            <footer className="auction-footer">
                <div className="container-fluid">
                    <div className="footer-txt">
                        <span className="copyright-txt">{props.t('site_content.'+SITE_COPYRIGHT)}</span>
                        <ul className="footer-link">
                            <li className=""><NavLink to="">Terms & Conditions</NavLink> </li>
                            <li className=""><NavLink to="">Privacy Policy</NavLink> </li>
                        </ul>
                    </div>
                </div>
            </footer>
        )
}

export default withTranslation('common')(FooterAuction);