import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_URL } from './../../config/constant';

class Brandlogo extends React.Component {
    
    render() {
    return (
        <Link className="navbar-brand" to="/"><img src={require('./../login/assets/images/'+LINK_URL.LOGO)} alt=''/></Link>
               
        );
    }
}
export default Brandlogo;