import React, { Component } from "react";
import InputField from './../../elements/InputField'
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class KycComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                kyc_id: props.kycId,
                comment_flag: props.active_status,
            },
            dealer_id: props.dealer_id,
            errors: {},
        };
    }

    submitCommentForm = (event) => {
        event.preventDefault();
        if (this.validFormData()) {
            let formData = this.state.formData;
            MasterService.post('dealer/dealer/kyc/add_comment', formData)
                .then(async (response) => {
                    if (response.status == 200 && response.data.status == 200) {
                        if (response.data.data && response.data.data.length) {
                            await MasterService.post('dealer/dealer/kyc/update', { id: formData.kyc_id, active_status: formData.comment_flag });
                        }
                        toast.success(response.data.message);
                        this.props.modalClose(false);
                        this.props.getDealerKycDetail();
                    }
                    else {
                        toast.error(response.data.message);
                    }
                }).catch((error) => {

                })
        }
    }

    handleChangetext = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let formData = this.state.formData;
        let errors = this.state.errors;
        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                errors[event.target.name] = event.target.name + " is required";
            } else {
                delete errors[event.target.name];
            }
        }
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    validFormData = () => {
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        if (!formData["comment"]) {
            formIsValid = false;
            errors["comment"] = "Comment is required.";
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    render() {
        const { errors } = this.state;
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }        
        
        return (
            <div>
                <style type="text/css">
                    {css}
                </style>
                <div className="row">
       
                    <div className="col-sm-6 col-md-12 form-group">
                        <InputField
                            inputProps={{
                                id: "comment",
                                type: "text",
                                placeholder: this.props.t('listLead.Comments'),
                                name: "comment",
                                autocompleate: "off",
                                validationreq: "yes",
                                label: this.props.t('listLead.Comments'),
                                validation_error: errors.comment
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>

                    <div className="col-sm-6 col-md-12 form-group text-right">
                        <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-link mrg-r15" type="reset" name="addleadbtn" id="cancel" title={this.props.t('listLead.Cancel')} />
                        <Button onClick={this.submitCommentForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('listLead.Save')} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('lead')(connect(mapStateToProps)(KycComment));