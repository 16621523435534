import React from 'react';
import { Route, Redirect } from "react-router-dom";
import secureStorage from './../config/encrypt';
import {DEALER_AUCTION_ID} from './../config/constant';

const PrivateRoute = ({ component: Component, ...rest }) => {

  let currentUrl      = window.location.pathname;
  let urlArray        = currentUrl.split('/')
  urlArray            = urlArray.filter(url=>url)

  //ALLOWED ROUTES FOR AUCTION DEALER
  let auctionRouteArray = ['auction-list', 'auction-inventory']
  
  //CHECK IF ROUTES ACCESS IS ALLOWED
  let authLoginData   = secureStorage.getItem('loginUserInfo') || {};
  let loginDealerData = (authLoginData.data && authLoginData.data.dealer_data && authLoginData.data.dealer_data[0]) || {}
  let isAuthorized    = false;
  

  if(!loginDealerData['subscription_ids'] && urlArray[0] !== 'auction' ){ //ACCESS ALL AVAILABLE ROUTES EXCEPT AUCTION
    isAuthorized = true
  }else if(loginDealerData['subscription_ids']){ //CHECK ACCESSIBLE ROUTES
    if(loginDealerData['subscription_ids'] != DEALER_AUCTION_ID && !auctionRouteArray.includes(urlArray[0]) ){ //ALLOW OTHER ROUTES BUT NOT AUCTION
      isAuthorized = true
    }else if(loginDealerData['subscription_ids'] == DEALER_AUCTION_ID){ //ALLOW AUCTION ROUTES
      isAuthorized = true
    }else if(loginDealerData['subscription_ids'] != DEALER_AUCTION_ID && loginDealerData['subscription_ids'].indexOf(DEALER_AUCTION_ID) !== -1){ //ALLOW ALL ROUTES
      isAuthorized = true
    }
  }

    return (
      <Route
        {...rest}
        render={props =>
          secureStorage.getItem('loginUserInfo') ?
            (isAuthorized
            ? 
              <Component {...props} />
            : 
              <Redirect to="/" />)
              :
              <Redirect to="/login" />
        }
      />
    );
};

export default PrivateRoute;