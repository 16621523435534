import React, { Component } from "react";

class ViewImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img_src: this.props.image_url
        };
    }

    render() {
        return (
            <div>
                <div>
                    <img alt="" src={this.state.img_src} height={400} width={400}/>
                </div>
            </div>
        );
    }

}

export default ViewImage
