import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from 'react-redux';
//Custom component
import PrivateRoute from './privateRoute';
import Header from './../view/common/Header';
import Footer from './../view/common/Footer';
import Login from './../view/login/component/login';
import AddStock from './../view/inventory/component/add-stock';
import ManageStock from './../view/inventory/component/manage-stock';
import ResetStock from './../view/inventory/component/reset-stock';
import Dashboard from '../view/dashboard/dashboard';
import BuyerLead from '../view/enquiry/component/BuyerEnquiry';
import AddbuyerEnquires from '../view/enquiry/component/AddbuyerEnquires';
import AdminRoute from './routeAdmin'
import EditListing from './../view/dealer/component/EditListing';
// import MyAccount from './../view/dealerpages/MyAccount';
import AdminDealerList from './../view/dealer/component/dealer-list-foradmin';
import ForgotPassword from './../view/login/component/ForgotPassword';
import ResetPassword from './../view/login/component/ResetPassword';
import ChangePassword from './../view/login/component/ChangePassword';
import Logout from './../view/common/logout';
// import NotFound from './../view/common/NotFound';

import Auction from '../view/auction/component/Auction';

// import Auction from './../view/auction_html/components/Auction';
import AuctionInventory from '../view/auction/component/AuctionInventory'
/* dealer detail */
import DealerDetail from './../view/dealerDetail/component/MyAccount';
import AuctioInventoryDetails from './../view/auction/component/AuctioInventoryDetails'
import HeaderAuction from '../view/auction/Common/HeaderAuction'
import FooterAuction from '../view/auction_html/Common/FooterAuction'
import RemoveInfo from './../view/login/component/remove-info'


class CustomRouter extends Component {
    constructor(props) {
        super(props);
        this.state = { ht: 500 }
    }
    componentDidMount() {
        this.setState({
            ht: window.innerHeight - 127
        });
        window.addEventListener('resize', () => {
            this.setState({
                ht: window.innerHeight - 127
            });
        }, false);
    }

    render() {
        const isLoggedIn = this.props.user_loggedin;
        let currentUrl = window.location.pathname ? window.location.pathname.split('/') : [];
        currentUrl = currentUrl.filter(currentUrl=> currentUrl)

        let enableAuctionHeader = false;

        let auctionRoute = ['auction-list', 'auction-inventory', 'auction-inventory-detail']

        if(auctionRoute.includes(currentUrl[0])){
            enableAuctionHeader = true;
        }

        return (
            <Router >
                {isLoggedIn == 1 ? (enableAuctionHeader ? <HeaderAuction /> : <Header />) : ''}
                {/* <Header /> */}

                <div style={{ minHeight: this.state.ht }}>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot" component={ForgotPassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/add-stock" exact component={AddStock} />
                    <Route path="/add-stock/:used_car_stock_id" component={AddStock} />
                    <Route path="/manage-stock" component={ManageStock} />
                    <Route path="/add-truck" exact component={AddStock} />
                    <Route path="/add-truck/:used_car_stock_id" component={AddStock} />
                    <Route path="/manage-truck" component={ManageStock} />
                    <PrivateRoute exact path="/add-lead" component={AddbuyerEnquires} />
                    <PrivateRoute exact path="/lead/:car_id_hash?" component={BuyerLead} />
                    <Route path="/edit-dealer-list" component={EditListing} />
                    <Route path="/my-account" component={DealerDetail} />
                    <Route path="/admin-dealer-list" component={AdminDealerList} />
                    <Route path="/dealer/:dealer_id_hash" component={DealerDetail} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/admin" component={AdminRoute} />
                    <Route path="/reset-stock" component={ResetStock} />
                    <PrivateRoute path="/auction-list" component={Auction} />

                    <PrivateRoute path="/auction-inventory/:auctionId" component={AuctionInventory} />

                    <PrivateRoute path="/auction-inventory-detail/:auctionCarId" component={AuctioInventoryDetails} />
                    <Route path="/remove-info" component={RemoveInfo}/>

                </div>
                
                {isLoggedIn == 1 ? (enableAuctionHeader ? <FooterAuction /> : <Footer />) : ''}

            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn,
        currentUrlPath: window.location.pathname
        
    };
}
export default connect(mapStateToProps, null)(CustomRouter);
