import React, {Component} from 'react';
import * as HELPER from './../auction/component/AuctionCommonData';
import NumericInput from 'react-numeric-input';
class CurrencyInputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            name: '',
            label:'',
            dataerror:'',
            value:'',
            onChange: '',
            className:'form-control',
            disabled:'',
            min:'',
            step:''
        }
    }

    changeHandel = (event)=>{
        if (typeof this.props.onChange === 'function') {
            if(event){
                this.setState({value: event})
                this.props.onChange(event);
            }
           
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedState = prevState;
        if(prevState.id !== nextProps.inputProps.id && nextProps.inputProps.value){
            updatedState = {...nextProps.inputProps, value: (nextProps.inputProps.value)};
            return updatedState; 
        }
        if(prevState.value!==nextProps.inputProps.value){
            updatedState = {value: (nextProps.inputProps.value)}; 
            return updatedState;    
        } else{
            return null; 
        }
       
    }

    chkValFocusOut = (event) => {
        if(event.target.value){
            // event.target.value =  (event.target.value);//deleteme is assigned to itself
            this.setState({value: event.target.value})
            this.props.onChange(event.target.value)
        }

    }
    chkValFocus = (event) => {
        if(event.target.value){
            // event.target.value = (event.target.value);//deleteme is assigned to itself
            this.setState({value: event.target.value})
            this.props.onChange(event.target.value)
        }
        
    }
    myFormat=(num)=>{
        return  HELPER.addCurrencyFormatting(num);
    }
        
    parseFun = (stringValue) => {
        return HELPER.removeCurrencyFormatting(stringValue);
    }
    
    render() {
        let props = this.props;
        return (

                <> 
                    <NumericInput {...this.state} onBlur={this.chkValFocusOut} onFocus={this.chkValFocus} onChange={this.changeHandel} mobile className={props.inputProps.className || this.state.className} autoComplete="off" /*value={props.inputProps.value}*/ disabled={props.inputProps.disabled} min={props.inputProps.min}  step={props.inputProps.step} format={this.myFormat} parse={this.parseFun} />
                </>
        );
    }
}

export default CurrencyInputField;