import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink ,Redirect  } from "react-router-dom";
import BasicDetails from './BasicDetails';
import OutletDetails from './OutletDetails';
import EmployeeDetails from './EmployeeDetails';
import KycDetails from './KycDetails';
import AuctionDetails from './AuctionDetails';
import ContactSetting from './ContactSetting';
import SubscriptionDetails from './SubscriptionDetails';
import secureStorage from '../../../config/encrypt';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import MasterContext from './MasterContext';
import { withTranslation } from 'react-i18next';
import { DealerService, InventoryService } from '../../../service';
import MasterService from './../../../service/MasterService';
import InventoryFundingDetails from './InventoryFundingDetails';
import { Dealership_Details_Four_Field_Not_allowd_For_Dealer_Size} from '../../../config/constant';


class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission:{},
            role: null,
            redirect: false,
            errors: {},
            loading:true,
            responseMsg:null,
            urlprefix:props.urlprefix || null,
            dealer_hash_id:props.dealer_hash_id || null,
            dealer_id:null,
            basic_details: props.basic_details || {},
            owner_details: props.owner_details || {},
            dealership_list:[],
            ac_managerList:[],
            outlet_list:props.outlet_list || [],
            StateList: [],
            cityAllList: [],
            localityList:[],
            outlet_errors:[],
            kycMasterlist:[],
            dealership_list_filtered:{},
            getLanguagelist:[],
            dealerPremiumTypeList:[],
            dealershipCategoryList:[],
            stockCategoryTypeList:[],
            formSubType:[],
            selectedAuctionUser:0,
            isAuctionBankSelected: 0,
            isAuctionSubscribed:[]
        }
        
        this.getBasicDetails = this.getBasicDetails.bind(this);
        this.getOwnerDetails = this.getOwnerDetails.bind(this);
        this.getDealertypeList = this.getDealertypeList.bind(this);
        this.getOutletList = this.getOutletList.bind(this);
        this.approvalRequest = this.approvalRequest.bind(this);
        this.updateDealerSizeFourFieldToZero = this.updateDealerSizeFourFieldToZero.bind(this);
    }

    approvalRequest = async () => {
        await this.getBasicDetails();
    }
    
    updateReduxData = async () => {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role;
        let isMyAccount = (this.props.match.url).indexOf("/my-account") > -1
        if (role == "admin" && !isMyAccount) {
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer_hash_id = this.props.match.params.dealer_id_hash;
            let urlprefix = '/dealer/' + dealer_hash_id;
            if (dealer_hash_id == 'basic-details') {
                urlprefix = '/dealer'; dealer_hash_id = '';
            }
            this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id })
        } else if (role == "dealercentral" && isMyAccount) {
            let permission = { dealerTypeViewOnly: true, cityViewOnly: true };
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer_hash_id = loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0] && loginResponse.data.dealer_data[0].dealer_id_hash;
            if (!dealer_hash_id) return this.setState({ redirect: '/' });
            let subTab = ((window.location.href.slice(-1) == '/') ? window.location.href.substr(0, window.location.href.length - 1) : window.location.href).split("/my-account")[1];
            let urlprefix = this.props.match.url
            urlprefix = (urlprefix.slice(-1) == '/') ? urlprefix.substr(0, urlprefix.length - 1) : urlprefix;
            this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id, role, permission }, () => {
                if (!subTab) {
                    // this.setState({ redirect: urlprefix + '/basic-details' })
                    this.props.history.push(urlprefix + '/basic-details');
                }
            })
        } else {
            this.setState({ redirect: '/' })
        }
    }

    componentDidMount = async() => {
        await this.updateReduxData();
        await this.getLanguageList();
        await this.getAccountManagerList();
        await this.getCityList();
        // await this.getlocalityList();
        await this.getBasicDetails();
        await this.getDealertypeList();
        await this.getKycMasterDetails(); 
        await this.getDealerFormInfo(); 
      }

      handleClickNav = (name,e) => {
        
        const { dealer_hash_id } = this.state
        if(!dealer_hash_id || dealer_hash_id=='basic-details'){
            e.preventDefault()
        }else{
            this.getBasicDetails();
        }
    }

    getLanguageList() {
        MasterService.getMasters(['lang','dealer_premium_type', 'stock_category','dealership_category'],
            { 'headers': { 'Authorization': this.props.auth_token } })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    this.setState({
                        getLanguagelist: response.data.data.lang,
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                        stockCategoryTypeList: response.data.data.stock_category,
                        dealershipCategoryList: response.data.data.dealership_category,            
                    })
                } else if (response.status == 401 && response.data.status == 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error) => {
            });
    }
    getCityList() {
        InventoryService.cityList({}).then(response => {
            if (response.data.status == 200 && response.status==200) {
                this.setState({StateList: response.data.data.state,
                    cityAllList: response.data.data.city
                })
            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }
    // Get locality list 
    // getlocalityList() {
    //     InventoryService.localityList({}).then(response => {
    //         if (response.data.status == 200 && response.status==200) {
    //             this.setState({localityList: response.data.data})
    //         }else{
    //             toast.error( (response.data.message) ? response.data.message : "Error" )
    //         }
    //     }).catch(error => {
    //         toast.error(error.message);
    //     });
    // }
    getDealerFormInfo= ()=> {
        let tempFormSubType=[];
        if (this.state.dealer_hash_id) {
            let postdata = { dealer_id_hash: this.state.dealer_hash_id }
             DealerService.getDealerFormInfo(postdata).then(response => {
                if (response.data.status == 200 && response.status == 200) {
                    let responsedata = response.data;
                    if (responsedata.data.length > 0) {        
                        responsedata.data.forEach(element => {
                            tempFormSubType.push(element.form_sub_type);
                            this.setState({formSubType:tempFormSubType},()=>{})
                        })
                    }
                }
            });
        } 
    }
    getBasicDetails(){
        if(this.state.dealer_hash_id){
            let postdata = {dealer_id_hash:this.state.dealer_hash_id}
            DealerService.getBasicDetails(postdata).then(response => {

                if (response.data.status == 200 && response.status==200) {
                    let responsedata = response.data;
                    let datasend ={}
                    if(responsedata.data.length > 0){
                        datasend = responsedata.data[0];
                        this.setState({basic_details:datasend,dealer_id:datasend.id,loading:false},() => {
                            this.getOwnerDetails();
                            this.getOutletList();
                        })
                    }
                    else {
                        this.setState({ redirect: '/' });
                    }
                }else{
                    toast.error( (response.data.message) ? response.data.message : "Error" )
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }else{
            this.setState({loading:false})
        }
    }

    getOwnerDetails(){
        let postdata = {dealer_id:this.state.dealer_id}
        DealerService.getOwnerDetails(postdata).then(response => {

            if (response.data.status == 200 && response.status==200) {
                let responsedata = response.data;
                let datasend ={}
                if(responsedata.data.length > 0){
                    datasend = responsedata.data[0];
                }

                this.setState({owner_details:datasend},() => {
                })

            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getAccountManagerList(){
        DealerService.getAccountManagerList().then(response => {
            if (response.data.status == 200 && response.status==200) {
                let responsedata = response.data; 
                let ac_manager_list = responsedata.data;
                this.setState({ac_managerList:ac_manager_list},()=>{
                })

            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getDealertypeList(){

        DealerService.getDealertypeList().then(response => {
            if (response.data.status == 200 && response.status==200) {
                let responsedata = response.data; 
                let dealershiplist = responsedata.data;
                this.setState({dealership_list:dealershiplist},()=>{
                })

            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getKycMasterDetails(){
        
        DealerService.getKycMasterDetails().then(response => {
            if (response.data.status == 200 && response.status==200) {
                let responsedata = response.data; 
                let kycMasterlist = responsedata.data;

                this.setState({kycMasterlist:kycMasterlist},()=>{
                })

            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getOutletList(){
       
        let postdata = {dealer_id:this.state.dealer_id,_with:["id","dealer_id","name", "address","description","type","contact_number","is_primary","city_id","state_id","locality_id","pincode","latitude","longitude","is_lat_long_verified","website_url","opening_timings","lead_preference_cities", "can_lead_cities"]}
        DealerService.getOutletList(postdata).then(response => {
            if (response.data.status == 200 && response.status==200) {
                let responsedata = response.data;
                let datasend =responsedata.data;
                datasend = datasend.map((singledata)=>{

                    if(singledata.opening_timings=='undefined' || singledata.opening_timings==null || singledata.opening_timings=='')
                    {
                        singledata.opening_timings = {"Monday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Tuesday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Wednesday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Thursday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Friday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Saturday":{"open":"10:00 AM","close":"7:00 PM","on":"on"},"Sunday":{"open":"10:00 AM","close":"7:00 PM","on":"on"}};
                    }
                    return singledata;
                })
                this.setState({outlet_list:datasend},()=>{
                })

            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    validateAndSaveBasicinfo = async(event, owner_details, basic_details) =>{
        this.setState({loading:true},()=>{
        });
        // var parentObj = this;//deleteme not in use
        event.preventDefault();
        if(owner_details.date_of_joining){
            let date_of_joining = new Date(owner_details.date_of_joining);
            let join_date = date_of_joining.getFullYear()+'-'+(date_of_joining.getMonth()+1)+'-'+date_of_joining.getDate();
            owner_details.date_of_joining = join_date;
        }

        if(owner_details.birth_date){
            let birth_date = new Date(owner_details.birth_date);
            let birthdate = birth_date.getFullYear()+'-'+(birth_date.getMonth()+1)+'-'+birth_date.getDate();
            owner_details.birth_date = birthdate;
        }

        let languageList = this.state.getLanguagelist || [];
        let lanLen = languageList.length;
        if(!basic_details.default_lang_id && lanLen == 1){
            let langId = languageList[0].id
            basic_details.default_lang_id = langId;
        }

        if(!basic_details.parent_dealer_type){
            basic_details.parent_dealer_type='1';
        }
        
        let data2save = Object.assign({},basic_details);
        data2save.owner = owner_details;
        //data2save.owner.id = '';
        var METHOD='post';
        if(data2save.dealer_id_hash){
            METHOD='put';
        }

        let response = await DealerService.saveBasicinfo(data2save,METHOD);
        if (response.data.status == 200 && response.status==200) {
                this.setState({loading:false},()=>{
                });
                let responseData = response.data;
                let dealer_id_hash = responseData.data && responseData.data[0] && responseData.data[0].dealer_id_hash;
                let other = {};
                if(METHOD=='post'){
                    if(dealer_id_hash) {
                        basic_details.dealer_id_hash = dealer_id_hash;
                        other = {
                            urlprefix:'/dealer/'+basic_details.dealer_id_hash,
                            dealer_hash_id:basic_details.dealer_id_hash,
                        }
                    }
                    else {
                        return toast.error("Error In Create");
                    }
                }

                this.setState({basic_details:basic_details,owner_details:owner_details, ...other},()=>{
                    toast.success( (response.data.message) ? response.data.message : "Success" )

                    if(METHOD=='post'){

                        let url2go = '/dealer/'+basic_details.dealer_id_hash+'/outlet-info';
                        this.props.history.push(url2go);
                       // this.componentDidMount();
                       window.location.reload();
                    }
                   
                })

        }else{
            this.setState({basic_details:basic_details,owner_details:owner_details,loading:false}, () => {
                toast.error( (response.data.message) ? response.data.message : "Error" )
            });
        }

        await this.getDealerFormInfo();
        await this.updateDealerSizeFourFieldToZero();
    }
    
    updateDealerSizeFourFieldToZero() {
        if(Dealership_Details_Four_Field_Not_allowd_For_Dealer_Size.includes(this.state.basic_details.premium_code) && this.state.dealer_hash_id){
            let postdata = {dealer_id: this.state.basic_details.dealer_id}
            DealerService.updateDealerSizeFourFieldToZero(postdata)
        }
    }
    saveoutletInfo=(event, idx ,formdata)=>{

        this.setState({loading:true},()=>{
        });
        // var parentObj = this;//deleteme not in use
        event.preventDefault();
        let saveData = Object.assign({}, this.state.outlet_list[idx]);
        saveData.name = formdata.name;
        let fulladdress = formdata.address;
        if(formdata.address2){
            fulladdress = fulladdress + '|' + formdata.address2;
        }
        saveData.address = fulladdress;
        saveData.city_id = formdata.city_id;
        saveData.contact_number = formdata.contact_number;
        saveData.state_id = formdata.state_id;
        saveData.city_id = formdata.city_id;
        if(formdata.locality_id){
            saveData.locality_id = formdata.locality_id;
        }else{
            saveData.locality_id = formdata.locality_old;
        }
        
        saveData.pincode = formdata.pincode;
        saveData.website_url = formdata.website_url;
        // if(formdata.showroom_type && formdata.workshop){
        //     saveData.type = formdata.showroom_type+','+formdata.workshop;
        // }else if(formdata.showroom_type){
        //     saveData.type = formdata.showroom_type;
        // }else if(formdata.workshop){
        //     saveData.type = formdata.workshop;
        // }

        // if(!saveData.type) saveData.type = '2';
        
        if(formdata.is_primary){
            saveData.is_primary = formdata.is_primary;
        }else{
            saveData.is_primary = 0;
        }
        /*
        if(formdata.is_primary_button){
            saveData.is_primary = formdata.is_primary_button;
        }else{
            saveData.is_primary = 0;
        } */

       

        saveData.latitude = formdata.latitude;
        saveData.longitude = formdata.longitude;
        if(formdata.is_lat_long_verified){
            saveData.is_lat_long_verified = formdata.is_lat_long_verified;
        }

        saveData.opening_timings.Monday.open = formdata.Monday_open;
        saveData.opening_timings.Monday.close = formdata.Monday_close;
        if(typeof formdata.Monday_on!='undefined'){
            saveData.opening_timings.Monday.on = formdata.Monday_on;
        }else{
            saveData.opening_timings.Monday.on = 'off';
        }
        

        saveData.opening_timings.Tuesday.open = formdata.Tuesday_open;
        saveData.opening_timings.Tuesday.close = formdata.Tuesday_close;
        if(typeof formdata.Tuesday_on!='undefined'){
            saveData.opening_timings.Tuesday.on = formdata.Tuesday_on;
        }else{
            saveData.opening_timings.Tuesday.on = 'off';
        }
        

        saveData.opening_timings.Wednesday.open = formdata.Wednesday_open;
        saveData.opening_timings.Wednesday.close = formdata.Wednesday_close;
        if(typeof formdata.Wednesday_on!='undefined'){
            saveData.opening_timings.Wednesday.on = formdata.Wednesday_on;
        }else{
            saveData.opening_timings.Wednesday.on = 'off';
        }

        saveData.opening_timings.Thursday.open = formdata.Thursday_open;
        saveData.opening_timings.Thursday.close = formdata.Thursday_close;
        if(typeof formdata.Thursday_on!='undefined'){
            saveData.opening_timings.Thursday.on = formdata.Thursday_on;
        }else{
            saveData.opening_timings.Thursday.on = 'off';
        }

        saveData.opening_timings.Friday.open = formdata.Friday_open;
        saveData.opening_timings.Friday.close = formdata.Friday_close;
       // saveData.opening_timings.Friday.on = formdata.Friday_on;
        if(typeof formdata.Friday_on!='undefined'){
            saveData.opening_timings.Friday.on = formdata.Friday_on;
        }else{
            saveData.opening_timings.Friday.on = 'off';
        }

        saveData.opening_timings.Saturday.open = formdata.Saturday_open;
        saveData.opening_timings.Saturday.close = formdata.Saturday_close;
        if(typeof formdata.Saturday_on!='undefined'){
            saveData.opening_timings.Saturday.on = formdata.Saturday_on;
        }else{
            saveData.opening_timings.Saturday.on = 'off';
        }

        saveData.opening_timings.Sunday.open = formdata.Sunday_open;
        saveData.opening_timings.Sunday.close = formdata.Sunday_close;
        if(typeof formdata.Sunday_on!='undefined'){
            saveData.opening_timings.Sunday.on = formdata.Sunday_on;
        }else{
            saveData.opening_timings.Sunday.on = 'off';
        }
        let save_outlet_url='dealer/showroom/add';
        if(formdata.id){
            save_outlet_url ='dealer/showroom/edit';
        }

        if(saveData.id){
            if(isNaN(saveData.id)){
                saveData.id='';
            }
        }

        saveData.inventory_size = formdata.inventory_size;
        saveData.parking_space = formdata.parking_space;
        saveData.average_monthly_sales = formdata.average_monthly_sales;
        saveData.potential_sales = formdata.potential_sales;
        DealerService.saveoutletInfo(saveData,save_outlet_url).then(response => {
            if (response.data.status == 200 && response.status==200) {
                // let responseData = response.data;//deleteme not in use
                this.setState({responseMsg:response.data.message,loading:false},()=>{
                    toast.success( (response.data.message) ? response.data.message : "Success" )
                    // this.getOutletList();
                    window.location.reload();
                })

            }else{
                this.setState({loading:false});
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            this.setState({loading:false});
            toast.error(error.message);
        });

    }
    checkAuctionStatus = async(arg)=>{
        let isAuctionselected = 0;
        if(arg.length > 0){
            isAuctionselected = arg[0];
        }
        this.setState({isAuctionBankSelected: isAuctionselected})
    }
    getSubsribedData = async(arg)=>{       
        this.setState({isAuctionSubscribed: arg});
    }
  

    render() {        
        let {formSubType,basic_details, isAuctionSubscribed}=this.state;  
        //let auctionUser = this.props.location.state.userType; 
        // let dealerTypeIds = basic_details.dealer_type_ids; //deleteme not in use
        let subscriptionDetails = (this.state.basic_details.subscription)? this.state.basic_details.subscription:'';
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
          }
        let masters = {
                aaaaa: [
                ]
        }        
        let auction_buyer_id = (isAuctionSubscribed.length)?isAuctionSubscribed[0].master.id:0;
        let isAuction = ((subscriptionDetails && (subscriptionDetails['auction_buyer._is_active'] == '1')) || (isAuctionSubscribed.length > 0)) ? true : false; 
        return (
            
                <div>
                    <MasterContext.Provider value={masters}>
                    <Router>
                        <ul className="pagesteps">
                            <li className="active">
                                <NavLink exact activeClassName="active" to={this.state.urlprefix+'/basic-details'} onClick={this.getBasicDetails}><span className="pagecount">1</span>{this.props.t('dealerDetails.myAccount.Basic_Details')}
                                <span>{(formSubType.indexOf("basic_detail") !=-1 && parseInt(basic_details.status)==3)?<i className="ic-peson-listed icons-normal"></i>:(parseInt(basic_details.status)==3)?<i className="ic-peson-blacklist icons-normal"></i>:''}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active"  to={this.state.urlprefix+'/outlet-info'}  onClick={this.handleClickNav.bind(this,'outlet-info')}><span className="pagecount">2</span>{this.props.t('dealerDetails.myAccount.Outlet_Details')}
                                <span>{(formSubType.indexOf("outlet_info") !=-1 && parseInt(basic_details.status)==3)?<i className="ic-peson-listed icons-normal"></i>:(parseInt(basic_details.status)==3)?<i className="ic-peson-blacklist icons-normal"></i>:''}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to={this.state.urlprefix+'/employee-info'} onClick={this.handleClickNav.bind(this,'employee-info')}><span className="pagecount">3</span>{this.props.t('dealerDetails.myAccount.Employee_Details')}</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to={this.state.urlprefix+'/subscription-details'} onClick={this.handleClickNav.bind(this,'subscription-details')}><span className="pagecount">4</span>{this.props.t('dealerDetails.myAccount.Subscription_Details')}
                                <span>{(formSubType.indexOf("subscription_details") !=-1 && parseInt(basic_details.status)==3)?<i className="ic-peson-listed icons-normal"></i>:(parseInt(basic_details.status)==3)?<i className="ic-peson-blacklist icons-normal"></i>:''}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to={this.state.urlprefix+'/contact-setting'} onClick={this.handleClickNav.bind(this,'contact-setting')}><span className="pagecount">5</span>{this.props.t('dealerDetails.myAccount.Contact_Setting')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={this.state.urlprefix+'/kyc-info'} onClick={this.handleClickNav.bind(this,'kyc-info')}><span className="pagecount">6</span>{this.props.t('dealerDetails.myAccount.KYC_Details')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={this.state.urlprefix+'/inventory_funding_details'} onClick={this.handleClickNav.bind(this,'inventory_funding_details')}><span className="pagecount">7</span>{this.props.t('dealerDetails.myAccount.Inventory_Funding')}</NavLink>
                            </li>
                            {(isAuction) ?
                                <li>
                                    <NavLink to={this.state.urlprefix+'/auction-info'} onClick={this.handleClickNav.bind(this,'auction')}><span className="pagecount">8</span>{this.props.t('dealerDetails.myAccount.auction')}</NavLink>
                                </li> : null 
                            }
                        </ul>
                        <div className="container-fluid">
                        {this.props.children}
                            <Switch>
                               
                                <Route exact path={this.state.urlprefix+'/basic-details'}>
                                    <BasicDetails loading={this.state.loading} getLanguagelist={this.state.getLanguagelist} getAccountManagerList={this.state.ac_managerList} dealerPremiumTypeList={this.state.dealerPremiumTypeList} dealershipCategoryList={this.state.dealershipCategoryList} stockCategoryTypeList={this.state.stockCategoryTypeList} nextpage={this.state.urlprefix+'/outlet-info'} basic_details={this.state.basic_details} owner_details={this.state.owner_details} dealership_list={this.state.dealership_list} responseMsg={this.state.responseMsg} onClick={this.validateAndSaveBasicinfo} onChange={this.handleChangeEvent} permission={this.state.permission} auctionCheck={this.checkAuctionStatus}/>
                                </Route>
                                <Route path={this.state.urlprefix+'/outlet-info'}>
                                    <OutletDetails StateList={this.state.StateList} 
                                    cityAllList={this.state.cityAllList} 
                                    localityList={this.state.localityList}
                                    basic_details={this.state.basic_details} outlet_list={this.state.outlet_list}
                                    responseMsg={this.state.responseMsg}
                                    dealer_id={this.state.dealer_id}
                                    onClick={this.saveoutletInfo} 
                                    outlet_errors={this.state.outlet_errors}
                                    prevpage={this.state.urlprefix+'/basic-details'}
                                    nextpage={this.state.urlprefix+'/employee-info'}
                                    loading={this.state.loading}
                                    permission={this.state.permission}
                                    />
                                </Route>
                                <Route path={this.state.urlprefix+'/employee-info'}>
                                    <EmployeeDetails prevpage={this.state.urlprefix+'/outlet-info'} nextpage={this.state.urlprefix+'/subscription-details'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} />
                                </Route>
                                <Route path={this.state.urlprefix+'/subscription-details'}>
                                    <SubscriptionDetails loading={this.state.loading} prevpage={this.state.urlprefix+'/employee-info'} nextpage={this.state.urlprefix+'/contact-setting'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} role={this.state.role} subscribed={this.getSubsribedData} isAuction={isAuction}/>
                                </Route>
                                <Route path={this.state.urlprefix+'/contact-setting'}>
                                    <ContactSetting loading={this.state.loading} prevpage={this.state.urlprefix+'/subscription-details'} nextpage={this.state.urlprefix+'/kyc-info'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} />
                                </Route>
                                <Route  path={this.state.urlprefix+'/kyc-info'}>
                                    <KycDetails 
                                    prevpage={this.state.urlprefix+'/contact-setting'}
                                    nextpage={this.state.urlprefix+'/inventory_funding_details'}
                                    basic_details={this.state.basic_details} 
                                    dealer_id_hash={this.state.dealer_hash_id} 
                                    StateList={this.state.StateList} 
                                    cityAllList={this.state.cityAllList} 
                                    localityList={this.state.localityList}
                                    kycMasterlist={this.state.kycMasterlist}
                                    dealer_id={this.state.dealer_id}
                                    loading={this.state.loading}
                                    auction_buyer_id = {auction_buyer_id}
                                    formSubType={formSubType}
                                    onClick = {this.approvalRequest}
                                    />
                                </Route>
                                <Route path={this.state.urlprefix+'/inventory_funding_details'}>
                                        <InventoryFundingDetails 
                                            loading={this.state.loading}
                                            dealer_id_hash={this.state.dealer_hash_id}
                                            basic_details={this.state.basic_details}
                                            prevpage={this.state.urlprefix+'/kyc-info'}
                                            nextpage={this.state.urlprefix+'/auction-info'}
                                            role_access={this.state.role_access}
                                        />
                                </Route>
                                <Route path={this.state.urlprefix+'/auction-info'}>
                                    <AuctionDetails 
                                    loading={this.state.loading} 
                                    prevpage={this.state.urlprefix+'/inventory_funding_details'} 
                                    // nextpage={this.state.urlprefix+'/kyc-info'} 
                                    dealer_id={this.state.dealer_id} 
                                    basic_details={this.state.basic_details} 
                                    dealer_id_hash={this.state.dealer_hash_id} 
                                    formSubType={formSubType}
                                    onClick = {this.approvalRequest}
                                    />
                                </Route>
                                
                            </Switch>
        
                        </div>
                    </Router>
                    </MasterContext.Provider>
                </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn
    };
  }
  const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
  }
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
