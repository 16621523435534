import React, { Component } from "react";
import { NavLink, Link } from 'react-router-dom';
import Notifaction from './Notification'
import secureStorage from './../../../config/encrypt';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import {DEALER_AUCTION_ID} from './../../../config/constant';
import Brandlogo from "../../elements/Brandlogo";


class Navigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            langOption: []
        }
    }
    componentWillMount = () => {
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({ langOption: langData });
                }
            });
        } else {
            this.setState({ langOption: localLangData });
        }
    }

    handleLogout = () => {
        if (typeof this.props.logoutUser === 'function') {
            this.props.logoutUser();
        }
    }

    render() {

        let name = "Admin";
        let role, profileTitle, userType, dealerName,stock_category=[];
        let showOtherMenuToDealer = true

        const { langOption } = this.state;
        // const { t, i18n } = this.props;
        const setLang = secureStorage.getItem('lang') || 'en';
        let selectedLang =  langOption.filter(lang=> lang.iso_code === setLang)

        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                name = loginResponse.data.user_data.name;
                role = loginResponse.data.user_data.role;
                userType = loginResponse.data.user_data.role_type;
            }
            if (loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0]) {
                let dealer = loginResponse.data.dealer_data[0];
                if (Array.isArray(dealer.stock_category))
                    stock_category = dealer.stock_category;
                dealerName = `${dealer.organization} (${dealer.gcd_code})`;

                //CHECK IF AUCTION/BANK IS ASSIGNED (i.e type_id=9 )
                if(dealer.subscription_ids && dealer.subscription_ids.indexOf(DEALER_AUCTION_ID) !== -1){
                    // showAuctionLinkToDealer = true

                    //IF ONLY AUCTION/BANK ASSIGNED, THEN HIDE OTHER LINKS
                    if(+dealer.subscription_ids === DEALER_AUCTION_ID) showOtherMenuToDealer = false
                }
            }
            profileTitle = [dealerName, userType].filter(v => v).join(" | ")
        }

        return (
            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                        <Brandlogo />
                    </div>

                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                                <li><NavLink to="">{this.props.t('menu.home')}</NavLink></li>
                                <li className=""><NavLink to="/auction-list">{this.props.t('menu.auction')}</NavLink> </li>

                                {
                                    showOtherMenuToDealer
                                    ?
                                        <>
                                            <li className="dropdownmenu">
                                                <NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}
                                                    isActive={(match, location) => {
                                                        if (location.pathname === '/add-stock' || location.pathname === '/manage-stock') { return true; }
                                                    }}>
                                                    {this.props.t('menu.stock_manager')}<span className="caret"></span></NavLink>
                                                <ul className="submenu">
                                                    {(stock_category.includes(1)) ?
                                                        <><li><NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}>{this.props.t('menu.manage_stock')}</NavLink></li>
                                                            <li><NavLink to="/add-stock">{this.props.t('menu.add_stock')}</NavLink></li></>
                                                        : ""}
                                                    {(stock_category.includes(3)) ?
                                                        <><li><NavLink to={{ pathname: '/manage-truck', state: 'freshLoadStock' }}>{this.props.t('menu.manage_truck')}</NavLink></li>
                                                            <li><NavLink to="/add-truck">{this.props.t('menu.add_truck')}</NavLink></li></>
                                                        : ""}
                                                </ul>
                                            </li>
                                            
                                            <li className="dropdownmenu">
                                                <NavLink to="/lead"
                                                    isActive={(match, location) => {
                                                        if (location.pathname === '/add-lead' || location.pathname === '/lead') { return true; }
                                                    }}>{this.props.t('menu.buyer_enquiry')}<span className="caret"></span></NavLink>
                                                    <ul className="submenu">
                                                        <li><Link to={{ pathname: '/lead', state: 'loadAllLead' }}>{this.props.t('menu.manage_enquiry')}</Link></li>
                                                        <li><Link to="/add-lead">{this.props.t('menu.add_enquiry')}</Link></li>
                                                    </ul>
                                            </li>
                                        </>
                                    :
                                            ''
                                    
                                }
                                {/* <li className="dropdownmenu">
                                    <NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}
                                        isActive={(match, location) => {
                                            if (location.pathname == '/add-stock' || location.pathname == '/manage-stock') { return true; }
                                        }}>
                                        {this.props.t('menu.stock_manager')}<span className="caret"></span></NavLink>
                                    <ul className="submenu">
                                        {(stock_category.includes(1)) ?
                                            <><li><NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}>{this.props.t('menu.manage_stock')}</NavLink></li>
                                                <li><NavLink to="/add-stock">{this.props.t('menu.add_stock')}</NavLink></li></>
                                            : ""}
                                        {(stock_category.includes(3)) ?
                                            <><li><NavLink to={{ pathname: '/manage-truck', state: 'freshLoadStock' }}>{this.props.t('menu.manage_truck')}</NavLink></li>
                                                <li><NavLink to="/add-truck">{this.props.t('menu.add_truck')}</NavLink></li></>
                                            : ""}
                                    </ul>
                                </li>
                                
                                <li className="dropdownmenu">
                                    <NavLink to="/lead"
                                        isActive={(match, location) => {
                                            if (location.pathname == '/add-lead' || location.pathname == '/lead') { return true; }
                                        }}>{this.props.t('menu.buyer_enquiry')}<span className="caret"></span></NavLink>
                                        <ul className="submenu">
                                            <li><Link to={{ pathname: '/lead', state: 'loadAllLead' }}>{this.props.t('menu.manage_enquiry')}</Link></li>
                                            <li><Link to="/add-lead">{this.props.t('menu.add_enquiry')}</Link></li>
                                        </ul>
                                </li> */}

                                {/* <li className=""><NavLink to="">Post Auction</NavLink> </li>
                                <li className=""><NavLink to="">Seller Reports</NavLink> </li>
                                <li className="dropdownmenu"><NavLink to="/contact">Administration</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/about">Option1</NavLink> </li>
                                        <li><NavLink to="/about">Option1</NavLink> </li>
                                        <li><NavLink to="/about">Option1</NavLink> </li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                        <div className="notification-btn">
                            <Notifaction />

                        </div>

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu"><NavLink to="#">
                                    <span className="userarc">
                                        {name[0].toUpperCase()}
                                    </span>
                                    <div className="username">
                                        <span>{name}</span>
                                        <span className="user-degintion">{profileTitle}</span>
                                    </div>
                                </NavLink>
                                    <ul className="submenu">
                                        
                                        {role === 'dealercentral' && <li><NavLink to="/my-account/basic-details">{this.props.t('menu.my_account')}</NavLink></li>}
                                        <li onClick={this.handleLogout}><NavLink to="">{this.props.t('menu.logout')}</NavLink></li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    
                    {
                        langOption.length>1
                        ?
                            <div className="language">
                                <ul>
                                    <li className="dropdownmenu">
                                        <NavLink to="#">
                                            {selectedLang && selectedLang[0] && selectedLang[0]['iso_code'].toUpperCase()}
                                        </NavLink>
                                        <ul className="submenu">
                                        
                                            {
                                                langOption.length>1
                                                ?
                                                    langOption.map((lang, index) => {
                                                        if(setLang!==lang.iso_code){
                                                            return <li key={index}>
                                                                <NavLink to="#" onClick={() => this.changeLanguage(lang.iso_code)}>{lang.iso_code.toUpperCase()}</NavLink>
                                                            </li>
                                                        }else{
                                                            return ''
                                                        }
                                                    })
                                                :
                                                    ''
                                            }
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        :
                            ''
                    }
                    </div>

                </div>

            </div >
        );
    }
}

export default withTranslation('common')(Navigation);
