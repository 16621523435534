import {  useEffect, useState } from 'react';
import * as HELPER from "./AuctionCommonData";

const AuctionInventory = (props) => {

    const [endDateTime, setEndDateTime] = useState("");
    const [cacheTime, setCacheTime] = useState("");

    useEffect(() => {

        let intervalManger

        if(endDateTime === ""){

            intervalManger = setInterval(() => {
                if (props.endDate) {
                    if (new Date(props.endDate).getTime() - new Date().getTime() > 0) {
                        setEndDateTime(HELPER.getRemainingTime(props.endDate))
                        setCacheTime(HELPER.getRemainingTime(props.endDate))
                    } else {
                        clearInterval(intervalManger);
                        window.location.reload();
                    }

                }

            }, 1000)
        }

        return () => {
            setEndDateTime("")
            clearInterval(intervalManger);
        }
    // eslint-disable-next-line
    }, [props]);

    return (
        cacheTime || endDateTime
    )
}

export default AuctionInventory;