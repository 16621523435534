import React, { Component } from 'react';
import OtpLogin from './otp-login';
import ForgotPassword from './ForgotPassword';
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';
import Captcha from "../../common/Captcha";
import GoogleLogin from './google-login';
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: 'admin_login',
            passwordType:"password",
            reloadCaptcha:1,
            fields: {},
            inputFieldCaptcha:{captcha:""},
            errors: {captchaErr:'Please enter valid captcha'},
            showOtp:false,
            otpTimer:0,
            showotpFrm: false
        }
    }
    componentDidMount = ()=>{
        let self = this
        this.myInterval = setInterval(() => {
            self.setState(({ otpTimer }) => ({
                otpTimer: otpTimer - 1
            }))
        }, 1000)
    }

    componentWillReceiveProps = (nextProps)=>{
        this.setState({errors : nextProps.errorMessage});
        this.setState({reloadCaptcha : nextProps.reloadCaptcha});
        this.setState({showOtp : nextProps.showOtp});
        this.setState({showotpFrm : nextProps.showotpFrm});
        if(this.state.showOtp != nextProps.showOtp && nextProps.showOtp){
            this.setState({otpTimer:60})
        }
    }
    submitLoginForm = (event) =>{
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }

    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }


    togglePassword = (e) => {
        e.preventDefault();
        if (this.state.passwordType === 'password') {
            this.setState({passwordType:'text'})
        }else{
            this.setState({passwordType:'password'})
        }
    };
     

    showLoginForm = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.setState({
            displayForm: showingSection
        })
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    showEmailLoginForm = (event) => {
        this.setState({
            displayForm: 'dealer_login'
        })
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }
     captchaFiledHandler = (childData) => {
        let captcahId = childData.captchaData.data.value;
        //let bufferObj = Buffer.from(childData.captchaData.data.value, "base64");
        //let decodedCaptcha = bufferObj.toString("utf8");
        let tempErrors=this.state.errors;
        let tempInputFieldCaptcha=this.state.inputFieldCaptcha;
        if(captcahId){                  
           tempErrors['captchaErr']="";           
           tempInputFieldCaptcha['captcha']=childData.captchaVal;               
        } else {            
            tempErrors['captchaErr']="Please enter valid captcha.";             
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(childData);
        }
        this.setState({tempErrors});        
    }

    handleResend = async (event) =>{
        await this.props.sendOtpEmail(event)
        this.setState({otpTimer:60})
    }

    render() {
        const { errors} = this.state;
        return (
            <div className="item active">
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                                <div className={(this.state.displayForm === 'forgot' ? "hide" : " ")}>
                                    <h1 className="login-h1">{this.props.t('login.login')}</h1>
                                    <p className="login-p">{this.props.t('login.login_title')}</p>
                                    <div className="mrg-b30">
                                        <button onClick={this.showLoginForm} className={"btn btn-default btn-lg button-rounded mrg-L15 " + (this.state.displayForm === 'admin_login' ? "active" : " ")} type="button" id="sign_upBtn" data-key="admin_login" >{this.props.t('login.admin_login')}</button>
                                        <button onClick={this.showLoginForm} className={"btn btn-default btn-lg button-rounded " + (this.state.displayForm === 'dealer_login' ? "active" : " ")} type="button" id="sign_inBtn" data-key="dealer_login">{this.props.t('login.dealer_login')}</button>
                                    </div>
                                </div>
                                {this.state.displayForm === 'dealer_login' ?
                                    <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform" onSubmit={this.submitLoginForm}>
                                        <div className="row">
                                            {this.state.showOtp == false ?
                                                <div>
                                                    <div className="col-sm-12">
                                                        <InputField
                                                            inputProps={{
                                                                id: "email",
                                                                type: "text",
                                                                name: "email",
                                                                label: this.props.t('joinus.email'),
                                                                autoFocus: true,
                                                                dataerror: errors.email
                                                            }}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <InputField
                                                            inputProps={{
                                                                id: "password",
                                                                type:this.state.passwordType,
                                                                name: "password",
                                                                label: this.props.t('login.password'),
                                                                dataerror: errors.password
                                                            }}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                        <span style={{cursor:"pointer",position: "absolute",right: "8px",marginTop: "112px"}} >{ this.state.passwordType == 'password' ? <i onClick={this.togglePassword} class="ic-remove_red_eyevisibility"></i> :<i onClick={this.togglePassword} className='ic-remove_red_eyevisibility'></i> }</span>
                                                    <div className="col-sm-12">
                                                        <Captcha onKeyPressCaptcha={this.captchaFiledHandler} errField={this.state.errors} inputField={this.state.inputFieldCaptcha} reloadCaptcha={this.state.reloadCaptcha} />
                                                    </div>
                                                </div> :
                                                <div className="col-sm-12">
                                                    <InputField
                                                        inputProps={{
                                                            id: "otpValue",
                                                            type: "number",
                                                            name: "otpValue",
                                                            label: this.props.t('joinus.otp'),
                                                            autoFocus: true,
                                                            dataerror: errors.otpValue
                                                        }}
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.state.otpTimer > 0 ?
                                                        <span className="otp-time">{"00:" + (this.state.otpTimer > 9 ? this.state.otpTimer : "0" + this.state.otpTimer)}</span> :
                                                        <span className="otp-time" style={{ cursor: "pointer" }} onClick={(event)=>this.handleResend(event)}>Resend</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                            </div>
                                            <div className="col-sm-6"><label className="mrg-B0 forgotP" id="forgotPassword"><a onClick={this.showLoginForm} data-key="forgot" href={() => false}>{this.props.t('login.forgot_password')}</a></label></div>
                                        </div>
                                        <span id="invalidLogin" className="invalid-login"></span>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.submitLoginForm}>{this.props.t('login.sign_in')}</button>
                                        </div>
                                    </form>
                                    :
                                    this.state.displayForm === 'forgot' ?
                                        <ForgotPassword showEmailLoginForm={this.showEmailLoginForm} showLoginForm={this.showLoginForm} />
                                        :
                                        <GoogleLogin handleResend={this.handleResend} showotpFrm={this.state.showotpFrm} submitLoginForm={this.submitLoginForm} onChange={this.props.onChange}/>
                                        // <OtpLogin />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(LoginForm);